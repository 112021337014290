import { FormGroup } from "@angular/forms";
import { PLAN_EXECUTION_MODE } from "./zcomponents-lib/plan-execution/plan-execution.service";

export class DbmCompany {
  ID: number;
  CODE_SOCIETE: string;
  NOM_SOCIETE: string;
  NOM_BASE: string;
  DOMAINE: string;
  CONNECTION_STRING: string;
  CONNECTEUR: number;
  CONNECTEUR_VERSION: string;
  DATE_CREATION: string;
  DATE_SUPPRESSION: string;
  SOMMEIL: number;
  DATE_INSTALLATION: string;
  DB_VERSION: string;
  DERNIERE_MAJ: string;
  RUNNER_SECRET_KEY: string;
  RUNNER_DATE_INSTALLATION: string;
  RUNNER_USERNAME: string;
  RUNNER_PASSWORD: string;
  RUNNER_CONNECTION_ID: string;
  SMTP_SENDER_NAME: string;
  SMTP_SENDER_ADDRESS: string;
  SMTP_HOST: string;
  SMTP_PORT: string;
  SMTP_USERNAME: string;
  SMTP_PASSWORD: string;
  SMS_APIHOST: string;
  SMS_APIKEY: string;
  SMS_APISECRET: string;
  SMS_API_AUTHORIZATION_HEADER: string;
  SMS_SENDER_PHONE_NUMBER: string;
}

export class DbmUser {
  ID: number;
  NOM: string;
  PRENOM: string;
  EMAIL: string;
  TELEPHONE: string;
  DATE_CREATION: string;
  USERNAME: string;
  ROLE: ABW_ROLE;
  PASSWORD: string;
  SOMMEIL: number;
  DATE_SUPPRESSION: string;
  VARIABLES_ENVIRONMENT: DbmVariableEnvironmentUser[];
  SUPERVISEUR: number;

  //
  FULLNAME: string;
}

export class DbmTeam {
  ID: number;
  NOM: string;
  DATE_CREATION: string;
  SOMMEIL: number;
  DATE_SUPPRESSION: string;
  mUSERS_IDS: any;
  mSUPERVISEURS_IDS: any;
  mNUMS_WORKFLOWS: any;
  mWORKFLOWS: DbmWorkflow[];
  mUSERS: DbmUser[];
  mASSIGNATIONS_AGENTS: DbmTeamAssignationAgent[];
  mASSIGNATIONS_WORKFLOWS: DbmTeamAssignationWorkflow[];
}

export class DbmTeamAssignationWorkflow {
  TEAM_ID: number;
  NUM_WORKFLOW: string;
  DATE_DEBUT_ASSIGNATION: string;
  DATE_FIN_ASSIGNATION: string;
}

export class DbmTeamAssignationAgent {
  TEAM_ID: number;
  USER_ID: number;
  DATE_DEBUT_ASSIGNATION: string;
  DATE_FIN_ASSIGNATION: string;
  SUPERVISEUR: number;
}

export class DbmWorkflow {
  ID: number;
  NUM_WORKFLOW: string;
  TITRE: string;
  DESCRIPTION: string;
  MODE_WORKFLOW: Enum_ModeWorkflow;
  TITLE_EXECUTIONS_EXP: string;
  DATE_CREATION: string;
  DATE_MODIFICATION: string;
  CREE_PAR: number;
  SOMMEIL: boolean;
  DATE_SUPPRESSION: string;
  STEPS: DbmWorkflowStep[] = [];
  DECLENCHEURS: DbmDeclencheur[] = [];

  doGetUiComponents(): DbmWorkflowStepUiComponent[] {
    var uiComponents: DbmWorkflowStepUiComponent[] = [];
    this.STEPS.forEach(step => {
      uiComponents = uiComponents.concat(step.UI_COMPONENTS);
    })
    return uiComponents;
  }
}

export class DbmWorkflowStep {
  ID: number;
  NUM_WORKFLOW: string;
  NUM_WORKFLOWSTEP: string;
  S_INDEX: number;
  S_TITRE: string;
  S_DESCRIPTION: string;
  DATE_CREATION: string;
  DATE_MODIFICATION: string;
  SOMMEIL: boolean;
  DATE_SUPPRESSION: string;
  CREE_PAR: number;
  S_TYPE: STEP_TYPE;
  S_MODIFIABLE: boolean;
  S_TYPE_GEOLOC: STEP_TYPE_GEOLOC;
  S_SUPERVISEUR_VALIDATION: boolean;
  S_SUPERVISEUR_NOTIFICATION: boolean;
  S_CUSTOM_API: string;
  UI_COMPONENTS: DbmWorkflowStepUiComponent[];
  DATASOURCES: DbmWorkflowStepDatasource[];
  REDIRECTIONS: DbmWorkflowStepRedirection[];
}

export class DbmResponseWorkflow {
    ID: number;
    TITRE_RESPONSE_WORKFLOW: string;
    STATUS_WORKFLOW: STATUS_WORKFLOW;
    CURRENT_STEP_WORKFLOW: number;
    TOTAL_STEPS_COUNT: number;
    DATE_CREATION: string;
    DATE_DEBUT_WORKFLOW: string;
    DATE_FIN_WORKFLOW: string;
    SOMMEIL: number;
    DATE_MODIFICATION: string;
    DATE_SUPPRESSION: string;
    USER_ID: number;
    TEAM_ID: number;
    NUM_WORKFLOW: string;
    STEPS: DbmResponseWorkflowStep[] = [];
    F_STEPS : any[] = [];
    WORKFLOW: DbmWorkflow;
    User: DbmUser;
    TEAM: DbmTeam;
    CIRCUIT_INSTANCE_ID: number;

    //
    NUM_WORKFLOW_LABEL: string;
    STATUS_WORKFLOW_LABEL: string;
    CURRENT_STEP_LABEL: string;
    SUPERVISEURS: string;
    DATE_CREATION_DISPLAY: string;
    GUID: string;

    //
    SYNCED: boolean;
    DATE_SYNCHRONISATION: string;
}

export class DbmResponseWorkflowStep {
    ID: number;
    DATE_DEBUT_STEP: string;
    DATE_FIN_STEP: string;
    SOMMEIL: number;
    DATE_SUPPRESSION: string;
    STATUS_WORKFLOW_STEP: STATUS_WORKFLOW;
    GEOLOCALISATIONS: Model_Geolocalisation[] = [];
    FORM_RESPONSES: any;
    RESPONSE_WORKFLOW_ID: number;
    USER_ID: number;
    NUM_WORKFLOW: string;
    NUM_WORKFLOWSTEP: string;
    VALIDATIONS: DbmResponseWorkflowStepValidation[] = [];
    S_GEOLOCATION_POINTS: { LATITUDE: number, LONGITUDE: number }[] = [];
    RESULT_CUSTOM_API: any;
    FORM_RESPONSES_FILES: { key: DbmResponseWorkflowStepFile } | any;
    FORM_RESPONSES_COMPONENTS: {key: DbmWorkflowStepUiComponent} | any = {};
}

export class DbmResponseWorkflowStepFile {
  ID: number;
  NOM_FICHIER: string;
  FILEPATH: string;
  DATE_CREATION: string;
  RESPONSE_WORKFLOWSTEP_ID: number;
  NUM_WORKFLOWSTEP: string;
  NUM_WORKFLOW: string;
  UIC_ID: number;
  UIC_NOM: string;
  USER_ID: number;
}

export class DbmResponseWorkflowStepValidation {
    ID: number;
    USER_ID: number;
    NUM_WORKFLOW: string;
    NUM_WORKFLOWSTEP: string;
    DATE_VALIDATION: string;
    STATUS_VALIDATION: STATUS_WORKFLOW_VALIDATION;
    COMMENTAIRE_VALIDATION: string;
}

export class DbmWorkflowStepUiComponent {
    ID: number;
    NUM_WORKFLOW: string;
    NUM_WORKFLOWSTEP: string;
    UIC_INDEX: number;
    UIC_CODE: string;
    UIC_IDENTIFIANT: string;
    UIC_NOM: string;
    UIC_LIBELLE: string;
    UIC_PARAMS: any;
    DATE_CREATION: string;
    DATE_MODIFICATION: string;
    CREE_PAR: number;
    DATE_SUPPRESSION: string;
    SOMMEIL: boolean;
    S_CONDITIONS_VISIBILITY: any | { logic: string, filters: { field: string, value: string, operator: string }[] } = null;
    S_CONDITIONS_ENABLED: any | { logic: string, filters: { field: string, value: string, operator: string }[] } = null;
    S_CONDITIONS_REQUIRED: any | { logic: string, filters: { field: string, value: string, operator: string }[] } = null;
    S_CONDITIONS_VALIDATION: any | { logic: string, filters: { field: string, value: string, operator: string }[] } = null;

    doBuildConditions(S_CONDITIONS: { logic: string, filters: { field: string, value: string, operator: string }[] }, formGroup: FormGroup): boolean {
      var condition = true;
      S_CONDITIONS.filters?.forEach(filter => {
        switch(filter.operator) {
          case 'eq':
            condition = condition && formGroup.controls[filter.field] !== undefined && String(formGroup.controls[filter.field].value) == String(filter.value);
            break;
          case 'neq':
            condition = condition && formGroup.controls[filter.field] !== undefined && String(formGroup.controls[filter.field].value) != String(filter.value);
            break;
          case 'gte':
            condition = condition && formGroup.controls[filter.field] !== undefined && String(formGroup.controls[filter.field].value) >= String(filter.value);
            break;
          case 'gt':
            condition = condition && formGroup.controls[filter.field] !== undefined && String(formGroup.controls[filter.field].value) > String(filter.value);
            break;
          case 'lte':
            condition = condition && formGroup.controls[filter.field] !== undefined && String(formGroup.controls[filter.field].value) <= String(filter.value);
            break;
          case 'lt':
            condition = condition && formGroup.controls[filter.field] !== undefined && String(formGroup.controls[filter.field].value) < String(filter.value);
            break;
          case 'isnull':
            condition = condition && formGroup.controls[filter.field] !== undefined && formGroup.controls[filter.field].value == null;
            break;
          case 'isnotnull':
            condition = condition && formGroup.controls[filter.field] !== undefined && formGroup.controls[filter.field].value != null;
            break;
          case 'eq_uic_control':
            condition = condition && formGroup.controls[filter.field] !== undefined && formGroup.controls[filter.value] !== undefined && formGroup.controls[filter.field].value == formGroup.controls[filter.value].value;
            break;
          case 'neq_uic_control':
            condition = condition && formGroup.controls[filter.field] !== undefined && formGroup.controls[filter.value] !== undefined && formGroup.controls[filter.field].value != formGroup.controls[filter.value].value;
            break;
          case 'gte_uic_control':
            condition = condition && formGroup.controls[filter.field] !== undefined && formGroup.controls[filter.value] !== undefined && formGroup.controls[filter.field].value >= formGroup.controls[filter.value].value;
            break;
          case 'gt_uic_control':
            condition = condition && formGroup.controls[filter.field] !== undefined && formGroup.controls[filter.value] !== undefined && formGroup.controls[filter.field].value > formGroup.controls[filter.value].value;
            break;
          case 'lte_uic_control':
            condition = condition && formGroup.controls[filter.field] !== undefined && formGroup.controls[filter.value] !== undefined && formGroup.controls[filter.field].value <= formGroup.controls[filter.value].value;
            break;
          case 'lt_uic_control':
            condition = condition && formGroup.controls[filter.field] !== undefined && formGroup.controls[filter.value] !== undefined && formGroup.controls[filter.field].value < formGroup.controls[filter.value].value;
            break;
        }
      });
      return condition;
    }

    doBuildNgDynamicsFormControlRelations() {
      //this.doParseConditions();

      var relations = [];
      relations.push(this._doGetNgDynamicsFormControlRelations('QUERY_BUILDER_VISIBILITY', UI_COMPONENT_TYPE_CONDITION.CONDITION_VISIBILITY));
      relations.push(this._doGetNgDynamicsFormControlRelations('QUERY_BUILDER_ENABLED', UI_COMPONENT_TYPE_CONDITION.CONDITION_ENABLED));
      relations.push(this._doGetNgDynamicsFormControlRelations('QUERY_BUILDER_REQUIRED', UI_COMPONENT_TYPE_CONDITION.CONDITION_REQUIRED));
      relations.push(this._doGetNgDynamicsFormControlRelations('QUERY_BUILDER_VALIDATION', UI_COMPONENT_TYPE_CONDITION.CONDITION_VALIDATION));
      relations = relations.filter(relation => relation != null);

      return relations;
    }

    _doGetNgDynamicsFormControlRelations(matcherKey: string, typeCondition: UI_COMPONENT_TYPE_CONDITION) {
      var conditionQuery = null;
      if (typeCondition == UI_COMPONENT_TYPE_CONDITION.CONDITION_VISIBILITY) {
        conditionQuery = this.S_CONDITIONS_VISIBILITY;
      }
      if (typeCondition == UI_COMPONENT_TYPE_CONDITION.CONDITION_ENABLED) {
        conditionQuery = this.S_CONDITIONS_ENABLED;
      }
      if (typeCondition == UI_COMPONENT_TYPE_CONDITION.CONDITION_REQUIRED) {
        conditionQuery = this.S_CONDITIONS_REQUIRED;
      }
      if (typeCondition == UI_COMPONENT_TYPE_CONDITION.CONDITION_VALIDATION) {
        conditionQuery = this.S_CONDITIONS_VALIDATION;
      }

      var dynamicsFormControlRelations = null;
      if (conditionQuery !== null && conditionQuery.filters !== undefined) {
        var relationsClauses = [];
        conditionQuery.filters.forEach((filter) => {
          relationsClauses.push({ id: filter.field, value: filter.value });
          if (filter.operator.includes('uic_control')) {
            relationsClauses.push({ id: filter.value, value: filter.field });
          }
        })
        dynamicsFormControlRelations = { match: matcherKey, when: relationsClauses };
      }
      return dynamicsFormControlRelations;
    }

    doParseConditions() {
      if (this.S_CONDITIONS_VISIBILITY !== null) {
        try { this.S_CONDITIONS_VISIBILITY = JSON.parse(this.S_CONDITIONS_VISIBILITY); } catch(e) { this.S_CONDITIONS_VISIBILITY = null; }
      }
      if (this.S_CONDITIONS_ENABLED !== null) {
        try { this.S_CONDITIONS_ENABLED = JSON.parse(this.S_CONDITIONS_ENABLED); } catch(e) { this.S_CONDITIONS_ENABLED = null; }
      }
      if (this.S_CONDITIONS_REQUIRED !== null) {
        try { this.S_CONDITIONS_REQUIRED = JSON.parse(this.S_CONDITIONS_REQUIRED); } catch(e) { this.S_CONDITIONS_REQUIRED = null; }
      }
      if (this.S_CONDITIONS_VALIDATION !== null) {
        try { this.S_CONDITIONS_VALIDATION = JSON.parse(this.S_CONDITIONS_VALIDATION); } catch(e) { this.S_CONDITIONS_VALIDATION = null; }
      }
    }


}

export class DbmWorkflowStepDatasource {
    ID: number;
    NUM_WORKFLOW: string;
    NUM_WORKFLOWSTEP: string;
    WDS_TYPE: WDS_TYPE;
    WDS_NUM: string;
    WDS_TITRE: string;
    WDS_DESCRIPTION: string;
    DS_KVP_PARAMS: DS_KVP_PARAMS;
    DS_TR_PARAMS: DS_TR_PARAMS;
    DS_DLL_PARAMS: DS_DLL_PARAMS;
    DS_API_PARAMS: DS_API_PARAMS;
    DS_GLOBAL_DATASOURCE: DS_GLOBAL_DATASOURCE;
    DATE_CREATION: string;
    DATE_MODIFICATION: string;
    SOMMEIL: boolean;
    DATE_SUPPRESSION: string;
    CREE_PAR: number;
}

export class DbmWorkflowStepRedirection {
  ID: number;
  NUM_WORKFLOW: string;
  NUM_WORKFLOWSTEP: string;
  NEXT_WORKFLOWSTEP_NUM: string;
  S_CONDITIONS_REDIRECTION: any | { logic: string, filters: { field: string, value: string, operator: string }[] } = null;
}

export class DbmDatasource {
  ID: number;
  WDS_TYPE: WDS_TYPE;
  WDS_NUM: string;
  WDS_TITRE: string;
  WDS_DESCRIPTION: string;
  DS_KVP_PARAMS: DS_KVP_PARAMS;
  DS_TR_PARAMS: DS_TR_PARAMS;
  DS_DLL_PARAMS: DS_DLL_PARAMS;
  DS_API_PARAMS: DS_API_PARAMS;
  DATE_CREATION: string;
  DATE_MODIFICATION: string;
  SOMMEIL: boolean;
  DATE_SUPPRESSION: string;
  CREE_PAR: number;
  ABS_GRID: AbsGrid;
}

export class DbmVariableEnvironment {
    ID: number;
    NOM_VARIABLE: string;
    LIBELLE_VARIABLE: string;
    TYPE_VARIABLE: TYPE_VARIABLE_ENVIRONMENT = TYPE_VARIABLE_ENVIRONMENT.CUMUL;
    VALUE_VARIABLE: string;
    NUM_WORKFLOW: string;
    NUM_WORKFLOWSTEP: string;
    UIC_IDENTIFIANT: string;
    HAS_REINIT_WORKFLOW: number = 0;
    REINIT_WORKFLOW_NUM: string;
    DATE_CREATION: string;
    DATE_SUPPRESSION: string;
    SOMMEIL: number;
    CREE_PAR: number;
    PUBLIC_VARIABLE: SCOPE_VARIABLE_ENVIRONMENT = SCOPE_VARIABLE_ENVIRONMENT.PUBLIC;
    EXPRESSION_VARIABLE: string;

    VARIABLES_USERS: AbsGrid;
}

export class DbmVariableEnvironmentUser {
  ID: number;
  GUID: string;
  NOM_VARIABLE: string;
  LIBELLE_VARIABLE: string;
  TYPE_VARIABLE: TYPE_VARIABLE_ENVIRONMENT = TYPE_VARIABLE_ENVIRONMENT.CUMUL;
  VALUE_VARIABLE: string;
  NUM_WORKFLOW: string;
  NUM_WORKFLOWSTEP: string;
  UIC_IDENTIFIANT: string;
  HAS_REINIT_WORKFLOW: number = 0;
  REINIT_WORKFLOW_NUM: string;
  DATE_CREATION: string;
  DATE_MODIFICATION: string;
  DATE_SUPPRESSION: string;
  SOMMEIL: number;
  VARIABLE_ENVIRONMENT_ID: number;
  USER_ID: number;
  PUBLIC_VARIABLE: SCOPE_VARIABLE_ENVIRONMENT = SCOPE_VARIABLE_ENVIRONMENT.PUBLIC;
}

export class DbmActivityLog {
  ID: number;
  TITRE: string;
  DESCRIPTION: string;
  TYPE_ACTIVITY: TYPE_ACTIVITY_LOG;
  DATE_CREATION: string;
  AUTEUR: number;
  TEAM_ID: number;
  ITEM_ID: number;
  ITEM_TYPE_OBJECT: string;
  READ_AT: string;
  EXTRA_PROPERTY: any;
}

export class DbmCircuit {
  ID: number;
  NOM_CIRCUIT: string;
  DATE_CREATION: string;
  SOMMEIL: boolean;
  DATE_SUPPRESSION: string;
  NUM_WORKFLOW: string;
  USER_ID: number;
  CIRCUIT_PARAMS: DbmCircuitParams[] = [];
  WORKFLOW: DbmWorkflow;
}

export class DbmCircuitParams {
  ID: number;
  INDEX_PARAMS: number;
  TITRE_PARAMS: string = '';
  FORM_RESPONSES: any = {};
  CIRCUIT_ID: number;
  DATE_CREATION: string;
  SOMMEIL: boolean;
  DATE_SUPPRESSION: string;

  //
  WORKFLOW_RESPONSE: DbmResponseWorkflow;
}

export class DbmCircuitInstance {
  ID: number;
  NOM_CIRCUIT: string;
  DATE_CREATION: string;
  SOMMEIL: boolean;
  DATE_SUPPRESSION: string;
  NUM_WORKFLOW: string;
  USER_ID: number;
  DATE_DEBUT: string;
  DATE_FIN: string;
  STATUS_CIRCUIT: STATUS_CIRCUIT;
  PROGRESSION_CIRCUIT: string;
  CIRCUIT_PARAMS: DbmCircuitInstanceParams[] = [];
  WORKFLOW: DbmWorkflow;
}

export class DbmCircuitInstanceParams {
  ID: number;
  INDEX_PARAMS: number;
  TITRE_PARAMS: string = '';
  FORM_RESPONSES: any = {};
  CIRCUIT_INSTANCE_ID: number;
  DATE_CREATION: string;
  SOMMEIL: boolean;
  DATE_SUPPRESSION: string;
  DATE_DEBUT: string;
  DATE_FIN: string;
  STATUS_CIRCUIT_INSTANCE_PARAMS: STATUS_WORKFLOW;
  USER_ID: number;
  RESPONSE_WORKFLOW_ID: number;
  NUM_WORKFLOW: string;
  NUM_WORKFLOWSTEP: string;

  //
  RESPONSE_WORKFLOW: DbmResponseWorkflow;
  WORKFLOW: DbmWorkflow;
}

export class DbmDBModels {
  ID: number;
  APP_NAME: string;
  APP_VERSIONS: string[];
  SOMMEIL: boolean;
}

export class DbmDBModelsTable {
  ID: number;
  TABLE_NAME: string;
  TABLE_DESCRIPTION: string;
  DBMODEL_TYPE_TABLE: DBMODEL_TYPE_TABLE;
  ABW_MODEL_ID: number;
  ABW_MODEL_VERSION: string;
  SOMMEIL: boolean;
  TABLE_COLUMNS: Model_SqlColumn[] = [];
}

export class DbmDeclencheur {
  ID: number;
  NOM_DECLENCHEUR: string;
  NUM_WORKFLOW: string;
  TYPE_DECLENCHEUR: Enum_TypeDeclencheur;
  MODE_NOTIFICATION: Enum_ModeNotificationDeclencheur;
  EVENT_DECLENCHEUR: Enum_EventDeclencheur;
  DESTS_DECLENCHEUR: Model_DeclencheurDestinataire[];
  MESSAGE_DECLENCHEUR: string;
  TABLES_RUNNER_DECLENCHEUR: string[];
  DATE_CREATION: string;
  DATE_MODIFICATION: string;
  DATE_SUPPRESSION: string;
}

export class Model_SqlColumn {
  COLUMN_NAME: string;
  DATA_TYPE: string;
}

export class JwtToken {
    ID: number;
    PRENOM: string;
    NOM: string;
    USERNAME: string;
    ROLE: number;
    EMAIL: string;
    TELEPHONE: string;
    access_token: string;
    VARIABLES_ENVIRONMENT: DbmVariableEnvironmentUser[];
    RECENTS_ACTIVITY: DbmActivityLog[];
}

export class Model_CustomApi {
    Name: string;
    Description: string;
    RouteURL: string;
    TypeApi: Enum_TypeAPI;
    Properties: Model_CustomApiParameterProperty[];
}

export class Model_CustomDatasource {
  DS_ID: Enum_CustomDatasource;
  DS_NAME: string;
  CODE_SOCIETE: string;
}

export class Model_Geolocalisation {
    LONGITUDE: number;
    LATITUDE: number;
    DATE_LOCALISATION: string;
    ACCURACY: number;
}

export class Model_CustomApiParameterProperty {
    Type_Parameter: string;
    NAME_Parameter: string;
}

export class Model_MobileSynchronisationDatas {
    mWorkflows: DbmWorkflow[] = [];
    mDatasources: DbmDatasource[] = [];
    VARIABLES_ENVIRONMENT: DbmVariableEnvironmentUser[] = [];
    mTeams: DbmTeam[] = [];
    mResponses: DbmResponseWorkflow[] = [];
}

export class ReqParams_HistoryResponseWorkflow {
  BORNE_INF: string;
  BORNE_SUP: string;
  TEAMS_IDS: number[];
  USERS_IDS: number[];
  NUMS_WORKFLOWS: string[];
  MODE_EXECUTION: PLAN_EXECUTION_MODE;
  RESPONSES_WORKFLOW_IDS: number[];

}

export class Model_DeclencheurDestinataire {
  TYPE_DESTINATAIRE: Enum_TypeDestinataireDeclencheur;
  VALUE_DESTINATAIRE: any;
  LIBELLE_DESTINATAIRE: any;
}

export class StartupInfos_VariableEnvironment {
  mWorkflows: DbmWorkflow[] = [];
}

export class StartupInfos_Team {
  mTeams: DbmTeam[] = [];
  mUsers: DbmUser[] = [];
  mWorkflows: DbmWorkflow[] = [];
}

export class StartupInfos_ProcessingWorkflows {
  mWorkflows: DbmWorkflow[] = [];
  mValidatingResponsesWorkflows: DbmResponseWorkflow[] = [];
  mPausedResponsesWorkflows: DbmResponseWorkflow[] = [];
  mDatasources: DbmDatasource[] = [];
}

export class StartupInfos_VariableEnvironmentUser {
  mVariablesEnvironment: DbmVariableEnvironment[];
}

export class StartupInfos_HistoryResponseWorkflow {
  mResponsesWorkflow: DbmResponseWorkflow[];
}

export class StartupInfos_ProcessingCircuits {
  mCircuits: DbmCircuit[] = [];
  mCircuitsInstances: DbmCircuitInstance[] = [];
  mCircuitsInstancesPaginated = new PaginatedList<DbmCircuitInstance>();
}

export class StartupInfos_Circuit {
  mCircuits: DbmCircuit[] = [];
  mWorkflows: DbmWorkflow[] = [];
}

export class DS_TR_PARAMS {
    TR_TABLE: string;
}

export class DS_DLL_PARAMS {
  DLL_METHODE: string;
}

export class DS_GLOBAL_DATASOURCE {
  WDS_NUM: string;
}

export class DS_API_PARAMS {

}

export class AbsColumn {
    mDatasource: string;
    mTitle: string;
    mType: TYPE_COLONNE;
    mTypeDonnees: TYPE_DONNEE;
    mVisible: boolean;
    mTemplate?: string;
}

export class DS_KVP_PARAMS {
    SCHEMA_COLONNES: AbsColumn[];
    GRID_DATA: any[];
    GRID: AbsGrid;
    GRID_OPTIONS: any;
}

export class AbsGrid {
    mAbsColumns: AbsColumn[];
    mColumns: string[];
    mColumnNames: string[];
    mData: any[] = [];
    mGroups?: any[] = [];
}

export enum ABW_ROLE {
    AGENT = 0,
    ADMIN = 1
}

export enum TYPE_DONNEE {
  NUMERIQUE = 0,
  STRING = 1,
  DATETIME = 2,
  NUMERIQUE_POIDS = 3,
  NUMERIQUE_TEMPS = 4,
  NUMERIQUE_MONTANT = 5,
  BOOLEAN = 6
}
export enum TYPE_COLONNE {
  DIMENSION = 0,
  VALEUR = 1,
  VIRTUEL = 2
}

export enum WDS_TYPE {
  KVP = 0,
  TR = 10,// TABLES RUNNERS
  DLL = 20,
  API = 40
}

export enum STEP_TYPE
{
    FORMULAIRE = 0,
    CHRONOMETRE = 1
}
export enum STEP_TYPE_GEOLOC
{
    AUCUNE = 0,
    PONCTUELLE = 1,
    GEOTRACKING = 2
}

export enum UI_COMPONENT_TYPE_CONDITION {
  CONDITION_VISIBILITY = 1,
  CONDITION_ENABLED = 2,
  CONDITION_REQUIRED = 3,
  CONDITION_VALIDATION = 4,
}


//#region Variable Environment
export enum TYPE_VARIABLE_ENVIRONMENT {
    CONSTANT = 0,
    CUMUL = 1,
    MAPPING = 2,
    COMPUTED = 3
}

export enum SCOPE_VARIABLE_ENVIRONMENT {
    PRIVATE = 0,
    PUBLIC = 1
}
//#endregion

//#region Response Workflow
export enum STATUS_WORKFLOW {
    NULL = -1,
    RUNNING = 0,
    PAUSED = 1,
    VALIDATING = 2,
    ACHIEVED = 3,
    CANCELED = 4,
    REJECTED = 5,
}

export enum STATUS_WORKFLOW_VALIDATION {
  REJECT = -1,
  VALID = 1
}

export enum Enum_ModeWorkflow {
  OFFLINE = 0,
  ONLINE = 1
}
//#endregion

export enum WORKFLOW_EXECUTION_MODE {
  READING = 0,
  EXECUTING = 1,
  CONTROLLING = 2,
  CIRCUIT = 3,
  DESIGN = 4
}


export enum TYPE_ACTIVITY_LOG {
    ABW_OTHER_ACTIVITY = -1,
    WORKFLOW_EXECUTION_FINISHED = 1,
    WORKFLOW_EXECUTION_SUBMIT_TO_VALIDATION = 2,
    WORKFLOW_EXECUTION_VALIDATED = 3,
    WORKFLOW_EXECUTION_REJECTED = 4
}

export enum STATUS_CIRCUIT {
  NULL = -1,
  RUNNING = 0,
  PAUSED = 1,
  VALIDATING = 2,
  ACHIEVED = 3,
  CANCELED = 4,
  REJECTED = 5,
}

export enum DBMODEL_TYPE_TABLE {
  TABLE_STRUCTURE = 1,
  TABLE_TRANSACTION = 2
}

export enum Enum_WorkflowUIComponent
{
  CHECKBOX = 30,
  RADIOBOX = 40,
  SCAN_DOCUMENT = 50,
  SCAN_CODE_BARRE = 60,
  ELECTRONIC_SIGNATURE = 70,
  LOCALISATION_GPS = 80,
  CHRONOMETRE = 90,
  DROPDOWN = 100,
  TEXTBOX = 110,
  NUMERIC_TEXTBOX = 120,
  TEXTAREA = 130,
  DATEPICKER = 140,
  UPLOAD_FILE = 150,
  INPUT_GRID = 160,
  FAMOCO_SCAN_LASER = 170,
  SOUDOTEC_INVENTORY = 180,
  FAMOCO_SCAN_FINGERPRINT = 190,
  MIBEM_COMMAND = 200,
  MIBEM_COMMAND_GRIDINPUT = 210,
  SOUDOTEC_COMMAND_GRIDINPUT = 220,
  MAP_PICKER = 230,
  CAMERA = 240,
  BUTTON = 250,
  SOUDOTEC_BARCODES_VERIFICATION = 260,
  SOUDOTEC_SCAN_EMPLACEMENT = 270,
  CARD_PICKER = 280,
} 

export enum Enum_TypeAPI {
    ANY = 0,
    FIND_ITEM = 1,
    LIST_ITEMS = 2,
    DATASOURCES = 3
}

export enum Enum_TypeDeclencheur {
  WORKFLOW = 1,
  RUNNER = 2,
}

export enum Enum_ModeNotificationDeclencheur {
    NONE = 0,
    NOTIFICATION_PUSH = 1,
    NOTIFICATION_MAIL = 2,
    NOTIFICATION_SMS = 3,
    NOTIFICATION_PUSH_RUNNER = 4,
}

export enum Enum_EventDeclencheur {
  END_OF_WORKFLOW = 1,
  END_OF_SYNCHRONISATION = 2,
}

export enum Enum_TypeDestinataireDeclencheur {
  AGENT = 1,
  WORKFLOW_FORM_CONTROL = 2,
  CUSTOM = 3
}

export enum Enum_ActionDeclencheur {
    START_WORKFLOW_INSTANCE = 1
}

export enum STATUS_SYNCHRONISATION {
    EN_ATTENTE = 0,
    EN_COURS = 1,
    TERMINE = 2,
    ANNULE = 3,
    ECHEC = 4
}

export enum Enum_CustomDatasource {
    DS_ARTICLES_CLIENTS_SIMAM = 1
}

export class PaginatedList<T> {
  Datas: T[];
  CurrentPage: number;
  TotalPages: number;
  PageSize: number;
  TotalCount: number;

  constructor() {
    this.Datas = [];
    this.CurrentPage = 0;
    this.TotalCount = 0;
    this.TotalPages = 0;
    this.PageSize = 0;
    }
}

export class QueryParams {
    PaginationEnabled: boolean;
    PageNumber: number;
    PageSize: number;
    OrderByColumn?: string;
    OrderByDirection?: string;
    DateDebut?: string;
    DateFin?: string;
    IncludeDeleted?: boolean;
}