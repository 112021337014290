<ng-container [formGroup]="group">
    <my-custom-form-control style="display: block;"
      [formControlName]="model.id"
      [id]="bindId ? model.id : null"
      [name]="model.name"
      [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)">
    </my-custom-form-control>
  </ng-container>