<ng-container [formGroup]="group">
    <cfc-soudotec-command-gridinput style="display: block;"
      [formControlName]="model.id"
      [id]="bindId ? model.id : null"
      [name]="model.name"
      [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
      [doPiece]="model.DoPiece"
      [doType]="model.DoType"
      [doDomaine]="model.DoDomaine"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)">
    </cfc-soudotec-command-gridinput>
</ng-container>
