import { Injectable } from "@angular/core";
import { DatasourcesOfflineDao } from "./datasources-offline.dao";
import { EnvVarUserOfflineDao } from "./envvaruser-offline.dao";
import { ResponsesOfflineDao } from "./responses-offline.dao";
import { TeamsOfflineDao } from "./teams-offline.dao";
import { WorkflowsOfflineDao } from "./workflows-offline.dao";

@Injectable()
export class UnitOfWorkOfflineDao {
    constructor(
        public datasourcesDao: DatasourcesOfflineDao,
        public envVarUserDao: EnvVarUserOfflineDao,
        public workflowsDao: WorkflowsOfflineDao,
        public responsesDao: ResponsesOfflineDao,
        public teamsDao: TeamsOfflineDao
    ) {}
}