import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from 'src/app/core/core.module';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { ApiService } from './core/api.service';
import { ToastrModule } from 'ngx-toastr';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ConnectorSettingsAPIService } from './views/settings-api/connector-settings-api.service';
import { OfflineApiModule } from './@offline-api/offline-api.module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import {HardwareBridgeService} from "./shared/bridge/hardware-bridge.service";
import {WebbrowserBridgeService} from "./shared/bridge/webbrowser-bridge.service";
import {AndroidBridgeService} from "./shared/bridge/android-bridge.service";

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    // Core
    CoreModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    DateInputsModule,
    OfflineApiModule,
    SimpleNotificationsModule.forRoot()
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    ApiService,
    ConnectorSettingsAPIService,
    WebbrowserBridgeService,
    AndroidBridgeService,
    HardwareBridgeService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
