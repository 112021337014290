<div>
  <div class="d-flex justify-content-center align-items-center mb-3" style="min-height: 300px;background: #eee;border-radius: 5px;flex-direction: column;">
    <div class="d-flex flex-column align-items-center" *ngIf="itemsScanned == null || itemsScanned.length == 0">
      <i class="iconsminds-qr-code"></i>
      <h4 style="color: #444">Scanner un article</h4>
    </div>
    <div style="width: 100%" *ngIf="itemsScanned != null && itemsScanned.length != 0">
      <div class="animate__animated animate__bounceIn" style="width: 100%" *ngFor="let item of itemsScanned; let rowIndex = index">
        <div class="d-flex" style="width: 100%">
          <div class="product-desc" style="width: 100%;flex-direction: row;background: #fff;padding: 10px;box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6); margin-bottom: 10px">
            <h3 style="margin-bottom: 0px;font-size: 14px;">
              {{ item.AR_Design || item.AR_Ref }}
            </h3>
            <div>
              <strong style="font-weight: 400; font-size: 12px">{{ item.AR_Ref }}</strong>
            </div>
            <div class="d-flex justify-content-between" style="margin-top: 10px">
              <div style="margin-right: 30px;">
                <button type="button" class="btn btn-danger" (click)="onRemoveButtonClicked(rowIndex)">Supprimer</button>
              </div>
              <div class="quantity">
                <input type="button" value="-" class="minus" (click)="onMinusQteButtonClicked(rowIndex)" />
                <input type="text" [(ngModel)]="item.QUANTITE_COMPTEE" size="4" style="float: left;margin-top: 6px;text-align: center" />
                <input type="button" value="+" class="plus" (click)="onPlusQteButtonClicked(rowIndex)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div role="alert" class="alert alert-danger ng-star-inserted" *ngIf="showAlert">{{ msgAlert }}</div>
  </div>
  <div class="d-flex justify-content-center">
    <button class="btn btn-primary" (click)="enterCode()" style="margin-right: 15px">
      <i class="glyph-icon simple-icon-note"></i>
      Saisir un code Article
    </button>
    <button class="btn btn-info" (click)="scanCode()">
      <i class="iconsminds-qr-code"></i>
      Scanner un code QR / Barcode
    </button>
  </div>
</div>
