import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: 'cfc-card-picker',
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CfcCardPickerComponent),
        multi: true
      },
      {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => CfcCardPickerComponent),
        multi: true
      }
    ], 
    templateUrl: './cfc-card-picker.component.html',
    styleUrls: ['./cfc-card-picker.component.scss']
})
export class CfcCardPickerComponent implements ControlValueAccessor, OnChanges {

    @Input() required = false;
    @Input() options: any[] = [];
    @Input() value: any = null;
    @Output() hasChanged = new EventEmitter<void>();
    
    constructor(
      private changeDetectorRef: ChangeDetectorRef
    ) {
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
    }

    propagateChange = (_: any) => {};
    registerOnChange(fn) { this.propagateChange = fn; }
    registerOnTouched() {}
    writeValue(value: any) {
        if (!!value) {
            this.value = value;
            this.propagateChange(this.value);
            this.hasChanged.emit();
        }
    }

    validate({ value }: FormControl) {
        if (this.required && !value) {
            return  { required: true };
        }
        return null;
    }

    doSelectOption(_value) {
        this.writeValue(_value);
    }
}