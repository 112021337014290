import {ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";

@Component({
  selector: 'cfc-camera',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcCameraComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CfcCameraComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-camera.component.html'
})
export class CfcCameraComponent implements ControlValueAccessor {

  @Input() required = false;
  photo: any = null;
  propagateChange = (_: any) => {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private hardwareBridge: HardwareBridgeService
  ) {}

  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.photo = value;
    }
  }

  validate({ value }: FormControl) {
    const isNotValid = this.required && value == null;
    return isNotValid && {
      invalid: true
    }
  }

  openCamera() {
    this.hardwareBridge.openCamera()
    .then((result) => {
      this.photo = result;
      this.propagateChange(result);
      this.changeDetectorRef.detectChanges();
    });
  }

}
