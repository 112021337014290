import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HbridgeInterface, ResultUpdate} from "./hbridge.interface";

declare var Android: any;

@Injectable({
  providedIn: 'root',
})
export class AndroidBridgeService implements HbridgeInterface {

  onLocationChanged = new Subject<{ latitude: number, longitude: number, accuracy: number }>();
  onDownloadUpdateChanged = new Subject<ResultUpdate>();

  getGeolocationPoint(): Promise<{ longitude: number, latitude: number, accuracy: number }> {
    return new Promise<{ longitude: number, latitude: number, accuracy: number }>((resolve, reject) => {
      const callbackName = 'ANDROID_API_PONCTUAL_GEOLOCATION';
      window[callbackName] = (location: { latitude: number, longitude: number, accuracy: number}) => {
        delete window[callbackName];
        resolve(location);
      };
      Android.getCurrentLocation();
    });
  }

  watchGeolocationTracking() {
    const callbackName = 'ANDROID_API_GEOTRACKING_GEOLOCATION';
    window[callbackName] = (location: { latitude: number, longitude: number, accuracy: number}) => {
      this.onLocationChanged.next(location);
    };
    Android.startGeolocationTracking();
  }

  stopGeolocationTracking() {
    const callbackName = 'ANDROID_API_GEOTRACKING_GEOLOCATION';
    delete window[callbackName];
    this.onLocationChanged.complete();
    Android.stopGeolocationTracking();
  }

  openCodeScanner(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      var callbackName = 'ANDROID_API_BARCODE_SCANNER';
      window[callbackName] = (barcodeScanned: string) => {
        delete window[callbackName];
        resolve(barcodeScanned);
      };
      Android.openCodeScanner();
    });
  }

  openDocumentScanner(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      var callbackName = 'ANDROID_API_DOCUMENT_SCANNER';
      window[callbackName] = (documentScanned: string) => {
        delete window[callbackName];
        resolve(documentScanned);
      };
      Android.openDocumentScanner();
    });
  }

  openCamera(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      var callbackName = 'ANDROID_API_CAMERA';
      window[callbackName] = (barcodeScanned: string) => {
        delete window[callbackName];
        resolve(barcodeScanned);
      };
      Android.openCamera();
    });
  }

  showNotification(title: string, text: string) {
    Android.showNotification(title, text);
  }

  openFamocoLaser(): Promise<any> {
    return new Promise<string>((resolve, reject) => {
      var callbackName = 'ANDROID_API_FAMOCO_SCANNER';
      window[callbackName] = (barcodeScanned: string) => {
        delete window[callbackName];
        resolve(barcodeScanned);
      };
      Android.openFamocoScanner();
    });
  }

  openFingerprintScanner(): Promise<any> {
    return new Promise((resolve, reject) => reject('Not Implemented yet'));
  }

  getAppVersion(): string {
    const appVersion = Android.getAppVersion();
    return appVersion
  }

  downloadUpdateApp(url: string, version: string) {
    return new Promise((resolve, reject) => {
      const callbackName = 'ANDROID_API_UPDATE';
      window[callbackName] = (progress: ResultUpdate) => {
        this.onDownloadUpdateChanged.next(progress);
        resolve();
      };
      Android.selfUpdateApp(url, version);
    });
  }

  cancelUpdateApp() {
    return new Promise((resolve, reject) => {
      Android.cancelUpdateApp();
      resolve();
    });
  }

}
