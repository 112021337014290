import { Injectable } from "@angular/core";
import { AbsGrid } from "../../views/app/app.models";
import { DatasourcesOfflineService } from "../services/datasources-offline.service";

@Injectable()
export class DatasourcesOfflineApi {
    constructor(private _datasourcesOfflineService: DatasourcesOfflineService) {}

    doBuildGridDatasourceAction_Datasource(_id: number): Promise<AbsGrid> {
        return this._datasourcesOfflineService.doBuildGridDatasourceAction_Datasource(_id);
    }
}