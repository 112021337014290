import {HttpClient, HttpParams} from '@angular/common/http';
import { ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/api.service';
import { CfcSoudotecCommandScanItemComponent } from './cfc-soudotec-command-scan-item.component';
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";
import { CfcSoudotecCommandEmplacementsDialogComponent } from './cfc-soudotec-command-emplacements-dialog/cfc-soudotec-command-emplacements-dialog.component';

function _window(): any {
  // return the global native browser window object
  return window;
}

enum Command_View {
  IDLE = 0,
  COMMAND_LOADING = 1,
  COMMAND_LOADED = 2,
  COMMAND_LOADING_ERROR = 3
}

export class Model_SoudotecCommandGridInput {
  DO_Piece: string;
  GridDatas: {[key: number]: number};
}

@Component({
  selector: 'cfc-soudotec-command-gridinput',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcSoudotecCommandGridInputComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-soudotec-command-gridinput.component.html',
  styleUrls: ['./cfc-soudotec-command-gridinput.component.scss']
})
export class CfcSoudotecCommandGridInputComponent implements OnInit, ControlValueAccessor, OnChanges {

  viewMode = Command_View.IDLE;
  loadingStatus = '';
  Command_View = Command_View;
  @Input() doPiece: string;
  @Input() doDomaine: number;
  @Input() doType: number;
  document: any = {};

  gridForms: {[key: number]: FormControl} = {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private api: ApiService,
    private httpClient: HttpClient,
    private hardwareBridge: HardwareBridgeService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    if (!!this.doPiece) {
      this.doGetSageDocumentAction(this.doPiece);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.doPiece && !!changes.doPiece.currentValue && !this.doPiece) {
      this.doPiece = changes.doPiece.currentValue;
      this.doGetSageDocumentAction(this.doPiece);
    }
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.propagateChange(value);
      this.changeDetectorRef.detectChanges();
    }
  }

  doGetSageDocumentAction(_doPiece: string) {
    this.viewMode = Command_View.COMMAND_LOADING;
    let params = new HttpParams();
    params = params.append('DO_Domaine', this.doDomaine.toString());
    params = params.append('DO_Type', this.doType.toString());
    params = params.append('DO_Piece', this.doPiece);
    this.httpClient.get(this.api.endPoints.doGetSageDocumentAction_SageDocument + "/" + _doPiece, { params: params })
    .subscribe((_document: any) => {
      try {
        this._processSageDocument(_document);
        this.viewMode = Command_View.COMMAND_LOADED;
      } catch(e) {
        this.loadingStatus = 'Une erreur est survenue lors de l\'affichage du document. Assurez vous que les paramètres soient correctement synchronisés. (Contactez un administrateur).';
        this.viewMode = Command_View.COMMAND_LOADING_ERROR;
        console.log(e);
      }
      this.changeDetectorRef.detectChanges();
    }, err => {
      this.loadingStatus = 'Une erreur est survenue lors du chargement de la commande. Veuillez réessayer plus tard.';
      this.viewMode = Command_View.COMMAND_LOADING_ERROR;
      console.log(err);
    });
  }

  _processSageDocument(_document: any) {
    this._initFormControls(_document);
    this.document = _document;
    this.processGridForm();
  }

  isRowIncorrect(docLigne: any) {
    const qteCommande = docLigne.DL_Qte != null ? docLigne.DL_Qte : 0;
    const qteChargement = this.gridForms[docLigne['cbMarq']].value != null ? this.gridForms[docLigne['cbMarq']].value : 0;

    return qteCommande != qteChargement;
  }

  doScanCode() {
    this.hardwareBridge.openFamocoLaser()
    .then((_scanCode) => {
      if (!!_scanCode) {
        const scannedArticleIndex = this.document.LIGNES.findIndex(l => l.AR_CodeBarre == _scanCode);
        if (scannedArticleIndex > -1) {
          const scannedArticle = this.document.LIGNES[scannedArticleIndex];
          const dialogRef = this.dialog.open(CfcSoudotecCommandScanItemComponent, { data: { docLigne: scannedArticle }, panelClass: 'custom-dialog-container' });
          dialogRef.afterClosed().subscribe((result: { cbMarq: number, DL_Qte: number }) => {
            this.gridForms[result['cbMarq']].patchValue(result.DL_Qte);
          });
        }
      }
    }, err => {
      console.log(err);
    });
  }

  controlMaxValue(docLigne: any) {
    if (this.doType == 2 && this.gridForms[docLigne.cbMarq].value > docLigne.DL_Qte) {
      this.gridForms[docLigne.cbMarq].patchValue(docLigne.DL_Qte);
    } else if (this.gridForms[docLigne.cbMarq].value < 0) {
      this.gridForms[docLigne.cbMarq].patchValue(0);
    }
  }

  openEmplacementsDialog(ligne: any) {
    this.dialog.open(CfcSoudotecCommandEmplacementsDialogComponent, {
      data: {
        ligne: ligne,
      },
      id: 'soudotec_modal'
    });
  }

  private _initFormControls(_document: any) {
    var gridForms = {};
    _document.LIGNES.forEach((ligne) => {
      gridForms[ligne['cbMarq']] = new FormControl(/*ligne.DL_Qte*/0);
    });
    this.gridForms = gridForms;
  }

  private processGridForm() {
    var gridDatas: any = {};
    for(var cbMarq in this.gridForms) {
      gridDatas[cbMarq] = this.gridForms[cbMarq].value;
    }
    this.writeValue({
      DO_Domaine: this.doDomaine,
      DO_Type: this.doType,
      DO_Piece: this.doPiece,
      GridDatas: gridDatas
    });
  }

}
