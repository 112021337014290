import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControlComponent, DynamicFormControlCustomEvent, DynamicFormLayout, DynamicFormLayoutService, DynamicFormValidationService } from '@ng-dynamic-forms/core';
import { CfcCardPickerComponent } from './cfc-card-picker.component';
import { CfcCardPickerModel } from './cfc-card-picker.model';

@Component({
    selector: 'dynamic-cfc-card-picker',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dynamic-cfc-card-picker.component.html',
})
export class DynamicCfcCardPickerComponent extends DynamicFormControlComponent {

    @Input() bindId: boolean = true;
    @Input() group: FormGroup;
    //@Input() layout: DynamicFormLayout;
    @Input() model: CfcCardPickerModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild(CfcCardPickerComponent) myCustomFormControlComponent: CfcCardPickerComponent;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
                private _cdf: ChangeDetectorRef) {
        super(layoutService, validationService);
    }

    hasChanged() {
        this._cdf.detectChanges();
    }
}
