import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'my-custom-form-control',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MyCustomFormControlComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-test.component.html'
})
export class MyCustomFormControlComponent implements ControlValueAccessor {
  @Input()
  name: string;

  @Input()
  _counterValue = 0; // notice the '_'

  get counterValue() {
    return this._counterValue;
  }

  set counterValue(val) {
    this._counterValue = val;
    this.propagateChange(this._counterValue);
  }

  increment() {
    this.counterValue++;
  }

  decrement() {
    this.counterValue--;
  }

  propagateChange = (_: any) => {};
  
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  
  registerOnTouched() {}

  writeValue(value: any) { 
    if (value !== undefined) {
      this.counterValue = value;
    }
  }

}