import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'cfc-input-datepicker',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcInputDatePickerComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-input-datepicker.component.html'
})
export class CfcInputDatePickerComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() options: { value: any, label: string }[] = [];
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() value: any;

  public format: any = {
    displayFormat: 'dd/MM/yyyy',
    inputFormat: 'dd/MM/yy'
  };

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.propagateChange(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value !== undefined) {
      try { this.value = new Date(changes.value.currentValue); } catch(e) { console.log(e); this.value = null; }
    }
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      try { this.value = new Date(value); } catch(e) { console.log(e); }
      this.changeDetectorRef.detectChanges();
    }
  }

  onValueChanged(value: number) {
    this.propagateChange(value);
  }

}
