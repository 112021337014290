<ng-container [formGroup]="group">
  <cfc-text-autocomplete style="display: block;"
    [formControlName]="model.id"
    [id]="bindId ? model.id : null"
    [name]="model.name"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"
    [workflowStep]="model.workflowStep">
  </cfc-text-autocomplete>
</ng-container>
