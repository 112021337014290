import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../../core/api.service";
import { DbmResponseWorkflow, DbmVariableEnvironmentUser, Model_MobileSynchronisationDatas } from "../../views/app/app.models";
import { UnitOfWorkOfflineDao } from "../daos/unitofwork-offline.dao";
import * as moment from "moment";

@Injectable()
export class SynchronisationOfflineService {
    constructor(
        private _unitOfWork: UnitOfWorkOfflineDao,
        private httpClient: HttpClient,
        private apiService: ApiService,
    ) {}

    doSynchronizeAppAction(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await this._doSynchronizeLocalResponses();
                await this._doSynchronizeLocalDatas();
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    private _doSynchronizeLocalResponses(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const responses = await this._unitOfWork.responsesDao.doGetResponsesAction();
            responses.forEach(async(response) => {
                try {
                    const savedResponse = await this.httpClient.post<{ ENVIRONMENT_VARIABLES: DbmVariableEnvironmentUser[], RESPONSE_WORKFLOW: DbmResponseWorkflow }>(this.apiService.endPoints.doSaveResponseWorkflowAction_ResponseWorkflows, response).toPromise();
                    savedResponse.RESPONSE_WORKFLOW.SYNCED = true;
                    savedResponse.RESPONSE_WORKFLOW.DATE_SYNCHRONISATION = moment().format();
                    await this._unitOfWork.responsesDao.doSaveResponsesAction(savedResponse.RESPONSE_WORKFLOW);
                    //await this._unitOfWork.responsesDao.doDeleteResponsesAction(savedResponse.RESPONSE_WORKFLOW.GUID);
                } catch(e) {

                }
            });
            resolve();
        });
    }

    private _doSynchronizeLocalDatas(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.httpClient.post<Model_MobileSynchronisationDatas>(this.apiService.endPoints.doSynchronizeDatasAction_Synchronisation, {})
            .subscribe((_syncDatas: Model_MobileSynchronisationDatas) => {
                Promise.all([
                    this._unitOfWork.workflowsDao.doSyncWorkflowsAction(_syncDatas.mWorkflows),
                    this._unitOfWork.datasourcesDao.doSyncDatasourcesAction(_syncDatas.mDatasources),
                    this._unitOfWork.envVarUserDao.doSyncEnvironmentVariablesUserAction(_syncDatas.VARIABLES_ENVIRONMENT),
                    this._unitOfWork.teamsDao.doSyncTeamsAction(_syncDatas.mTeams)
                ]).then(() => {
                    resolve();
                }, err => {
                    console.log(err);
                    reject(err);
                })
            }, err => {
                console.log(err);
                reject(err);
            });
        });
    }

    doClearOfflineDatabaseAction(): Promise<void> {
        return new Promise((resolve, reject) => {
            Promise.all([
                //this._unitOfWork.responsesDao.doSyncResponsesAction([]),
                this._unitOfWork.responsesDao.doDeleteSyncedResponsesAction(),
                this._unitOfWork.workflowsDao.doSyncWorkflowsAction([]),
                this._unitOfWork.datasourcesDao.doSyncDatasourcesAction([]),
                this._unitOfWork.envVarUserDao.doSyncEnvironmentVariablesUserAction([]),
                this._unitOfWork.teamsDao.doSyncTeamsAction([])
            ]).then(() => {
                resolve();
            }, err => {
                console.log(err);
                reject(err);
            });
        });
    }
}
