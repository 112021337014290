import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
  } from "@ng-dynamic-forms/core";

  export interface CfcSignaturePadModelConfig extends DynamicFileUploadModelConfig { }

  export class CfcSignaturePadModel extends DynamicFileUploadModel {
    @serializable() readonly type: string = 'CfcSignaturePad';

    constructor(config: CfcSignaturePadModelConfig, layout?: DynamicFormControlLayout) {
      super(config, layout);
    }
  }
