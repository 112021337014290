<ng-container [formGroup]="group">
    <cfc-input-datepicker style="display: block;"
      [formControlName]="model.id"
      [id]="bindId ? model.id : null"
      [name]="model.name"
      [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)"
      [readOnly]="model.readOnly"
      [disabled]="model.disabled"
      [required]="model.required"
      [value]="model.value">
    </cfc-input-datepicker>
    <span class="cfc-error-message" *ngIf="group.dirty && group.controls[model.id].errors?.required">Le champ {{ model.name }} est requis</span>
  </ng-container>
