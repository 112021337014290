import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import SignaturePad from 'signature_pad';

declare var $: any;

@Component({
  selector: 'cfc-signature-pad',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CFCSignaturePadComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-signature-pad.component.html',
  styleUrls: ['./cfc-signature-pad.component.scss']
})
export class CFCSignaturePadComponent implements AfterViewInit, ControlValueAccessor {
  
  signaturePad: SignaturePad = null;
  signatureImg: any = null;
  @Input() name: string;
  @ViewChild('canvasPad', { static: false }) canvasPad: ElementRef;
  @ViewChild('wraperPad', { static: false }) wraperPad: ElementRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      
      this.signatureImg = value;
      this.propagateChange(value);
      this.changeDetectorRef.detectChanges();
    }
  }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasPad.nativeElement, {
      onEnd: (e) => {
        this.signatureImg = this.signaturePad.toDataURL();
        this.signatureImg = this.signatureImg.replace('data:image/png;base64,', '');
        this.writeValue(this.signatureImg);
      }
    });
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signatureImg = null;
    this.writeValue(this.signatureImg);
  }

}
