import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { Enum_SoudotecBarcodesVerificationStatus, Labels_SoudotecBarcodesVerificationStatus, Model_SoudotecBarcodesVerification } from './cfc-soudotec-barcodes-verification.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/core/api.service';

enum Command_View {
  IDLE = 0,
  COMMAND_LOADING = 1,
  COMMAND_LOADED = 2,
  COMMAND_LOADING_ERROR = 3
}

@Component({
  selector: 'cfc-soudotec-barcodes-verification',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcSoudotecBarcodesVerificationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CfcSoudotecBarcodesVerificationComponent),
      multi: true
    }
  ],
  viewProviders: [
      {
          provide: ControlContainer,
          useExisting: FormGroupDirective
      }
  ],
  templateUrl: './cfc-soudotec-barcodes-verification.component.html',
  styleUrls: ['./cfc-soudotec-barcodes-verification.component.scss']
})
export class CfcSoudotecBarcodesVerificationComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {

  viewMode = Command_View.IDLE;
  loadingStatus = '';
  value: any;
  Enum_SoudotecBarcodesVerificationStatus = Enum_SoudotecBarcodesVerificationStatus;
  ngControl: NgControl | undefined;
  @Input() required = false;
  @Input() doPiece: string;
  @Input() doDomaine: number;
  @Input() doType: number;
  @Output() change = new EventEmitter<any>();
  Command_View = Command_View;
  articles: any[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private api: ApiService,
    private httpClient: HttpClient,
    private inj: Injector
  ) { }
 
  ngOnInit(): void {
    if (!!this.doPiece) {
      this.doGetSageDocumentAction(this.doPiece).then(() => {
        this._evaluateComponentValue();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.doPiece && !!changes.doPiece.currentValue && !this.doPiece) {
      this.doPiece = changes.doPiece.currentValue;
      this.doGetSageDocumentAction(this.doPiece).then(() => {
        this._evaluateComponentValue();
      });
    }
  }

  ngAfterViewInit(): void {
      this.ngControl = this.inj.get(NgControl);
      if (!!this.ngControl.value) {
        this.writeValue(this.ngControl.value);
      }
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      this.changeDetectorRef.detectChanges(); 
    }
  }

  validate({ value }: FormControl) {
    const isNotValid = this.required && value != null && value.filter(x => x.STATUS == Enum_SoudotecBarcodesVerificationStatus.CORRESPONDANCE_NON_OK).length > 0;
    return isNotValid && {
      invalid: true
    }
  }

  doGetLabelStatut(_verification: Model_SoudotecBarcodesVerification) {
      if (!Labels_SoudotecBarcodesVerificationStatus[_verification.STATUS]) { return ''; }
      return Labels_SoudotecBarcodesVerificationStatus[_verification.STATUS];
  }

  doGetSageDocumentAction(_doPiece: string) {
    return new Promise<void>((resolve, reject) => {
      this.viewMode = Command_View.COMMAND_LOADING;
      let params = new HttpParams();
      params = params.append('DO_Domaine', this.doDomaine.toString());
      params = params.append('DO_Type', this.doType.toString());
      params = params.append('DO_Piece', this.doPiece);
      this.httpClient.get(this.api.endPoints.doGetSageDocumentAction_SageDocument + "/" + _doPiece, { params: params })
      .subscribe((_document: any) => {
        try {
          this.articles = _document.LIGNES.map(l => ({ 
            AR_CodeBarre: l.AR_CodeBarre, 
            AR_Ref: l.AR_Ref, 
            DL_Design: l.DL_Design, 
            SCAN_CODE: new FormControl(null, Validators.required),
            STATUS: !!l.AR_CodeBarre ? Enum_SoudotecBarcodesVerificationStatus.CORRESPONDANCE_NON_OK : Enum_SoudotecBarcodesVerificationStatus.AUCUNE_CORRESPONDANCE 
          }));
          this.viewMode = Command_View.COMMAND_LOADED;
        } catch(e) {
          this.loadingStatus = 'Une erreur est survenue lors de l\'affichage du document. Assurez vous que les paramètres soient correctement synchronisés. (Contactez un administrateur).';
          this.viewMode = Command_View.COMMAND_LOADING_ERROR;
          console.log(e);
        }
        this.changeDetectorRef.detectChanges();
        resolve();
      }, err => {
        this.loadingStatus = 'Une erreur est survenue lors du chargement de la commande. Veuillez réessayer plus tard.';
        this.viewMode = Command_View.COMMAND_LOADING_ERROR;
        console.log(err);
        reject(err);
      });
    });
  }

  onScanned(_rowIndex) {
    let statut = Enum_SoudotecBarcodesVerificationStatus.CORRESPONDANCE_OK;
    const article = this.articles[_rowIndex];
    const scanCode = this.articles[_rowIndex].SCAN_CODE.value;
    if (article.AR_CodeBarre === scanCode) {
        statut = Enum_SoudotecBarcodesVerificationStatus.CORRESPONDANCE_OK;
    } else {
        statut = Enum_SoudotecBarcodesVerificationStatus.CORRESPONDANCE_NON_OK;
    }
    this.articles[_rowIndex].STATUS = statut;

    this._evaluateComponentValue();
  }

  private _evaluateComponentValue() {
    this.value = this.articles.map(a => ({ AR_CodeBarre: a.AR_CodeBarre, AR_Ref: a.AR_Ref, DL_Design: a.DL_Design, SCAN_CODE: a.SCAN_CODE.value, STATUS: a.STATUS }));
    console.log("EVALUATION", this.value);
    this.propagateChange(this.value);
  }

}
