import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";

@Component({
  selector: 'cfc-famoco-fingerprint',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcFamocoFingerprintComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-famoco-fingerprint.component.html'
})
export class CfcFamocoFingerprintComponent implements ControlValueAccessor {

  @Input() typeScan: number;
  scannedFingerprint: any;
  scanID: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private hardwareBridge: HardwareBridgeService
  ) {
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.propagateChange(value);
      this.changeDetectorRef.detectChanges();
    }
  }
  validate({ value }: FormControl) {
    var isNotValid = false;
    return isNotValid && {
      invalid: true
    };
  }

  scanFingerprint() {
    this.hardwareBridge.openFingerprintScanner()
    .then((result) => {
      this.scannedFingerprint = result;
      this.processValue();
    });
  }

  processValue() {
    const componentValue = {
      FINGERPRINT_ID: this.scanID,
      FINGERPRINT_DATAS: this.scannedFingerprint
    };
    this.writeValue(componentValue);
  }

}
