import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { WORKFLOW_EXECUTION_MODE } from 'src/app/views/app/app.models';
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";

function _window(): any {
  // return the global native browser window object
  return window;
}

@Component({
  selector: 'cfc-input-locationgps',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcInputLocationGPSComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-input-locationgps.component.html'
})
export class CfcInputLocationGPSComponent implements OnInit, ControlValueAccessor {

  _locationGPS = { longitude: null, latitude: null };
  _locationGPSFormated: string;
  @Input() required = false;
  @Input() modeExecution: WORKFLOW_EXECUTION_MODE;

  constructor(private hardwareBridge: HardwareBridgeService, protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.propagateChange(null);
    if (this.modeExecution === undefined || this.modeExecution !== WORKFLOW_EXECUTION_MODE.CIRCUIT) {
      this.hardwareBridge.getGeolocationPoint()
      .then((location: { longitude: number, latitude: number }) => {
        this._locationGPS = location;
        if (this._locationGPS != null) {
          this._locationGPSFormated = this._locationGPS.latitude + ";" + this._locationGPS.longitude;
        }
        this.propagateChange(this._locationGPSFormated);
        this.ref.detectChanges();
      });
    }
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) { }

}
