import { Injectable } from "@angular/core";
import { DbmWorkflow } from "../../views/app/app.models";

const TB_WORKFLOWS_NAME = "LOCAL_TB_WORKFLOWS";

@Injectable()
export class WorkflowsOfflineDao {
    doSyncWorkflowsAction(_workflows: DbmWorkflow[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doClearWorkflowsAction();
            this.doInsertWorkflowsAction(_workflows).then(() => {
                resolve();
            }, err => reject(err));
        });
    }

    doClearWorkflowsAction() {
        localStorage.setItem(TB_WORKFLOWS_NAME, "[]");
    }

    doGetWorkflowsAction(): Promise<DbmWorkflow[]> {
        return new Promise((resolve, reject) => {
            var datas: DbmWorkflow[] = [];
            try {
                datas = JSON.parse(localStorage.getItem(TB_WORKFLOWS_NAME));
            } catch(e) { console.log(e) }
            resolve(datas);
        });
    }

    doCountWorkflowsAction(): number {
      let count = 0;
      try {
        const workflows = JSON.parse(localStorage.getItem(TB_WORKFLOWS_NAME));
        count = workflows.length;
      } catch(e) { console.log(e) }
      return count;
    }

    doInsertWorkflowsAction(_workflows: DbmWorkflow[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doGetWorkflowsAction().then((_datasTable: DbmWorkflow[]) => {
                var newDatas = _datasTable.concat(_workflows);
                localStorage.setItem(TB_WORKFLOWS_NAME, JSON.stringify(newDatas));
                resolve();
            }, err => reject(err));
        });
    }

    doGetWorkflowAction(_numWorkflow: string): Promise<DbmWorkflow> {
        return new Promise((resolve, reject) => {
            this.doGetWorkflowsAction().then((_datasTable: DbmWorkflow[]) => {
                const workflow = _datasTable.find(w => w.NUM_WORKFLOW == _numWorkflow);
                resolve(workflow);
            }, err => reject(err));
        });
    }
}
