<div style="padding: 24px !important">
    <h2 mat-dialog-title>Saisir un code Article</h2>
    <mat-dialog-content class="mat-typography">
        <div class="form-group">
            <kendo-dropdownlist [formControl]="control" [filterable]="true" [data]="articles" [textField]="'C0002'" [valueField]="'C0001'" [valuePrimitive]="true" (filterChange)="handleFilter($event)">
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    {{dataItem['C0001'] + ' - ' +dataItem['C0002']}}
                </ng-template>
            </kendo-dropdownlist>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="onSubmit()" cdkFocusInitial class="btn btn-outline-primary" [disabled]="!control.valid">Valider</button>
        <button mat-button mat-dialog-close class="btn btn-outline-default">Annuler</button>
    </mat-dialog-actions>
</div>
