import { Injectable } from "@angular/core";
import { StartupInfos_ProcessingWorkflows } from "../../views/app/app.models";
import { WorkflowsOfflineService } from "../services/workflows-offline.service";

@Injectable()
export class WorkflowsOfflineApi {
    constructor(
        private _workflowOfflineService: WorkflowsOfflineService
    ) {}

    doGetStartupInfosAction_Processing(): Promise<StartupInfos_ProcessingWorkflows> {
        return this._workflowOfflineService.doGetStartupInfosAction_Processing();
    }
}