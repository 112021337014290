import { Injectable } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { ResponsesOfflineService } from "../services/responses-offline.service";
import { SynchronisationOfflineService } from "../services/synchronisation-offline.service";
import {WorkflowsOfflineService} from "../services/workflows-offline.service";

@Injectable()
export class SynchronisationOfflineApi {

    $appSynchrozing = false;
    $appSynchronized = new Subject<any>();
    notificationConfig = {
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    };

    constructor(
        private _synchronisationOfflineService: SynchronisationOfflineService,
        private _workflowsOfflineService: WorkflowsOfflineService,
        private _responsesOfflineService: ResponsesOfflineService,
        private toastr: ToastrService,
    ) {}

    doSynchronizeAppAction_Synchronisation(): Promise<void> {
        if (this.$appSynchrozing) { return; }
        this.$appSynchrozing = true;
        const activeToast = this.toastr.info("Synchronisation des données en cours...", "Abworkflow", { disableTimeOut: true });
        return new Promise((resolve, reject) => {
            this._synchronisationOfflineService.doSynchronizeAppAction()
            .then(() => {
                this.$appSynchronized.next();
                activeToast.toastRef.close();
                this.toastr.info("Synchronisation éffectuée !", "Abworkflow", { disableTimeOut: true });
                this.$appSynchrozing = false;
                resolve();
            }).catch(err => {
                console.log(err);
                activeToast.toastRef.close();
                this.toastr.error("Erreurs survenues lors de la synchronisation des données.", "Abworkflow", { disableTimeOut: true });
                this.$appSynchrozing = false;
                reject(err);
            });
        });
    }

    hasLocalAppDatasSynced_Synchronisation(): boolean {
      return this._workflowsOfflineService.doCountWorkflowsAction_Processing() > 0;
    }

    hasDatasToSynchronize_Synchronisation(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const count_responses = await this._responsesOfflineService.doCountResponsesAction_ResponseWorkflows();
            resolve(count_responses > 0);
        });
    }

    doClearOfflineDatabaseAction(): Promise<void> {
      return new Promise((resolve, reject) => {
        this._synchronisationOfflineService.doClearOfflineDatabaseAction().then(() => {
          resolve();
        }, err => {
          console.log(err);
          reject(err)
        });
      });
    }
}
