import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";

@Component({
  selector: 'cfc-famoco-laser',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcFamocoLaserComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CfcFamocoLaserComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-famoco-laser.component.html'
})
export class CfcFamocoLaserComponent implements ControlValueAccessor {

  @Input() required = false;
  @Output() changed = new EventEmitter();
  scannedCode: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private hardwareBridge: HardwareBridgeService,
    private inj: Injector
  ) {
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.scannedCode = value;
      //this.changed.emit(value);
      //this.propagateChange(this.scannedCode);
      this.changeDetectorRef.detectChanges();
    }
  }

  validate({ value }: FormControl) {
    const isNotValid = this.required && value == null;
    return isNotValid && {
      invalid: true
    }
  }

  scanCode() {
    this.hardwareBridge.openFamocoLaser()
    .then((result) => {
      this.scannedCode = result;
      this.propagateChange(this.scannedCode);
      this.changed.emit(this.scannedCode);
    });
  }

}
