import {Injectable} from "@angular/core";
import {HbridgeInterface} from "./hbridge.interface";
import {Subject} from "rxjs";
import package_json from '../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class WebbrowserBridgeService implements HbridgeInterface {
  locationWatcherID: number;
  onLocationChanged = new Subject<{ latitude: number, longitude: number, accuracy: number }>();
  onDocumentScanned = new Subject<string>();

  //#region Geolocation
  getGeolocationPoint(): Promise<{ longitude: number, latitude: number, accuracy: number }> {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position)=>{
            const longitude = position.coords.longitude;
            const latitude = position.coords.latitude;
            const accuracy = position.coords.accuracy;
            resolve({ longitude: longitude, latitude: latitude, accuracy: accuracy });
          }, (err) => {
            console.log(err);
            reject(err);
          }, { enableHighAccuracy: true });
        } else {
          reject('La géolocalisation n\'est pas disponible.');
        }
    });
  }

  watchGeolocationTracking(): Promise<void> {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.clearWatch(this.locationWatcherID);
          this.locationWatcherID = navigator.geolocation.watchPosition((position) => {
            this.onLocationChanged.next({ latitude: position.coords.latitude, longitude: position.coords.longitude, accuracy: position.coords.accuracy });
          }, (err) => {
            console.log(err);
            reject(err);
          }, { enableHighAccuracy: true, timeout: 60000 });
          resolve(null);
        } else {
          reject('La géolocalisation n\'est pas disponible.');
        }
    });
  }

  stopGeolocationTracking() {
    navigator.geolocation.clearWatch(this.locationWatcherID);
  }
  //#endregion

  openCodeScanner(): Promise<string> {
    return new Promise<string>((resolve, reject) => reject('Le lecteur de code-barres n\'est pas disponible.'));
  }

  openDocumentScanner(): Promise<string> {
    return new Promise<string>((resolve, reject) => reject('Le scanneur de documents n\'est pas disponible.'));
  }

  openCamera(): Promise<string> {
    return new Promise<string>((resolve, reject) => reject('La caméra n\'est pas disponible.'));
  }

  showNotification(title: string, text: string) {
    if (window.Notification) {
      Notification.requestPermission(function (permission) {
        const notification = new Notification(title, {
          body: text,
          icon: 'https://abworkflow.com/ui/abw-classic.png',
          dir: 'auto'
        });
      });
    } else {
      throw('Les notifications push ne sont pas supportées.')
    }
  }

  openFamocoLaser(): Promise<any> {
    return new Promise((resolve, reject) => reject('Not Implemented yet'));
  }

  openFingerprintScanner(): Promise<any> {
    return new Promise((resolve, reject) => reject('Not Implemented yet'));
  }

  getAppVersion(): string {
      const appVersion = package_json.version
      return appVersion;
  }

  downloadUpdateApp(url: string, version: string) {
    throw('Les mises à jour ne sont pas supportées.')
  }

  cancelUpdateApp(): Promise<any> {
    return new Promise((resolve, reject) => reject('Les mises à jour ne sont pas supportées.'));
  }
}
