import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControlComponent, DynamicFormControlCustomEvent, DynamicFormLayout, DynamicFormLayoutService, DynamicFormValidationService } from '@ng-dynamic-forms/core';
import { CfcInputGridComponent } from './cfc-input-grid.component';
import { CfcInputGridModel } from './cfc-input-grid.model'

@Component({
    selector: 'dynamic-cfc-input-grid',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dynamic-cfc-input-grid.component.html',
})
export class DynamicCfcInputGridComponent extends DynamicFormControlComponent {

    @Input() bindId: boolean = true;
    @Input() group: FormGroup;
    //@Input() layout: DynamicFormLayout;
    @Input() model: CfcInputGridModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild(CfcInputGridComponent) myCustomFormControlComponent: CfcInputGridComponent;

    constructor(
        protected layoutService: DynamicFormLayoutService, 
        protected validationService: DynamicFormValidationService
    ) {
        super(layoutService, validationService);
    }
}
