import { Injectable } from "@angular/core";
import { DbmTeam } from "../../views/app/app.models";
import { UnitOfWorkOfflineDao } from "../daos/unitofwork-offline.dao";

@Injectable()
export class TeamsOfflineService {
    constructor(private unitOfWork: UnitOfWorkOfflineDao) { }

    doGetTeamsAction_Teams(): Promise<DbmTeam[]> {
        return new Promise((resolve, reject) => {
            this.unitOfWork.teamsDao.doGetTeamsAction()
            .then((_teams: DbmTeam[]) => {
                resolve(_teams);
            }, err => {
                console.log(err);
                reject(err);
            });
        });
    }
}