import { Injectable } from "@angular/core";
import { DbmTeam } from "../../views/app/app.models";

const TB_TEAMS_NAME = "LOCAL_TB_TEAMS";

@Injectable()
export class TeamsOfflineDao {
    doSyncTeamsAction(_workflows: DbmTeam[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doClearTeamsAction();
            this.doInsertTeamsAction(_workflows).then(() => {
                resolve();
            }, err => reject(err));
        });
    }

    doClearTeamsAction() {
        localStorage.setItem(TB_TEAMS_NAME, "[]");
    }

    doGetTeamsAction(): Promise<DbmTeam[]> {
        return new Promise((resolve, reject) => {
            var datas: DbmTeam[] = [];
            try {
                datas = JSON.parse(localStorage.getItem(TB_TEAMS_NAME));
            } catch(e) { console.log(e) }
            resolve(datas);
        });
    }

    doInsertTeamsAction(_workflows: DbmTeam[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doGetTeamsAction().then((_datasTable: DbmTeam[]) => {
                var newDatas = _datasTable.concat(_workflows);
                localStorage.setItem(TB_TEAMS_NAME, JSON.stringify(newDatas));
                resolve();
            }, err => reject(err));
        });
    }

    doGetTeamAction(_idTeam: number): Promise<DbmTeam> {
        return new Promise((resolve, reject) => {
            this.doGetTeamsAction().then((_datasTable: DbmTeam[]) => {
                const workflow = _datasTable.find(w => w.ID == _idTeam);
                resolve(workflow);
            }, err => reject(err));
        });
    }
}
