import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormArray, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DynamicFormArrayModel, DynamicFormControlModel, DynamicFormService, DynamicInputModel } from '@ng-dynamic-forms/core';
import { Enum_WorkflowUIComponent } from 'src/app/views/app/app.models';
import { ConnectorWorkflowExecutionDsBuilderService } from '../../workflow-execution-ds-builder.service';
import { CfcBarcodeScannerModel } from '../cfc-barcode-scanner/cfc-barcode-scanner.model';
import { CfcDocumentScannerModel } from '../cfc-document-scanner/cfc-document-scanner.model';
import { CfcFamocoLaserModel } from '../cfc-famoco-laser/cfc-famoco-laser.model';
import { CfcInputDatePickerModel } from '../cfc-input-datepicker/cfc-input-datepicker.model';
import { CfcInputDropdownModel } from '../cfc-input-dropdown/cfc-input-dropdown.model';
import { CfcInputFileModel } from '../cfc-input-file/cfc-input-file.model';
import { CfcInputNumericTextboxModel } from '../cfc-input-numerictextbox/cfc-input-numerictextbox.model';
import { CfcInputTextareaModel } from '../cfc-input-textarea/cfc-input-textarea.model';
import { CfcInputTextboxModel } from '../cfc-input-textbox/cfc-input-textbox.model';
import { CfcSignaturePadModel } from '../cfc-signature-pad/cfc-signature-pad.model';
import { CfcInputGridModel } from './cfc-input-grid.model';

@Component({
  selector: 'cfc-input-grid',
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcInputGridComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-input-grid.component.html',
  styleUrls: ['./cfc-input-grid.component.scss'],
  styles: [`table { width: 100%; }`]
})
export class CfcInputGridComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() value: any;
  @Input() valueGrid: any;
  @Input() model: CfcInputGridModel;
  initialRowsCount: number = 1;
  totalRows: number = 1;
  gridColumns = [];
  hasDynamicRows = true;
  label: string;
  inputGridFormsGroup: FormGroup[] = [];
  inputGridFormsModel: DynamicFormControlModel[][] = [];
  loading = false;
  datasourcesColumn: any = {};
  @Input() gridValues: any = undefined;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private connectorDS : ConnectorWorkflowExecutionDsBuilderService,
    private formService: DynamicFormService,
  ) { }

  ngOnInit() {
    try { 
      this.gridValues = JSON.parse(this.valueGrid); 
      this.totalRows = this.gridValues.length;
    } catch(e) {} 

    this.label = this.model.additional.component.UIC_PARAMS['LIBELLE'];
    //this.hasDynamicRows = this.model.additional.component.UIC_PARAMS['INPUT_GRID_ADD_ROW_ENABLED'];
    this.gridColumns = this.model.additional.component.UIC_PARAMS['INPUT_GRID_COLUMNS'];
    this.loading = true;
    this._buildFormGroups()
    .then((_formGroups) => {
      this.inputGridFormsModel = _formGroups;
      this.inputGridFormsModel.forEach((_gridFormsModel) => {
        this.inputGridFormsGroup.push(this.formService.createFormGroup(_gridFormsModel));
        this.inputGridFormsGroup.forEach((_formGroup) => _formGroup.valueChanges.subscribe((e) => { this.onChange(); }));
      });
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    }, err => {
      this.loading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.gridValues && !!changes.gridValues.currentValue) {
      try { 
        this.gridValues = JSON.parse(changes.gridValues.currentValue); 
        this.totalRows = this.gridValues.length;
      } catch(e) {} 
      for (var i = 0; i < this.totalRows; i++) {
        for (var j = 0; j < this.gridColumns.length; j++) {
          const groupFormId = this.gridColumns[j]['IDENTIFIANT'];
          this.inputGridFormsGroup[i].controls[groupFormId].patchValue(this.gridValues[i][groupFormId]);
        }
      }
    }
  }

  addRow() {
    if (!this.hasDynamicRows) { return; }
    this.loading = true;
    this._doBuildFormRow(this.totalRows)
    .then((_formRow) => {
      this.inputGridFormsModel.push(_formRow);
      this.inputGridFormsGroup.push(this.formService.createFormGroup(_formRow));
      this.inputGridFormsGroup.forEach((_formGroup) => _formGroup.valueChanges.subscribe((e) => { this.onChange(); }));
      this.totalRows += 1;
      this.changeDetectorRef.detectChanges();
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  removeRow(rowIndex: number) {
    if (!this.hasDynamicRows) { return; }
    this.inputGridFormsModel.splice(rowIndex, 1);
    this.inputGridFormsGroup.splice(rowIndex, 1);
    this.totalRows -= 1;

    this.changeDetectorRef.detectChanges();
    this.onChange();
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      this.propagateChange(value);
      this.changeDetectorRef.detectChanges();
    }
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => { return 0; }

  private _evaluateComputedControls() {
    for (var j = 0; j < this.gridColumns.length; j++) {
      const typeDefaultValue = this.gridColumns[j]['TYPE_DEFAULT_VALUE'];
      if (typeDefaultValue == 1) {
        const regex = /@([\w]+)/g;
        const defaultValue = this.gridColumns[j]['DEFAULT_VALUE_COMPUTED'];
        const computedControls = defaultValue.match(regex);
        for (var i = 0; i < this.totalRows; i++) {
          var expression = defaultValue;
          const groupFormId = this.gridColumns[j]['IDENTIFIANT'];
          computedControls.map(matched => matched.substr(1)).forEach((field) => {
            let fieldValue = this.inputGridFormsGroup[i].controls[field].value;
            if (isNaN(fieldValue)) {
              fieldValue = 0;
            }
            expression = expression.replace(new RegExp('@' + field, 'g'), fieldValue);
          });
          var expression_value = 0;
          try {
            expression_value = eval(expression);
          } catch(e) {}
          this.inputGridFormsGroup[i].controls[groupFormId].setValue(expression_value, { emitEvent: false });
        }
      } else if (typeDefaultValue == 6) {
        const groupFormId = this.gridColumns[j]['IDENTIFIANT'];
        const linkedDropdown = this.gridColumns[j]['INPUT_DROPDOWN_LINKED_DROPDOWN'];
        for (var i = 0; i < this.totalRows; i++) {
          const linkedDropdownModel: any = this.inputGridFormsModel[i].find(model => model.id == linkedDropdown);
          const dropdownValue = this.inputGridFormsGroup[i].controls[linkedDropdown].value;
          if (!!dropdownValue && !!linkedDropdownModel.rawOptions) {
            const selectedOptionItem = linkedDropdownModel.rawOptions.find(o => o.value == dropdownValue);
            if (!!selectedOptionItem) {
              const linkedValueExpression = this.gridColumns[j]['LINKED_VALUE_EXP'];
              console.log(linkedValueExpression);
              var _result = linkedValueExpression.replace(/fields./gi, '');
              var matchesResult = _result.split('[').filter(function(v){ return v.indexOf(']') > -1}).map( function(value) { return value.split(']')[0]});
              if (matchesResult.length > 0 && !!selectedOptionItem) {
                const linkedValue = selectedOptionItem[matchesResult[0]];
                this.inputGridFormsGroup[i].controls[groupFormId].setValue(linkedValue, { emitEvent: false });
              }
            }
          }
        }
        /*const dropdownValue = _step.stepFormGroup.controls[linkedDropdown].value;
        if (!!dropdownValue && !!linkedDropdownModel.rawOptions) {
          const selectedOptionItem = linkedDropdownModel.rawOptions.find(o => o.value == dropdownValue);
          if (!!selectedOptionItem) {
            const linkedValueExpression = this.gridColumns[j].UIC_PARAMS['LINKED_VALUE_EXP'];
            var _result = linkedValueExpression.replace(/fields./gi, '');
            var matchesResult = _result.split('[').filter(function(v){ return v.indexOf(']') > -1}).map( function(value) { return value.split(']')[0]});
            if (matchesResult.length > 0 && !!selectedOptionItem) {
              const linkedValue = selectedOptionItem[matchesResult[0]];
              _step.stepFormGroup.controls[controlKey].setValue(linkedValue, { emitEvent: false });
            }
          }
        }*/
      }
    }
  }

  onChange() {
    if (!this.readOnly) {
      this._evaluateComputedControls();
    }
    var gridFormDatas = [];
    for (var i = 0; i < this.totalRows; i++) {
      var rowFormDatas = {};
      for (var j = 0; j < this.gridColumns.length; j++) {
        const groupFormId = this.gridColumns[j]['IDENTIFIANT'];
        const formControlId = groupFormId/* + '_' + (i + 1)*/;
        rowFormDatas[groupFormId] = this.inputGridFormsGroup[i].controls[formControlId].value;
      }
      gridFormDatas.push(rowFormDatas);
    }
    this.writeValue(gridFormDatas);
  }

  private _buildFormGroups(): Promise<DynamicFormControlModel[][]> {
    return new Promise((resolve, reject) => {
      var rowFormModel: DynamicFormControlModel[][] = [];
      var gridForm: DynamicFormArrayModel[] = [];
      var groupPromise = Promise.resolve<DynamicFormControlModel[]>(null);
      var rowIndex = 0;
      for (var i = 0; i < this.totalRows; i++) {
        groupPromise = groupPromise.then((_groupForm) => new Promise((groupResolve, groupReject) => {
          if (_groupForm != null) {
            rowFormModel.push(_groupForm);
            rowIndex += 1;
          }

          this._doBuildFormRow(rowIndex)
          .then((_formRow) => {
            //const formArray = new DynamicFormArrayModel({  id: "ROW_" + gridForm.length, initialCount: this.gridColumns.length, groupFactory: () => _formRow  });
            groupResolve(_formRow);
          });
        }));
      }
      groupPromise.then((_groupForm) => {
        if (_groupForm != null) {
          rowFormModel.push(_groupForm);
        }
        resolve(rowFormModel);
      })
    });
  }

  private _doBuildFormRow(rowIndex: number, gridValues?: any): Promise<DynamicFormControlModel[]> {
    return new Promise<DynamicFormControlModel[]>((resolve, reject) => {
      const formRow: DynamicFormControlModel[] = [];
      var colPromise = Promise.resolve<DynamicFormControlModel>(null);
      this.gridColumns.forEach((gridColumn, gridColumnIndex) => {
        colPromise = colPromise.then((_formControl) => new Promise<DynamicFormControlModel>((colResolve, colReject) => {
          if (_formControl != null) {
            formRow.push(_formControl);
          }
          this._doBuildFormControl(rowIndex, gridColumnIndex)
          .then((formControl) => {
            if (formControl != null) {
              colResolve(formControl);
            }
          });
        }));
      });
      colPromise.then((_formControl) => {
        if (_formControl != null) {
          formRow.push(_formControl);
        }
        resolve(formRow);
      });
    });
  }

  private _doBuildFormControl(rowIndex: number, gridColumnIndex: number): Promise<DynamicFormControlModel> {
    return new Promise((resolve, reject) => {
      var formControl: any;
      const formControlId = this.gridColumns[gridColumnIndex]['IDENTIFIANT']/* + '_' + (rowIndex + 1)*/;
      const formControlLabel = this.gridColumns[gridColumnIndex]['LIBELLE']/* + ' ' + (rowIndex + 1)*/;
      const formControlReadonly = !!this.gridColumns[gridColumnIndex]['READONLY'] ? this.gridColumns[gridColumnIndex]['READONLY'] : false;
      let formValue = this.gridColumns[gridColumnIndex]['DEFAULT_VALUE'];
      if (!!this.gridValues && !!this.gridValues[rowIndex] && !!this.gridValues[rowIndex][formControlId]) {
        formValue = this.gridValues[rowIndex][formControlId];
      }
      switch(parseInt(this.gridColumns[gridColumnIndex]['TYPE_CELL'])) {
        case Enum_WorkflowUIComponent.TEXTBOX: // Textbox
          formControl = new CfcInputTextboxModel({
            id: formControlId,
            label: null, 
            placeholder : formControlLabel,
            name : formControlId,
            required: true,
            value: formValue,
            readOnly: this.readOnly || formControlReadonly
          }); 
          resolve(formControl);
          break;

        case Enum_WorkflowUIComponent.NUMERIC_TEXTBOX: // Numeric Textbox
          formControl = new CfcInputNumericTextboxModel({
            id: formControlId,
            label: null,
            placeholder : formControlLabel,
            name : formControlId,
            required: true,
            value: formValue,
            readOnly: this.readOnly || formControlReadonly
          }); 
          resolve(formControl);
          break;

        case Enum_WorkflowUIComponent.TEXTAREA: // Textarea
          formControl = new CfcInputTextareaModel({
            id: formControlId,
            label: null,
            placeholder : formControlLabel,
            name : formControlId,
            required: true,
            value: formValue,
            readOnly: this.readOnly || formControlReadonly
          }); 
          resolve(formControl);
          break;

        case Enum_WorkflowUIComponent.DATEPICKER: // DatePicker
          formControl = new CfcInputDatePickerModel({
            id: formControlId,
            label: null,
            placeholder : formControlLabel,
            name : formControlId,
            required: true,
            value: formValue,
            readOnly: this.readOnly || formControlReadonly
          }); 
          resolve(formControl);
          break;

        case Enum_WorkflowUIComponent.DROPDOWN: // Liste déroulante
          formControl = new CfcInputDropdownModel({
            id: formControlId,
            label: null,
            placeholder : formControlLabel,
            name : formControlId,
            required: true,
            value: formValue,
            readOnly: this.readOnly || formControlReadonly
          }); 
          if (this.datasourcesColumn[gridColumnIndex] === undefined) {
            this.connectorDS.doBuildDatasourceOptionsAction_Execution(this.gridColumns[gridColumnIndex]['DATASOURCE_ID'], this.gridColumns[gridColumnIndex]['EXP_VALEUR'],this.gridColumns[gridColumnIndex]['EXP_AFFICHAGE']).then(a => {
              this.datasourcesColumn[gridColumnIndex] = a;
              formControl.rawOptions = a;
              formControl.options = a;
              resolve(formControl);
            }, err => {
              console.log(err);
              reject(err);
            });
          } else {
            formControl.rawOptions = this.datasourcesColumn[gridColumnIndex];
            formControl.options = this.datasourcesColumn[gridColumnIndex];
            resolve(formControl);
          }
          break;

        case Enum_WorkflowUIComponent.SCAN_DOCUMENT: // Scan Document
          formControl = new CfcDocumentScannerModel({
            id: formControlId,
            label: null,
            name : formControlId,
            required: true,
            value: formValue
          }); 
          resolve(formControl);
          break;

        case Enum_WorkflowUIComponent.SCAN_CODE_BARRE: // Lecteur Code Barre
          formControl = new CfcBarcodeScannerModel({
            id: formControlId,
            label: null,
            name : formControlId,
            required: true,
            value: formValue
          }); 
          resolve(formControl);
          break;

        case Enum_WorkflowUIComponent.ELECTRONIC_SIGNATURE: // Signature Electronique
          formControl = new CfcSignaturePadModel({
            id: formControlId,
            label: null,
            name : formControlId,
            required: true,
            value: formValue
          }); 
          resolve(formControl);
          break;

        case Enum_WorkflowUIComponent.UPLOAD_FILE: // Upload Fichier
          formControl = new CfcInputFileModel({
            id: formControlId,
            label: null,
            placeholder : formControlLabel,
            name : formControlId,
            required: true,
            value: formValue
          }); 
          resolve(formControl);
          break;

        case Enum_WorkflowUIComponent.FAMOCO_SCAN_LASER: // Famoco Scan Laser
          formControl = new CfcFamocoLaserModel({
            id: formControlId,
            label: null,
            name : formControlId,
            required: true,
            value: formValue
          }); 
          resolve(formControl);
          break;

        default:
          resolve(null);
          break;
      }
    });
  }

}
