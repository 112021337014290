import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControlComponent, DynamicFormControlCustomEvent, DynamicFormLayout, DynamicFormLayoutService, DynamicFormValidationService } from '@ng-dynamic-forms/core';
import { DbmWorkflowStepUiComponent } from 'src/app/views/app/app.models';
import { CfcMapPickerComponent } from './cfc-map-picker.component';
import { CfcMapPickerModel } from './cfc-map-picker.model'

@Component({
    selector: 'dynamic-cfc-map-picker',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dynamic-cfc-map-picker.component.html',
})
export class DynamicCfcMapPickerComponent extends DynamicFormControlComponent {

    @Input() bindId: boolean = true;
    @Input() group: FormGroup;
    //@Input() layout: DynamicFormLayout;
    @Input() model: CfcMapPickerModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild(CfcMapPickerComponent) myCustomFormControlComponent: CfcMapPickerComponent;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService) {
        super(layoutService, validationService);
    }
}
