<div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center" mat-dialog-title>
    <h1 style="font-size: 18px; display: inline-block; margin: 0px;">Emplacements Article - {{ mDocLigne.DL_Design }}</h1>
    <a href="javascript:;" (click)="dialogRef.close()" style="color: white;"><span class="glyph-icon simple-icon-close"></span></a>
</div>
<div mat-dialog-content>
    <div>
        <table style='border-collapse:collapse; width: 100%'>
            <thead>
                <tr>
                    <th style='border:1px solid black; font-size: 16px;font-family: Calibri; text-align:center'>Dépot</th>
                    <th style='border:1px solid black; width: 25%; font-size: 16px;font-family: Calibri; text-align:center'>Emplacement</th>
                    <th style='border:1px solid black; width: 25%; font-size: 16px;font-family: Calibri; text-align:center'>Quantité</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let emplacement of mDocLigne.LigEmplacements; let rowIndex = index">
                    <td style='border:1px solid black; font-size: 16px;font-family: Calibri; text-align:center'>{{ emplacement.DE_Intitule }}</td>
                    <td style='border:1px solid black; font-size: 16px;font-family: Calibri; text-align:center'>{{ emplacement.DP_Code }}</td>
                    <td style='border:1px solid black; font-size: 16px;font-family: Calibri; text-align:center'>{{ emplacement.DL_Qte }}</td>
                </tr>
                <tr *ngIf="mDocLigne.LigEmplacements.length == 0">
                    <td style='border:1px solid black; font-size: 16px;font-family: Calibri; text-align:center' colspan="3">Aucun emplacement défini</td>
                </tr>
            </tbody>
        </table>
    </div>
</div> 