import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AuthUtils } from 'src/app/core/auth/auth.utils';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import * as Sentry from "@sentry/browser";
import { environment } from 'src/environments/environment';
import { HardwareBridgeService } from 'src/app/shared/bridge/hardware-bridge.service';

declare var $: any;

export class ApiAAException {
  ErrorCode: number;
  ErrorDescription: string;
  HttpStatus: number;
  StackTrace: string;
  reasonPhrase: string;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    unhandledUrls: string[] = [];
    /**
     * Constructor
     *
     * @param {AuthService} _authService
     */
    constructor(
        private _authService: AuthService,
        private apiService: ApiService,
        private _router : Router,
        private toastr: ToastrService,
        private _hbService: HardwareBridgeService
    )
    {
      this.unhandledUrls = [
        this.apiService.endPoints.doGetResponseWorkflowStepImage,
        this.apiService.endPoints.doOsrmMapMatchingAction_Osrm,
        this.apiService.endPoints.doValidateResponseWorkflowAction_ProcessValidation,
        this.apiService.endPoints.doRejectResponseWorkflowAction_ProcessValidation,
      ];
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Clone the request object
        let newReq = req.clone({ headers: req.headers.set('ABW-VERSION', this._hbService.getAppVersion()) });

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
        {
            newReq = newReq.clone({
                headers: newReq.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
              if (!this.isUnhandledRequest(error.url)) {
                if (error.status === 401) {
                  if (error.url == '/user/sign-in') {
                    this.toastr.error("Login ou Mot de passe incorrect.", undefined, { disableTimeOut: true });
                  } else {

                    this.toastr.error("Vous avez été déconnecté.", undefined, { disableTimeOut: true });
                    this._authService.signOut().subscribe(() => {
                      this._router.navigateByUrl('/user/sign-in');
                    });
                  }
                } else {
                  let errMessage = "Une erreur est survenue lors de la requete au serveur.";
                  if (error.error !== null && error.error.message !== undefined) { errMessage = error.error.message; }
                  this.toastr.error(errMessage, undefined, { disableTimeOut: true });
                  this._CaptureSentryError(errMessage);
                }
                $.unblockUI();
                console.log(error);
              }
              return throwError(error);
            })
        );
    }

    isUnhandledRequest(currentUrl) {
      var isUnhandledRequest = false;
      this.unhandledUrls.forEach(url => {
        isUnhandledRequest = isUnhandledRequest || currentUrl.includes(url);
      });
      return isUnhandledRequest;
    }

    private _CaptureSentryError(errMessage) {
      try {
        const companyName = localStorage.getItem(environment.LS_ABWORKFLOW_CURRENT_COMPANY) ?? '';
        Sentry.captureMessage(`${companyName} | ${errMessage}`);
      } catch(exception) {}
    }
}
