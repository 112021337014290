export class AppSettings {

    // #region APP PARAMS

    public static DEV_SERVER_DEFAULT_ABW = "https://localhost:44340";
    public static SERVER_APP_NAME_ABW = "AbWorkflowCoreApi";

    // #endregion

    // #region LOCAL STORAGE CONSTANTS

    /**
     * @defines : Assistance Absoftwork API Endpoint
     * @returns : Ex http://localhost:12345/
     */
    public static LS_API_ENDPOINT_ABW ='LS_API_ENDPOINT_ABW';

    // #endregion

}
