import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
} from "@ng-dynamic-forms/core";

export enum SOUDOTEC_MODE_SCAN_EMPLACEMENT {
  FAMOCO_LASER = 0,
  APP_PHOTO_QR_CODE_READER = 1
}

export interface CfcSoudotecScanEmplacementModelConfig extends DynamicFileUploadModelConfig {
  emplacements?: any[];
  noDepot?: any;
  modeScan?: SOUDOTEC_MODE_SCAN_EMPLACEMENT;
}

  export class CfcSoudotecScanEmplacementModel extends DynamicFileUploadModel {
    @serializable() readonly type: string = 'CfcSoudotecScanEmplacement';
    emplacements: any[] = [];
    noDepot: any;
    modeScan = SOUDOTEC_MODE_SCAN_EMPLACEMENT.FAMOCO_LASER;

    constructor(config: CfcSoudotecScanEmplacementModelConfig, layout?: DynamicFormControlLayout) {
      super(config, layout);
      if (!!config.emplacements) { this.emplacements = config.emplacements; }
      if (!!config.noDepot) { this.noDepot = config.noDepot; }
      if (!!config.modeScan) { this.modeScan = config.modeScan; }
    }
  }
