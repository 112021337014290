import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import * as moment from "moment";
import { DbmResponseWorkflow } from "../../views/app/app.models";

const TB_RESPONSES_NAME = "LOCAL_TB_RESPONSES";

@Injectable()
export class ResponsesOfflineDao {
    doDeleteSyncedResponsesAction(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            let responses = await this.doGetResponsesAction();
            responses.forEach(async (response) => {
                if (response.SYNCED === true) {
                    await this.doDeleteResponsesAction(response.GUID);
                }
            });
            resolve();
        });
    }

    doSyncResponsesAction(_responses: DbmResponseWorkflow[]): Promise<void> {
        return new Promise((resolve, reject) => {
          this.doClearResponsesAction();
          this.doInsertResponsesAction(_responses).then(() => {
            resolve();
          }, err => reject(err));
        });
    }

    doDeleteResponsesAction(_guid: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doGetResponsesAction()
            .then((_responses: DbmResponseWorkflow[]) => {
                const responseIndex = _responses.findIndex(r => r.GUID == _guid);
                if (responseIndex != -1) {
                    _responses.splice(responseIndex, 1);
                }
                localStorage.setItem(TB_RESPONSES_NAME, JSON.stringify(_responses));
                resolve();
            }, err => reject(err));
        });
    }

    doClearResponsesAction() {
        localStorage.setItem(TB_RESPONSES_NAME, "[]");
    }

    doGetResponsesAction(): Promise<DbmResponseWorkflow[]> {
        return new Promise((resolve, reject) => {
            var datas: DbmResponseWorkflow[] = [];
            try {
                datas = JSON.parse(localStorage.getItem(TB_RESPONSES_NAME));
                if (datas == null) { datas = []; }
            } catch(e) { console.log(e) }
            resolve(datas);
        });
    }

    doInsertResponsesAction(_responses: DbmResponseWorkflow[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doGetResponsesAction().then((_datasTable: DbmResponseWorkflow[]) => {
                var newDatas = _datasTable.concat(_responses);
                localStorage.setItem(TB_RESPONSES_NAME, JSON.stringify(newDatas));
                resolve();
            }, err => reject(err));
        });
    }

    doSaveResponsesAction(_response: DbmResponseWorkflow): Promise<DbmResponseWorkflow> {
        return new Promise((resolve, reject) => {
            this.doGetResponsesAction().then((_datasTable: DbmResponseWorkflow[]) => {
                var itemIndex = _datasTable.findIndex(i => i.ID != _response.ID || i.GUID == _response.GUID);
                var newDatas = _datasTable;
                if (itemIndex === -1) {
                    newDatas.push(_response);
                    itemIndex = newDatas.length - 1;
                    newDatas[itemIndex].DATE_CREATION = moment().format();
                    newDatas[itemIndex].GUID = Guid.create().toString();
                } else {
                    newDatas[itemIndex] = _response;
                    newDatas[itemIndex].DATE_MODIFICATION = moment().format();
                }
                localStorage.setItem(TB_RESPONSES_NAME, JSON.stringify(newDatas));
                resolve(newDatas[itemIndex]);
            }, err => reject(err));
        });
    }

    doGetResponseAction(_guid: string): Promise<DbmResponseWorkflow> {
        return new Promise((resolve, reject) => {
            this.doGetResponsesAction().then((_datasTable: DbmResponseWorkflow[]) => {
                const response = _datasTable.find(w => w.GUID == _guid);
                resolve(response);
            }, err => reject(err));
        });
    }
}
