import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../core/api.service';

@Injectable()
export class ConnectorSettingsAPIService {

    constructor(private api: ApiService, private httpClient: HttpClient) {
    }

    doPingAction(){
        return this.httpClient.get(this.api.endPoints.ping);
    }

}