import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {MatDialog} from '@angular/material/dialog';
import { CfcSoudotecInventoryInputDialogComponent } from './cfc-soudotec-inventory-input.dialog';
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";
import { Model_SoudotecInventoryItem } from './cfc-soudotec-inventory.model';

@Component({
  selector: 'cfc-soudotec-inventory',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcSoudotecInventoryComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-soudotec-inventory.component.html',
  styleUrls: ['./cfc-soudotec-inventory.component.scss']
})
export class CfcSoudotecInventoryComponent implements ControlValueAccessor {

  @Input() articles: any[] = [];
  itemsScanned: Model_SoudotecInventoryItem[] = [];
  showAlert = false;
  msgAlert = '';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private hardwareBridge: HardwareBridgeService,
    public dialog: MatDialog
  ) {
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (!!value) {
      this.itemsScanned = value;
      this.propagateChange(this.itemsScanned);
      this.changeDetectorRef.detectChanges();
    }
  }

  scanCode() {
    this.showAlert = false;
    this.msgAlert = '';
    this.hardwareBridge.openFamocoLaser()
    .then((_scannedCode: string) => {
      if (_scannedCode == null) { return; }
      if (this.itemsScanned == null) { this.itemsScanned = []; }
      const articleIndex = this.articles.findIndex(a => a['C0003'] === _scannedCode);
      if (articleIndex === -1) {
        this.msgAlert = `Aucun article ne correspond au code-barres scanné : ${_scannedCode}`;
        this.showAlert = true;
        this.changeDetectorRef.detectChanges();
        return;
      }
      this.addItemToInventory(this.articles[articleIndex]);
    });
  }

  enterCode() {
    const dialogRef = this.dialog.open(CfcSoudotecInventoryInputDialogComponent);
    dialogRef.afterClosed().subscribe((_refArticle: string) => {
      if (_refArticle === undefined) { return; }
      const articleIndex = this.articles.findIndex(a => a['C0001'] === _refArticle);
      if (articleIndex === -1) {
        return;
      }
      this.addItemToInventory(this.articles[articleIndex]);
    });
  }

  addItemToInventory(_article: Model_SoudotecInventoryItem) {
    var item = new Model_SoudotecInventoryItem();
    item.AR_Ref = _article['C0001'];
    item.AR_Design = _article['C0002'];
    item.BARCODE_SCANNED = _article['C0003'];
    item.QUANTITE_COMPTEE = 1;

    const itemIndex = this.itemsScanned.findIndex(i => i.AR_Ref == item.AR_Ref);
    if (itemIndex != -1) {
      this.itemsScanned[itemIndex].QUANTITE_COMPTEE += 1;
    } else {
      this.itemsScanned.push(item);
    }
    this.propagateChange(this.itemsScanned);
    this.changeDetectorRef.detectChanges();
  }

  onMinusQteButtonClicked(_itemIndex: number) {
    this.itemsScanned[_itemIndex].QUANTITE_COMPTEE -= 1;
    this.propagateChange(this.itemsScanned);
    this.changeDetectorRef.detectChanges();
  }

  onPlusQteButtonClicked(_itemIndex: number) {
    this.itemsScanned[_itemIndex].QUANTITE_COMPTEE += 1;
    this.propagateChange(this.itemsScanned);
    this.changeDetectorRef.detectChanges();
  }

  onRemoveButtonClicked(_itemIndex: number) {
    this.itemsScanned.splice(_itemIndex, 1);
  }

}
