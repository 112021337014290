import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
    DynamicSelectModelConfig,
    DynamicSelectModel,
    DynamicInputModel,
    DynamicInputModelConfig,
  } from "@ng-dynamic-forms/core";

  export interface CfcInputTextboxModelConfig extends DynamicInputModelConfig {
    IsTruncated?: boolean;
    TruncatedLength?: number;
    TruncatedStartIndex?: number;
  }

  export class CfcInputTextboxModel extends DynamicInputModel {
    @serializable() readonly type: string = 'CfcInputTextbox';
    IsTruncated?: boolean;
    TruncatedLength?: number;
    TruncatedStartIndex?: number;

    constructor(config: CfcInputTextboxModelConfig, layout?: DynamicFormControlLayout) {
      super(config, layout);
      if (!!config.IsTruncated) { this.IsTruncated = config.IsTruncated; }
      if (!!config.TruncatedLength) { this.TruncatedLength = config.TruncatedLength; }
      if (!!config.TruncatedStartIndex) { this.TruncatedStartIndex = config.TruncatedStartIndex; }
    }
  }
