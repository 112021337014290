import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
  } from "@ng-dynamic-forms/core";

  export interface CfcBarcodeScannerModelConfig extends DynamicFileUploadModelConfig { }

  export class CfcBarcodeScannerModel extends DynamicFileUploadModel {
    @serializable() readonly type: string = 'CfcBarcodeScanner';

    constructor(config: CfcBarcodeScannerModelConfig, layout?: DynamicFormControlLayout) {
      super(config, layout);
    }
  }
