import { Injectable } from "@angular/core";
import { DbmResponseWorkflow, DbmVariableEnvironmentUser, STATUS_WORKFLOW, TYPE_VARIABLE_ENVIRONMENT } from "../../views/app/app.models";
import { UnitOfWorkOfflineDao } from "../daos/unitofwork-offline.dao";

@Injectable()
export class EnvVarUserOfflineService {
    constructor(private unitOfWork: UnitOfWorkOfflineDao) { }

    EvaluateEnvironmentVariableUser(_response: DbmResponseWorkflow): Promise<DbmVariableEnvironmentUser[]> {
        return new Promise(async (resolve, reject) => {
            var resolveDatas = await this.unitOfWork.envVarUserDao.doGetEnvironmentVariablesUserAction();
            if (_response.STATUS_WORKFLOW != STATUS_WORKFLOW.ACHIEVED) { resolve(resolveDatas); }

            const workflowVariables = resolveDatas.filter(v => v.NUM_WORKFLOW == _response.NUM_WORKFLOW);
            workflowVariables.forEach(async (userVar, index, _varArr) => {
                if (userVar.TYPE_VARIABLE == TYPE_VARIABLE_ENVIRONMENT.CUMUL) {
                    const response_step = _response.STEPS.find(r => r.NUM_WORKFLOWSTEP == userVar.NUM_WORKFLOWSTEP);
                    if (response_step !== undefined && !!response_step.FORM_RESPONSES[userVar.UIC_IDENTIFIANT]) {
                        const computedFormControl = response_step.FORM_RESPONSES[userVar.UIC_IDENTIFIANT];
                        if (computedFormControl != null) {
                            try {
                                userVar.VALUE_VARIABLE = !!userVar.VALUE_VARIABLE ? userVar.VALUE_VARIABLE : "0";
                                userVar.VALUE_VARIABLE = (parseInt(userVar.VALUE_VARIABLE) + parseInt(computedFormControl.toString())).toString();
                            } catch(e) {}
                        }
                    }
                }
                if (userVar.TYPE_VARIABLE == TYPE_VARIABLE_ENVIRONMENT.MAPPING) {
                    const response_step = _response.STEPS.find(r => r.NUM_WORKFLOWSTEP == userVar.NUM_WORKFLOWSTEP);
                    if (response_step !== undefined && !!response_step.FORM_RESPONSES[userVar.UIC_IDENTIFIANT]) {
                        const computedFormControl = response_step.FORM_RESPONSES[userVar.UIC_IDENTIFIANT];
                        if (computedFormControl != null) {
                            try {
                                userVar.VALUE_VARIABLE = computedFormControl.toString();
                            } catch(e) {}
                        }
                    }
                }
                await this.unitOfWork.envVarUserDao.doSaveEnvironmentVariablesUserAction(userVar);
            });

            const userVarsForReinitialisation = resolveDatas.filter(v => v.HAS_REINIT_WORKFLOW == 1 && v.REINIT_WORKFLOW_NUM == _response.NUM_WORKFLOW);
            userVarsForReinitialisation.forEach(async (userVar, index, _varArr) => {
                if (userVar.TYPE_VARIABLE == TYPE_VARIABLE_ENVIRONMENT.CUMUL) {
                    userVar.VALUE_VARIABLE = "0";
                }
                if (userVar.TYPE_VARIABLE == TYPE_VARIABLE_ENVIRONMENT.MAPPING) {
                    userVar.VALUE_VARIABLE = "";
                }
                await this.unitOfWork.envVarUserDao.doSaveEnvironmentVariablesUserAction(userVar);
            });

            resolve(await this.unitOfWork.envVarUserDao.doGetEnvironmentVariablesUserAction());
        });
    }
}