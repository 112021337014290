import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ApiService } from "./api.service";

@Injectable()
export class ApiInitialisatedGuard implements CanActivate {
    constructor(private _router: Router, private httpClient: HttpClient, private apiService: ApiService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.httpClient.get(this.apiService.endPoints.ping)
            .subscribe((res : any) => {
                resolve(true);
            }, (err) => {
                this._router.navigateByUrl("/api-settings"); 
                reject(err);
            });
        }); 
    }
}