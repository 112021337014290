import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ApiService } from 'src/app/core/api.service';

export const FAMILLE_CDF_SANGRIA_ROUGE_ID = 9;
export const FAMILLE_CDF_VIN_ROUGE_ID = 10;
export const ORDER_FAMILLES_IDS = [1, 10, 2, 9, 3, 8, 4, 12, 5, 11, 6, 15, 7, 13, 14];
export enum Enum_TypeRemise {
    GRATUITE_ARTICLE = 1,
    REMISE_TRANSPORT_GRATUITE = 2,
    REMISE_FINANCIERE = 3,
    RISTOURNE_CLIENT = 4,
    REMISE_LIBRE = 5
}
export enum Enum_BCTypeLigne {
  LIGNE = 0,
  ENTETE = 1,
  TOTAUX = 2
}

export enum Enum_AliasID {
  GROUPE = 1,
  MIB = 2,
  MIBEM = 3,
  AGENA = 4
}

enum Command_View {
  IDLE = 0,
  COMMAND_LOADING = 1,
  COMMAND_LOADED = 2,
  COMMAND_LOADING_ERROR = 3
}

export class Model_MibemCommandGridInput {
  BON_COMMANDE_ID: number;
  BON_COMMANDE_NUM: string;

  VOLUME_TOTAL_BC: number = 0;
  VOLUME_REMISE_BC: number = 0;
  POIDS_BC_MIB: number = 0;
  POIDS_BC_MIBEM: number = 0;
  POIDS_BC_AGENA: number = 0;
  POIDS_REMISE_BC: number = 0;
  POIDS_BC: number = 0;

  VOLUME_TOTAL_GRID: number = 0;
  VOLUME_REMISE_GRID: number = 0;
  POIDS_BC_MIB_GRID: number = 0;
  POIDS_BC_MIBEM_GRID: number = 0;
  POIDS_BC_AGENA_GRID: number = 0;
  POIDS_BC_REMISE_GRID: number = 0;
  POIDS_BC_GRID: number = 0;

  GRID_DATAS: { QUANTITE_COMMANDE: number, GRATUITE_COMMANDE: number, QUANTITE_CHARGEMENT: number, QUANTITE_GRATUIT: number, MOTIF_MODIFICATION: number, COMMENTAIRE: string }[];
}

export const TYPES_MODIFICATIONS = [
  {
    ID: 1,
    TEXT: 'Modification de quantité',
    MOTIFS_MODIFICATION: [
      { ID: 1, TEXT: 'Ruptures lors du chargement' },
      { ID: 2, TEXT: 'Camion trop plein' },
      { ID: 3, TEXT: 'Casse pendant chargement' },
      { ID: 4, TEXT: 'Modif de site de chargement Bassam-Koumassi' },
      { ID: 5, TEXT: 'Autres' },
    ]
  },
  {
    ID: 2,
    TEXT: 'Modification % gratuité',
    MOTIFS_MODIFICATION: [
      { ID: 6, TEXT: 'Suite à modif qté articles chargés' },
      { ID: 7, TEXT: 'Suite à annulation de camion en propre' },
      { ID: 8, TEXT: 'Suite à la modification du BC en cours de chargement' },
    ]
  },
];

@Component({
  selector: 'cfc-mibem-command-gridinput',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcMibemCommandGridInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CfcMibemCommandGridInputComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-mibem-command-gridinput.component.html',
  styleUrls: ['./cfc-mibem-command-gridinput.component.scss']
})
export class CfcMibemCommandGridInputComponent implements OnInit, ControlValueAccessor {

  viewMode = Command_View.IDLE;
  loadingStatus = '';
  Command_View = Command_View;
  @Input() bonCommandeId: number;
  bonCommande: any = {};
  typesModification = TYPES_MODIFICATIONS;
  mibemCommandeInput = new Model_MibemCommandGridInput();

  VOLUME_TOTAL = 0;
  VOLUME_REMISE = 0;
  POIDS_BC_REMISE = 0;
  POIDS_BC = 0;

  VOLUME_TOTAL_GRID = 0;
  VOLUME_REMISE_GRID = 0;
  POIDS_BC_MIB_GRID = 0;
  POIDS_BC_MIBEM_GRID = 0;
  POIDS_BC_AGENA_GRID = 0;
  POIDS_BC_REMISE_GRID = 0;
  POIDS_BC_GRID = 0;

  gridForms: {[key: string]: {[key: string]: { QteChargeForm: FormControl, QteGratuitForm: FormControl, MotifModification: FormControl, Commentaire: FormControl }}} = {};

  onlyFilledRowsFilterOn = false;
  filteredRows: any[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private api: ApiService,
    private httpClient: HttpClient,
  ) {
  }

  ngOnInit(): void {
    let bonCommandeId = this.bonCommandeId;
    if (typeof this.bonCommandeId == 'object' && this.bonCommandeId !== null) {
      this.mibemCommandeInput = (this.bonCommandeId as Model_MibemCommandGridInput);
      bonCommandeId = this.mibemCommandeInput.BON_COMMANDE_ID;
      this.writeValue(this.mibemCommandeInput);
    }
    this.doGetBonCommandeAction(bonCommandeId);
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.propagateChange(value);
      this.changeDetectorRef.detectChanges();
    }
  }
  validate({ value }: FormControl) {
    let isNotValid = false;
    let invalidRows = [];
    if (!!value) {
      const gridDatas = (value as Model_MibemCommandGridInput).GRID_DATAS;
      Object.keys(gridDatas).forEach((refFamille) => {
        Object.keys(gridDatas[refFamille]).forEach((arRef) => {
          let qteCommande = 0;
          let qteGratuit = 0;
          let ligne_commande = this.bonCommande.LIGNES.find(l => l.FAMILLE_REF == refFamille && l.AR_Ref == arRef);
          if (!!ligne_commande) {
            qteCommande = ligne_commande.DL_QteBC;
            this.bonCommande.REMISES.forEach((remise) => {
              if (remise.ENABLED == 1 && remise.REMISE_LIGNES !== undefined && (remise.TYPE_REMISE_ID == Enum_TypeRemise.GRATUITE_ARTICLE || remise.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_TRANSPORT_GRATUITE || remise.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_LIBRE)) {
                remise.REMISE_LIGNES.filter(l => l.AR_Ref == arRef && (l.ALIAS_ID == this.bonCommande.ALIAS_ID || this.bonCommande.PARENT_BON_COMMANDE_ID == 0)).forEach((ligneArticle) => {
                  qteGratuit += ligneArticle.DL_Qte;
                });
              }
            });
          }

          const quantiteCommande = gridDatas[refFamille][arRef].QUANTITE_COMMANDE != null ? gridDatas[refFamille][arRef].QUANTITE_COMMANDE : 0;
          const quantiteChargement = gridDatas[refFamille][arRef].QUANTITE_CHARGEMENT != null ? gridDatas[refFamille][arRef].QUANTITE_CHARGEMENT : 0;
          const gratuiteCommande = gridDatas[refFamille][arRef].GRATUITE_COMMANDE != null ? gridDatas[refFamille][arRef].GRATUITE_COMMANDE : 0;
          const gratuiteChargement = gridDatas[refFamille][arRef].QUANTITE_GRATUIT != null ? gridDatas[refFamille][arRef].QUANTITE_GRATUIT : 0;
          const isIncorrect = qteCommande != quantiteChargement || qteGratuit != gratuiteChargement;
          const isUnjustified = isIncorrect && (!gridDatas[refFamille][arRef].MOTIF_MODIFICATION || gridDatas[refFamille][arRef].MOTIF_MODIFICATION == 'null');
          if (isUnjustified) {
            invalidRows.push(gridDatas[refFamille][arRef]);
          }
          isNotValid = isNotValid || isUnjustified;
        });
      });
    }
    return isNotValid && {
      invalid: true
    };
  }

  doGetBonCommandeAction(_id: number) {
    this.viewMode = Command_View.COMMAND_LOADING;
    this.httpClient.get(this.api.endPoints.doGetBonCommandeAction_AppCadencier + "/" + _id.toString())
    .subscribe((_bonCommande: any) => {
      try {
        this._processBonCommande(_bonCommande);
      } catch(e) {
        this.loadingStatus = 'Une erreur est survenue lors de l\'affichage de la commande. Assurez vous que les paramètres soient correctement synchronisés. (Contactez un administrateur).';
        this.viewMode = Command_View.COMMAND_LOADING_ERROR;
        console.log(e);
      }
      this.changeDetectorRef.detectChanges();
    }, err => {
      this.loadingStatus = 'Une erreur est survenue lors du chargement de la commande. Veuillez réessayer plus tard.';
      this.viewMode = Command_View.COMMAND_LOADING_ERROR;
      console.log(err);
    });
  }

  processGridForm() {
    var VOLUME_TOTAL_GRID = 0;
    var VOLUME_REMISE_GRID = 0;
    var POIDS_BC_MIB_GRID = 0;
    var POIDS_BC_MIBEM_GRID = 0;
    var POIDS_BC_AGENA_GRID = 0;
    var POIDS_BC_REMISE_GRID = 0;
    var gridDatas: any = {};
    Object.keys(this.gridForms).forEach((refFamille) => {
      gridDatas[refFamille] = {};
      Object.keys(this.gridForms[refFamille]).forEach((arRef) => {

        const ligneBC = this.bonCommande.LIGNES.find(ligne => ligne.AR_Ref == arRef && ligne.FAMILLE_REF == refFamille);
        if (!!ligneBC) {
          VOLUME_REMISE_GRID += this.gridForms[refFamille][arRef].QteGratuitForm.value;
          POIDS_BC_REMISE_GRID += this.gridForms[refFamille][arRef].QteGratuitForm.value * ligneBC.AR_PoidsNet;
          VOLUME_TOTAL_GRID += this.gridForms[refFamille][arRef].QteChargeForm.value + this.gridForms[refFamille][arRef].QteGratuitForm.value;

          if (ligneBC.ALIAS_ID == Enum_AliasID.MIB) {
            POIDS_BC_MIB_GRID += this.gridForms[refFamille][arRef].QteChargeForm.value * ligneBC.AR_PoidsNet;
          }
          if (ligneBC.ALIAS_ID == Enum_AliasID.MIBEM) {
            POIDS_BC_MIBEM_GRID += this.gridForms[refFamille][arRef].QteChargeForm.value * ligneBC.AR_PoidsNet;
          }
          if (ligneBC.ALIAS_ID == Enum_AliasID.AGENA) {
            POIDS_BC_AGENA_GRID += this.gridForms[refFamille][arRef].QteChargeForm.value * ligneBC.AR_PoidsNet;
          }
        }

        gridDatas[refFamille][arRef] = {
          QUANTITE_COMMANDE: !!ligneBC ? ligneBC.DL_QteBC : 0,
          GRATUITE_COMMANDE: !!ligneBC ? ligneBC.QteGratuitBC : 0,
          QUANTITE_CHARGEMENT: this.gridForms[refFamille][arRef].QteChargeForm.value,
          QUANTITE_GRATUIT: this.gridForms[refFamille][arRef].QteGratuitForm.value,
          MOTIF_MODIFICATION: this.gridForms[refFamille][arRef].MotifModification.value,
          COMMENTAIRE: this.gridForms[refFamille][arRef].Commentaire.value,
        };
      });
    });

    this.VOLUME_TOTAL_GRID = VOLUME_TOTAL_GRID;
    this.VOLUME_REMISE_GRID = VOLUME_REMISE_GRID;
    this.POIDS_BC_MIB_GRID = Math.round(POIDS_BC_MIB_GRID);
    this.POIDS_BC_MIBEM_GRID = Math.round(POIDS_BC_MIBEM_GRID);
    this.POIDS_BC_AGENA_GRID = Math.round(POIDS_BC_AGENA_GRID);
    this.POIDS_BC_REMISE_GRID = Math.round(POIDS_BC_REMISE_GRID);
    this.POIDS_BC_GRID = this.POIDS_BC_MIB_GRID + this.POIDS_BC_MIBEM_GRID + this.POIDS_BC_AGENA_GRID + this.POIDS_BC_REMISE_GRID;

    this.mibemCommandeInput.VOLUME_TOTAL_GRID = this.VOLUME_TOTAL_GRID;
    this.mibemCommandeInput.VOLUME_REMISE_GRID = this.VOLUME_REMISE_GRID;
    this.mibemCommandeInput.POIDS_BC_MIB_GRID = this.POIDS_BC_MIB_GRID;
    this.mibemCommandeInput.POIDS_BC_MIBEM_GRID = this.POIDS_BC_MIBEM_GRID;
    this.mibemCommandeInput.POIDS_BC_AGENA_GRID = this.POIDS_BC_AGENA_GRID;
    this.mibemCommandeInput.POIDS_BC_REMISE_GRID = this.POIDS_BC_REMISE_GRID;
    this.mibemCommandeInput.POIDS_BC_GRID = this.POIDS_BC_GRID;
    this.mibemCommandeInput.GRID_DATAS = gridDatas;
    this.mibemCommandeInput.BON_COMMANDE_ID = this.bonCommande.ID;
    this.mibemCommandeInput.BON_COMMANDE_NUM = this.bonCommande.NUM_BC;
    this.writeValue(this.mibemCommandeInput);
    this.validate((this.mibemCommandeInput as any));
  }

  isRowIncorrect(rowCommande: { QTE_COMMANDE_STR: number, QTE_GRATUIT_STR: number}, rowChargement: { QteChargeForm: FormControl, QteGratuitForm: FormControl, MotifModification: FormControl }) {
    const qteCommande = rowCommande.QTE_COMMANDE_STR != null ? rowCommande.QTE_COMMANDE_STR : 0;
    const gratuiteCommande = rowCommande.QTE_GRATUIT_STR != null ? rowCommande.QTE_GRATUIT_STR : 0;
    const qteChargement = rowChargement.QteChargeForm.value != null ? rowChargement.QteChargeForm.value : 0;
    const qteGratuit = rowChargement.QteGratuitForm.value != null ? rowChargement.QteGratuitForm.value : 0;

    const isIncorrect = qteCommande != qteChargement || gratuiteCommande != qteGratuit;
    if (isIncorrect && (!rowChargement.MotifModification.value || rowChargement.MotifModification.value == 'null')) {
      rowChargement.MotifModification.setErrors({"MOTIF_MODIFICATION": true});
    } else {
      rowChargement.MotifModification.setErrors({"MOTIF_MODIFICATION": null})
    }
    return isIncorrect;
  }

  private _processBonCommande(_bonCommande: any) {
    const familles = JSON.parse(JSON.stringify((_bonCommande.FAMILLES as any[])/*.sort((a, b) => ORDER_FAMILLES_IDS.lastIndexOf(a.ID) - ORDER_FAMILLES_IDS.lastIndexOf(b.ID))*/));
    familles.forEach((famille, index, famillesArr) => {
      var famille_articles = [];
      if (famille.ID == FAMILLE_CDF_VIN_ROUGE_ID || famille.ID == FAMILLE_CDF_SANGRIA_ROUGE_ID) {
        famille_articles = (famille.ARTICLES as any[]).sort((a,b) => a.ID - b.ID);
      } else {
        famille_articles = (famille.ARTICLES as any[]).sort((a,b) => a.AR_Design.localeCompare(b.AR_Design));
      }

      famille_articles.forEach((ligne, ligneIndex, lignesArr) => {
        var qteCommande = 0;
        var qteGratuit = 0;
        var ligne_commande = _bonCommande.LIGNES.find(l => l.FAMILLE_ID == famille.ID && l.AR_Ref == ligne.AR_Ref);
        if (!!ligne_commande) {
          qteCommande = ligne_commande.DL_QteBC;
          _bonCommande.REMISES.forEach((remise) => {
            if (remise.ENABLED == 1 && remise.REMISE_LIGNES !== undefined && (remise.TYPE_REMISE_ID == Enum_TypeRemise.GRATUITE_ARTICLE || remise.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_TRANSPORT_GRATUITE || remise.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_LIBRE)) {
              remise.REMISE_LIGNES.filter(l => l.AR_Ref == ligne.AR_Ref && (l.ALIAS_ID == _bonCommande.ALIAS_ID || _bonCommande.PARENT_BON_COMMANDE_ID == 0)).forEach((ligneArticle) => {
                qteGratuit += ligneArticle.DL_Qte;
              });
            }
          });
        }
        lignesArr[ligneIndex].QTE_COMMANDE_STR = qteCommande;
        lignesArr[ligneIndex].QTE_GRATUIT_STR = qteGratuit;
      });

      famillesArr[index].ARTICLES = famille_articles;
    });
    _bonCommande.FAMILLES = familles;
    _bonCommande.POIDS_BC_MIB = _bonCommande.LIGNES.filter(ligne => ligne.ALIAS_ID == Enum_AliasID.MIB && ligne.TYPE_LIGNE == Enum_BCTypeLigne.LIGNE && ligne.IS_HIDDEN == 0).map(l => l.DL_PoidsNetTotal).reduce((sum, current) => sum + current, 0);
    _bonCommande.POIDS_BC_MIBEM = _bonCommande.LIGNES.filter(ligne => ligne.ALIAS_ID == Enum_AliasID.MIBEM && ligne.TYPE_LIGNE == Enum_BCTypeLigne.LIGNE && ligne.IS_HIDDEN == 0).map(l => l.DL_PoidsNetTotal).reduce((sum, current) => sum + current, 0);
    _bonCommande.POIDS_BC_AGENA = _bonCommande.LIGNES.filter(ligne => ligne.ALIAS_ID == Enum_AliasID.AGENA && ligne.TYPE_LIGNE == Enum_BCTypeLigne.LIGNE && ligne.IS_HIDDEN == 0).map(l => l.DL_PoidsNetTotal).reduce((sum, current) => sum + current, 0);
    this.VOLUME_TOTAL = _bonCommande.LIGNES.filter(ligne => ligne.TYPE_LIGNE == Enum_BCTypeLigne.LIGNE && ligne.IS_HIDDEN == 0).map(l => l.DL_QteBC).reduce((sum, current) => sum + current, 0);

    var activesRemises = _bonCommande.REMISES.filter(r => r.ENABLED == 1 && (r.TYPE_REMISE_ID == Enum_TypeRemise.GRATUITE_ARTICLE || r.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_TRANSPORT_GRATUITE || r.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_LIBRE));
    activesRemises.forEach((remise) => {
      var activesRemiseLignes = remise.REMISE_LIGNES;
      if (activesRemiseLignes.length > 0) {
        var activeRemiseLignesArticles = activesRemiseLignes.filter(l => l.DL_Qte != 0 && (_bonCommande.ALIAS_ID == 0 || l.ALIAS_ID == _bonCommande.ALIAS_ID));
        if (activeRemiseLignesArticles.length > 0) {
          this.POIDS_BC_REMISE += activeRemiseLignesArticles.map(a => a.AR_PoidsNet * a.DL_Qte).reduce((sum, current) => sum + current, 0);
          this.VOLUME_REMISE += activeRemiseLignesArticles.map(l => l.DL_Qte).reduce((sum, current) => sum + current, 0);
        }
      }
    });
    this.POIDS_BC = _bonCommande.POIDS_BC_MIB + _bonCommande.POIDS_BC_MIBEM + _bonCommande.POIDS_BC_AGENA + this.POIDS_BC_REMISE;

    _bonCommande.POIDS_BC_MIB = Math.trunc(_bonCommande.POIDS_BC_MIB);
    _bonCommande.POIDS_BC_MIBEM = Math.trunc(_bonCommande.POIDS_BC_MIBEM);
    _bonCommande.POIDS_BC_AGENA = Math.trunc(_bonCommande.POIDS_BC_AGENA);
    this.VOLUME_TOTAL = Math.trunc(this.VOLUME_TOTAL);
    this.VOLUME_REMISE = Math.trunc(this.VOLUME_REMISE);
    this.POIDS_BC_REMISE = Math.trunc(this.POIDS_BC_REMISE);
    this.POIDS_BC = Math.trunc(this.POIDS_BC);

    const dateCreation = new Date(_bonCommande.DATE_CREATION);
    _bonCommande.DATE_CREATION = dateCreation.getDate().toString().padStart(2, '0') + "/" + (dateCreation.getMonth() + 1).toString().padStart(2, '0') + "/" + dateCreation.getFullYear();

    this._initFormControls(_bonCommande);
    this.bonCommande = _bonCommande;
    this.filteredRows = JSON.parse(JSON.stringify(this.bonCommande.FAMILLES));

    this.processGridForm();

    this.mibemCommandeInput.BON_COMMANDE_ID = this.bonCommande.ID;
    this.mibemCommandeInput.BON_COMMANDE_NUM = this.bonCommande.NUM_BC;
    this.mibemCommandeInput.VOLUME_TOTAL_BC = this.VOLUME_TOTAL;
    this.mibemCommandeInput.VOLUME_REMISE_BC = this.VOLUME_REMISE;
    this.mibemCommandeInput.POIDS_BC_MIB = _bonCommande.POIDS_BC_MIB;
    this.mibemCommandeInput.POIDS_BC_MIBEM = _bonCommande.POIDS_BC_MIBEM;
    this.mibemCommandeInput.POIDS_BC_AGENA = _bonCommande.POIDS_BC_AGENA;
    this.mibemCommandeInput.POIDS_REMISE_BC = this.POIDS_BC_REMISE;
    this.mibemCommandeInput.POIDS_BC = this.POIDS_BC;

    this.viewMode = Command_View.COMMAND_LOADED;
  }

  private _initFormControls(_bonCommande: any) {
    var formControls: {[key: number]: {[key: string]: { QteChargeForm: FormControl, QteGratuitForm: FormControl, MotifModification: FormControl, Commentaire: FormControl }}} = {};
    _bonCommande.FAMILLES.forEach((famille) => {
      formControls[famille.REF_FAMILLE] = {};
      famille.ARTICLES.forEach((ligne) => {
        var qteCommande = 0;
        var qteGratuit = 0;
        var motifModification = null;
        var commentaire = null;
        /*var ligne_commande = _bonCommande.LIGNES.find(l => l.FAMILLE_ID == famille.ID && l.AR_Ref == ligne.AR_Ref);
        if (!!ligne_commande) {
          qteCommande = ligne_commande.DL_QteBC;
          _bonCommande.REMISES.forEach((remise) => {
            if (remise.ENABLED == 1 && remise.REMISE_LIGNES !== undefined && (remise.TYPE_REMISE_ID == Enum_TypeRemise.GRATUITE_ARTICLE || remise.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_TRANSPORT_GRATUITE || remise.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_LIBRE)) {
              remise.REMISE_LIGNES.filter(l => l.AR_Ref == ligne.AR_Ref && (l.ALIAS_ID == _bonCommande.ALIAS_ID || _bonCommande.PARENT_BON_COMMANDE_ID == 0)).forEach((ligneArticle) => {
                qteGratuit += ligneArticle.DL_Qte;
              });
            }
          });
          ligne_commande.QteGratuitBC = qteGratuit;
        }*/
        if (this.mibemCommandeInput.GRID_DATAS !== undefined && this.mibemCommandeInput.GRID_DATAS[famille.REF_FAMILLE][ligne.AR_Ref] !== undefined) {
          qteCommande = this.mibemCommandeInput.GRID_DATAS[famille.REF_FAMILLE][ligne.AR_Ref].QUANTITE_CHARGEMENT;
          qteGratuit = this.mibemCommandeInput.GRID_DATAS[famille.REF_FAMILLE][ligne.AR_Ref].QUANTITE_GRATUIT;
          motifModification = this.mibemCommandeInput.GRID_DATAS[famille.REF_FAMILLE][ligne.AR_Ref].MOTIF_MODIFICATION;
          commentaire = this.mibemCommandeInput.GRID_DATAS[famille.REF_FAMILLE][ligne.AR_Ref].COMMENTAIRE;
        }

        formControls[famille.REF_FAMILLE][ligne.AR_Ref] = {
          QteChargeForm: new FormControl(qteCommande != 0 ? qteCommande : null),
          QteGratuitForm: new FormControl(qteGratuit != 0 ? qteGratuit : null),
          MotifModification: new FormControl(motifModification),
          Commentaire: new FormControl(commentaire),
        };
      });
    });
    this.gridForms = formControls;
  }

  applyFilterRows(filtered: boolean) {
    this.onlyFilledRowsFilterOn = filtered;
    let filteredFamilles = JSON.parse(JSON.stringify(this.bonCommande.FAMILLES));
    if (this.onlyFilledRowsFilterOn) {
      filteredFamilles.forEach((famille, index, famillesArray) => {
        famillesArray[index].ARTICLES = famillesArray[index].ARTICLES.filter(item => item.QTE_COMMANDE_STR != 0 || item.QTE_GRATUIT_STR != 0);
      });
      filteredFamilles = filteredFamilles.filter(famille => famille.ARTICLES.length > 0);
    }
    this.filteredRows = filteredFamilles;
  }

}
