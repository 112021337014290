import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'cfc-soudotec-command-scan-item',
    template: `<h2 mat-dialog-title>Article Scanné</h2>
                <mat-dialog-content class="mat-typography">
                    <form [formGroup]="docLigneForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Article <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [value]="docLigne.DL_Design" readonly="readonly" />
                                </div>
                                <div class="form-group">
                                    <label>Quantité <span class="text-danger">*</span></label>
                                    <input type="number" class="form-control" formControlName="DL_Qte" />
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-dialog-content>
                <mat-dialog-actions align="end">
                    <button type="button" class="btn btn-outline-primary" mat-dialog-close>Annuler</button>
                    <button type="button" class="btn btn-primary" (click)="onSubmit()" cdkFocusInitial>Valider</button>
                </mat-dialog-actions>`
})
export class CfcSoudotecCommandScanItemComponent implements OnInit {
    docLigne: any;
    docLigneForm:FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<CfcSoudotecCommandScanItemComponent>
    ) {
        this.docLigne = data['docLigne'];
        this.docLigneForm = this.formBuilder.group({
            DL_Qte: this.docLigne.DL_Qte,
        });
    }

    ngOnInit() {
        
    }

    onSubmit() {
        const datas = { cbMarq: this.docLigne.cbMarq, DL_Qte: this.docLigneForm.controls['DL_Qte'].value };
        this.dialogRef.close(datas);
    }
}
