import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DbmWorkflowStep } from 'src/app/views/app/app.models';

@Component({
  selector: 'cfc-text-autocomplete',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CFCTextAutocompleteComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-text-autocomplete.component.html'
})
export class CFCTextAutocompleteComponent implements ControlValueAccessor {
  @Input() workflowStep?: DbmWorkflowStep;
  @Input() textValue: string;
  findChoices = (searchText: string) => [];

  propagateChange = (_: any) => {}

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  writeValue(value: any) {
    this.textValue = value;
    const componentNames = this._doGetWorkflowStepUIComponentsNames(this.workflowStep);
    this.findChoices = (searchText: string) => componentNames.filter(item => item.toLowerCase().includes(searchText.toLowerCase()));
    this.propagateChange(this.textValue);
  }

  getChoiceLabel(choice: string) {
    return `@${choice} `;
  }

  onChangeInput(event) {
    this.propagateChange(this.textValue);
  }

  onChoiceSelected(event) {
    this.propagateChange(this.textValue);
  }

  private _doGetWorkflowStepUIComponentsNames(workflowStep: DbmWorkflowStep): string[] {
    if (workflowStep === undefined) { return []; }
    return workflowStep.UI_COMPONENTS.map(component => component.UIC_NOM);
  }
}
