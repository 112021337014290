<kendo-numerictextbox
  [required]="required"
  [readonly]="readOnly"
  [disabled]="disabled"
  [value]="value"
  [autoCorrect]="autoCorrect"
  [format]="'n0'"
  [placeholder]="placeholder"
  (valueChange)="onValueChanged($event)">
</kendo-numerictextbox>
