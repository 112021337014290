import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { DynamicFormControlModel, DynamicFormLayout } from '@ng-dynamic-forms/core';
import { CfcInputGridColumnsPropertyModel } from './cfc-input-grid-columns-property.model';
import {MatDialog} from '@angular/material/dialog';
import { CfcInputGridColumnsPropertyDialogComponent } from './cfc-input-grid-columns-property-dialog.component'
import { Enum_WorkflowUIComponent } from 'src/app/views/app/app.models';

@Component({
  selector: 'cfc-input-grid-columns-property',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcInputGridColumnsPropertyComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-input-grid-columns-property.component.html'
})
export class CfcInputGridColumnsPropertyComponent implements OnInit, ControlValueAccessor {

  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() value: any;
  @Input() model: CfcInputGridColumnsPropertyModel;
  availablesInputForm = [
    { label : 'Textbox', value : 110 },
    { label : 'Numeric Texbox', value : 120 },
    { label : 'Textarea', value : 130 },
    { label : 'DatePicker', value : 140 },
    { label : 'Liste déroulante', value : 100 },
    { label : 'Scan Document', value : 50 },
    { label : 'Lecteur Code Barre', value : 60 },
    { label : 'Signature Electronique', value : 70 },
    { label : 'Upload Fichier', value : 150 },
    { label : 'Famoco Scan Laser', value : 170 },
  ];
  gridColumns: { [key: string]: FormControl }[] = [];
  selectedColumnIndex = -1;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.value !== null) {
      for (var i = 0; i < this.value.length; i++) {
        this.gridColumns.push({
          'TYPE_CELL': new FormControl(this.value[i]['TYPE_CELL'], Validators.required),
          'IDENTIFIANT': new FormControl(this.value[i]['IDENTIFIANT'], Validators.required),
          'LIBELLE': new FormControl(this.value[i]['LIBELLE'], Validators.required),
          'READONLY': new FormControl(this.value[i]['READONLY']),
          'TYPE_DEFAULT_VALUE': new FormControl(this.value[i]['TYPE_DEFAULT_VALUE']),
          'DEFAULT_VALUE_COMPUTED': new FormControl(this.value[i]['DEFAULT_VALUE_COMPUTED']),
          'DEFAULT_VALUE': new FormControl(this.value[i]['DEFAULT_VALUE']),
          'DATASOURCE_ID': new FormControl(this.value[i]['DATASOURCE_ID']),
          'EXP_VALEUR': new FormControl(this.value[i]['EXP_VALEUR']),
          'EXP_AFFICHAGE': new FormControl(this.value[i]['EXP_AFFICHAGE']),
          'INPUT_DROPDOWN_LINKED_DROPDOWN': new FormControl(this.value[i]['INPUT_DROPDOWN_LINKED_DROPDOWN']),
          'LINKED_VALUE_EXP': new FormControl(this.value[i]['LINKED_VALUE_EXP']),
        });
      }
    }
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      this.propagateChange(value);
      this.changeDetectorRef.detectChanges();
    }
  }

  updateValue() {
    var columnsDatas = [];
    for (var i = 0; i < this.gridColumns.length; i++) {
      var columnItem = {};
      for (var c in this.gridColumns[i]) {
        columnItem[c] = this.gridColumns[i][c].value;
      }
      columnsDatas.push(columnItem)
    }
    this.writeValue(columnsDatas);
  }

  onAddColumnAction() {
    this.gridColumns.push({
      'TYPE_CELL': new FormControl('110', Validators.required),
      'IDENTIFIANT': new FormControl(null, Validators.required),
      'LIBELLE': new FormControl(null, Validators.required),
      'READONLY': new FormControl(false),
      'TYPE_DEFAULT_VALUE': new FormControl(0),
      'DEFAULT_VALUE': new FormControl(null),
      'DEFAULT_VALUE_COMPUTED': new FormControl(null),
      'DATASOURCE_ID': new FormControl(null),
      'EXP_VALEUR': new FormControl(null),
      'EXP_AFFICHAGE': new FormControl(null),
      'INPUT_DROPDOWN_LINKED_DROPDOWN': new FormControl(null),
      'LINKED_VALUE_EXP': new FormControl(null),
    });
    this.updateValue();
  }

  onRemoveColumnAction(colIndex: number) {
    this.gridColumns.splice(colIndex, 1);
    this.updateValue();
  }

  onEditColumnAction(colIndex: number) {
    this.selectedColumnIndex = colIndex;
    var columnsDatas = {};
    var columnsFormNames: any[] = [];
    for (var c in this.gridColumns[this.selectedColumnIndex]) {
      columnsDatas[c] = this.gridColumns[this.selectedColumnIndex][c].value;
    }

    for (var c in this.gridColumns) {
      columnsFormNames.push({ value: this.gridColumns[c]['IDENTIFIANT'].value, label: this.gridColumns[c]['LIBELLE'].value });
    }

    const dialogRef = this.dialog.open(CfcInputGridColumnsPropertyDialogComponent, {
      data: {
        availablesInputForm: this.availablesInputForm,
        columnsDatas: columnsDatas, 
        datasources: this.model.datasources,
        uiComponents: columnsFormNames 
      }
    });

    dialogRef.afterClosed().subscribe(column => {
      if (column === undefined) { this.selectedColumnIndex = -1; return; }
      for (var c in column) {
        this.gridColumns[this.selectedColumnIndex][c].patchValue(column[c]);
      }
      this.updateValue();
      this.selectedColumnIndex = -1;
    });
  }

}
