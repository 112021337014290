import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'cfc-input-grid-columns-property-dialog',
    templateUrl: 'cfc-input-grid-columns-property-dialog.component.html',
})
export class CfcInputGridColumnsPropertyDialogComponent {

    availablesInputForm: { label: string, value: number }[] = [];
    column: any = {};
    datasources: { label: string, value: any }[];
    uiComponents: { label: string, value: any }[];

    constructor(
        private activeDialog: MatDialogRef<CfcInputGridColumnsPropertyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.availablesInputForm = data['availablesInputForm'];
        this.column = data['columnsDatas'];
        this.datasources = data['datasources'];
        this.uiComponents = data['uiComponents'];
    }

    onSubmitAction() {
        this.activeDialog.close(this.column);
    }

    onCancelAction() {
        this.activeDialog.close();
    }
}