import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'cfc-input-file',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcInputFileComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-input-file.component.html'
})
export class CfcInputFileComponent implements OnInit, ControlValueAccessor {

  @Input() options: { value: any, label: string }[] = [];
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() value: any;
  allowedExtensions = ["jpg", "jpeg", "png"];

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.propagateChange(null);
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      this.changeDetectorRef.detectChanges();
    }
  }

  onValueChanged(e: any) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  private _handleReaderLoaded(e) {
    let reader = e.target;
    const base64Datas = reader.result.substr(reader.result.lastIndexOf(',') + 1);
    this.writeValue(base64Datas)
    this.propagateChange(this.value);
  }


}
