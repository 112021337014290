import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './home/home.component';
import { SettingsApiComponent } from './settings-api/settings-api.component';
import { AuthGuard } from 'src/app/core/auth/guards/auth.guard';
import { UserRole } from 'src/app/core/auth/auth.roles';
import { RedirectIfMobileGuard } from '../shared/redirect-if-mobile.guard';
import { ApiInitialisatedGuard } from '../core/api-initialisted.guard';

const adminRoot = environment.adminRoot.substr(1); // path cannot start with a slash

let routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [RedirectIfMobileGuard],
  },
  {
    path: adminRoot,
    loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    data: { roles: [UserRole.Admin, UserRole.Agent] },
    //canActivate: [ApiInitialisatedGuard, AuthGuard],
    canActivateChild: [AuthGuard],
  },
  { path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'app/processing/workflows' },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    //canActivate: [ApiInitialisatedGuard]
  },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'api-settings', component: SettingsApiComponent },
  //{ path: '', redirectTo: '/app/processing/workflows', pathMatch: 'full' },
  { path: '**', redirectTo: '/error' },
];

if (!environment.isAuthGuardActive) {
  routes = [
    {
      path: '',
      component: HomeComponent,
      pathMatch: 'full',
      canActivate: [RedirectIfMobileGuard],
    },
    {
      path: 'app',
      loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
      //canActivate: [ApiInitialisatedGuard]
    },
    {
      path: 'user',
      loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
      //canActivate: [ApiInitialisatedGuard]
    },
    { path: 'error', component: ErrorComponent },
    { path: 'api-settings', component: SettingsApiComponent },
    { path: '**', redirectTo: '/error' },
  ];
}
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewRoutingModule {}
