import { Injectable } from "@angular/core";
import { DbmDatasource } from "../../views/app/app.models";

const TB_DATASOURCES_NAME = "LOCAL_TB_DATASOURCES";

@Injectable()
export class DatasourcesOfflineDao {
    doSyncDatasourcesAction(_datasources: DbmDatasource[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doClearDatasourcesAction();
            this.doInsertDatasourcesAction(_datasources).then(() => {
                resolve();
            }, err => reject(err));
        });
    }

    doClearDatasourcesAction() {
        localStorage.setItem(TB_DATASOURCES_NAME, "[]");
    }

    doGetDatasourcesAction(): Promise<DbmDatasource[]> {
        return new Promise((resolve, reject) => {
            var datas: DbmDatasource[] = [];
            try {
                datas = JSON.parse(localStorage.getItem(TB_DATASOURCES_NAME));
            } catch(e) { console.log(e) }
            resolve(datas);
        });
    }

    doInsertDatasourcesAction(_datasources: DbmDatasource[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doGetDatasourcesAction().then((_datasTable: DbmDatasource[]) => {
                var newDatas = _datasTable.concat(_datasources);
                localStorage.setItem(TB_DATASOURCES_NAME, JSON.stringify(newDatas));
                resolve();
            }, err => reject(err));
        });
    }

    doGetDatasourceAction(_id: number): Promise<DbmDatasource> {
        return new Promise((resolve, reject) => {
            this.doGetDatasourcesAction().then((_datasTable: DbmDatasource[]) => {
                const datasource = _datasTable.find(w => w.ID == _id);
                resolve(datasource);
            }, err => reject(err));
        });
    }
}
