<div *ngIf="viewMode == Command_View.COMMAND_LOADING">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="viewMode == Command_View.COMMAND_LOADING_ERROR">
    <button class="btn btn-block btn-danger" type="button">
        <span class="material-icons">warning</span>
        {{ loadingStatus }}
        <span class="material-icons">warning</span>
    </button>
</div>

<div *ngIf="viewMode == Command_View.COMMAND_LOADED">
    <div style='margin-top: 15px'>
        <table style='border-collapse:collapse; width: 100%'>
            <tbody>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>DATE : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>08/07/2023</td>
                </tr>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>CLIENT : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>SOCOCE</td>
                </tr>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>NUM PIECE : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>#045257</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <ng-container>
            <div style='margin-top: 15px; width: 100%;'>
                <table id="grid" style='border-collapse:collapse; width: 100%'>
                    <thead>
                        <tr>
                            <th style='border:1px solid black; font-size: 14px;font-family: Calibri; text-align:center'>Article</th>
                            <th style='border:1px solid black; font-size: 14px;font-family: Calibri; text-align:center'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let article of articles; let rowIndex = index">
                            <td style='border:1px solid black; font-size: 14px;font-family: Calibri'>
                                {{ article.DL_Design }}<br /><br />
                                <div class="badge badge-pill badge-secondary">{{ article.AR_CodeBarre || "Aucun code barre" }}</div>
                                <div class="badge badge-pill badge-secondary" *ngIf="article.STATUS === Enum_SoudotecBarcodesVerificationStatus.CORRESPONDANCE_OK">OK</div>
                                <div class="badge badge-pill badge-warning" *ngIf="article.STATUS === Enum_SoudotecBarcodesVerificationStatus.AUCUNE_CORRESPONDANCE">NONE</div>
                                <div class="badge badge-pill badge-secondary" *ngIf="article.STATUS === Enum_SoudotecBarcodesVerificationStatus.CORRESPONDANCE_NON_OK">NON OK</div>
                                <br />
                            </td>
                            <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>
                                <cfc-famoco-laser [formControl]="article.SCAN_CODE" (changed)="onScanned(rowIndex)"></cfc-famoco-laser>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pre>{{ validate ? "TRUEE" : "FAALSE" }}</pre>
            </div>
        </ng-container>
    </div>
</div>
<div style="clear:both">&nbsp;</div>