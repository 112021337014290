import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cfc-chronometer',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcChronometerComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-chronometer.component.html',
  styleUrls: ['./cfc-chronometer.component.scss']
})
export class CfcChronometerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  constructor(private cdf: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) { }

  //#region Timer
  _timerFormated: string = "00:00:00";
  private _heures: number = 0;
  private _minutes: number = 0;
  private _secondes: number = 0;
  private _totalSecondes: number = 0;
  private _timer;
  get minutes(): number { return this._minutes; }
  get secondes(): number { return this._secondes; }
  start() {
    this._timer = setInterval(() => {
      this._minutes = Math.floor(++this._totalSecondes / 60);
      this._secondes = this._totalSecondes - this._minutes * 60;
      this._heures = parseInt((this._minutes / 60).toString());
      this._timerFormated = `${this._heures.toString().padStart(2, '0')}:${this._minutes.toString().padStart(2, '0')}:${this._secondes.toString().padStart(2, '0')}`;
      this.propagateChange(this._timerFormated);
      this.cdf.detectChanges();
    }, 1000);
  }
  stop() {
    clearInterval(this._timer);
  }
  reset() {
    this._totalSecondes = this._minutes = this._secondes = 0;
  }
  //#endregion

}
