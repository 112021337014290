import { Injectable } from "@angular/core";
import * as moment from "moment";
import { DbmResponseWorkflow, ReqParams_HistoryResponseWorkflow } from "../../views/app/app.models";
import { UnitOfWorkOfflineDao } from "../daos/unitofwork-offline.dao";

@Injectable()
export class ResponsesOfflineService {
    constructor(private unitOfWork: UnitOfWorkOfflineDao) { }

  doClearResponsesAction_ResponseWorkflow() {
      this.unitOfWork.responsesDao.doClearResponsesAction();
  }

    doCountResponsesAction_ResponseWorkflows() {
        return new Promise((resolve, reject) => {
            this.unitOfWork.responsesDao.doGetResponsesAction()
            .then(async (_responses: DbmResponseWorkflow[]) => {
                resolve(_responses.length);
            }, err => reject(err));
        });
    }

    doGetResponsesAction_ResponseWorkflows(_reqParams?: ReqParams_HistoryResponseWorkflow): Promise<DbmResponseWorkflow[]> {
        return new Promise((resolve, reject) => {
            this.unitOfWork.responsesDao.doGetResponsesAction()
            .then(async (_responses: DbmResponseWorkflow[]) => {
                var responsesFiltered = _responses;
                if (!!_reqParams) {
                    if (!!_reqParams.BORNE_INF) {
                        responsesFiltered = responsesFiltered.filter(r => moment(r.DATE_CREATION).isSameOrAfter(moment(_reqParams.BORNE_INF).toDate(), 'day'));
                    }
                    if (!!_reqParams.BORNE_SUP) {
                        responsesFiltered = responsesFiltered.filter(r => moment(r.DATE_CREATION).isSameOrBefore(moment(_reqParams.BORNE_SUP).toDate(), 'day'));
                    }
                    if (!!_reqParams.NUMS_WORKFLOWS && _reqParams.NUMS_WORKFLOWS.length > 0) {
                        responsesFiltered = responsesFiltered.filter(r => _reqParams.NUMS_WORKFLOWS.lastIndexOf(r.NUM_WORKFLOW) !== -1);
                    }
                }
                responsesFiltered = responsesFiltered.sort((a,b) => moment(b.DATE_CREATION).valueOf() - moment(a.DATE_CREATION).valueOf());
                new Promise<void>(async (resolve, reject) => {
                    await responsesFiltered.forEach(async (_response) => {
                        _response.DATE_CREATION_DISPLAY = moment(_response.DATE_CREATION).toDate().toDateString();
                        _response.WORKFLOW = await this.unitOfWork.workflowsDao.doGetWorkflowAction(_response.NUM_WORKFLOW);
                        _response.User = JSON.parse(localStorage.getItem('auth_user'));
                    });
                    resolve();
                }).then(() => {
                    responsesFiltered = responsesFiltered.filter(r => r.WORKFLOW !== undefined);
                    resolve(responsesFiltered);
                });
            }, err => reject(err));
        });
    }

    doGetResponseAction_ResponseWorkflows(_guid: string): Promise<DbmResponseWorkflow> {
        return new Promise((resolve, reject) => {
            this.unitOfWork.responsesDao.doGetResponseAction(_guid)
            .then(async (_response: DbmResponseWorkflow) => {
                _response.DATE_CREATION_DISPLAY = moment(_response.DATE_CREATION).toDate().toDateString();
                _response.WORKFLOW = await this.unitOfWork.workflowsDao.doGetWorkflowAction(_response.NUM_WORKFLOW);
                _response.User = JSON.parse(localStorage.getItem('auth_user'));
                resolve(_response);
            }, err => reject(err));
        });
    }

    doSaveResponseWorkflowAction_ResponseWorkflows(_response: DbmResponseWorkflow): Promise<DbmResponseWorkflow> {
        return new Promise((resolve, reject) => {
            this.unitOfWork.responsesDao.doSaveResponsesAction(_response)
            .then((_savedResponse: DbmResponseWorkflow) => {
                resolve(_savedResponse);
            }, err => reject(err));
        });
    }
}
