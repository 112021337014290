import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'cfc-image-card-checklist',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcImageCardCheckListComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-image-card-checklist.component.html'
})
export class CfcImageCardCheckListComponent implements ControlValueAccessor {
  @Input()
  name: string;

  @Input() items = [];

  @Input()
  _itemsModel = [];

  get itemsModel() {
    return this._itemsModel;
  }

  set itemsModel(val) {
    this._itemsModel = val;
    this.propagateChange(this._itemsModel);
  }

  onCheckChange(item) {
    this.items.forEach(element => { if(element.ID == item.ID){ item.VALUE = !item.VALUE; }});
    this.itemsModel = this.items.filter(function(a){ return a.VALUE; }).map(function(a){ return a.ID; });
  }

  propagateChange = (_: any) => {};
  
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  
  registerOnTouched() {}

  writeValue(value: any) { 
    if (value !== undefined) {
      this.itemsModel = value;
    }
  }

}