import {Component, OnInit, Renderer2, AfterViewInit, Inject} from '@angular/core';
import { LangService } from './shared/lang.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ApiService } from './core/api.service';
import { ConnectorSettingsAPIService } from './views/settings-api/connector-settings-api.service';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = environment.isMultiColorActive;
  constructor(
    private langService: LangService,
    private renderer: Renderer2,
    private apiService: ApiService,
    private connectorSettingsAPIService: ConnectorSettingsAPIService,
    @Inject(DOCUMENT) private _document: Document,
  ) {
  }

  ngOnInit() {
    this.langService.init();
    //this.doInitApiEndPoints();
  }

  doInitApiEndPoints() {
    this.apiService.onSaveAction_APIEndpoints(location.origin);
    this.connectorSettingsAPIService.doPingAction().subscribe((res : { APP_VERSION: string, COMPANY_NAME: string }) => {
      localStorage.setItem(environment.LS_ABWORKFLOW_CURRENT_COMPANY, res.COMPANY_NAME);
      //localStorage.setItem(environment.LS_ABWORKFLOW_CURRENT_COMPANY, res.COMPANY_NAME);
    }, err => { });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
    var _currentTheme = localStorage.getItem(environment.themeColorStorageKey);
    if(_currentTheme.indexOf('dark') > - 1){
      var _lightThemeLink = document.querySelector('#lightThemeLink');
      _lightThemeLink.parentNode.removeChild(_lightThemeLink);
    }
    else {
      var _darkThemeLink = document.querySelector('#darkThemeLink');
      _darkThemeLink.parentNode.removeChild(_darkThemeLink);
    }

    if (this.apiService.isOfflineMode && !this._document.body.classList.contains('dark-mode')) {
      this._document.body.classList.add('dark-mode');
    } else if (this._document.body.classList.contains('dark-mode')) {
      this._document.body.classList.remove('dark-mode');
    }
  }
}
