import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
    DynamicInputModelConfig,
    DynamicInputModel,
} from "@ng-dynamic-forms/core";

export interface CfcSoudotecInventoryModelConfig extends DynamicInputModelConfig {
  datas?: any[];
}

export class CfcSoudotecInventoryModel extends DynamicInputModel {
  @serializable() readonly type: string = 'CfcSoudotecInventory';
  datas: any[];

  constructor(config: CfcSoudotecInventoryModelConfig, layout?: DynamicFormControlLayout) {
    super(config, layout);
    this.datas = config.datas;
  }
}

export class Model_SoudotecInventoryItem {
  AR_Ref: string;
  AR_Design: string;
  AS_QteSto: number;
  DE_Intitule: string;
  AE_QteSto: number;
  DP_Code: string;
  DP_Intitule: string;
  QUANTITE_COMPTEE: number;
  BARCODE_SCANNED: string;
}