import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'src/app/core/auth/auth.utils';
import { ApiService } from 'src/app/core/api.service';
import { AuthUser } from 'src/app/core/auth/auth.models';
import { auth } from 'firebase';
import { DbmActivityLog, DbmVariableEnvironmentUser, JwtToken } from 'src/app/views/app/app.models';
import { environment } from 'src/environments/environment';
import { SynchronisationOfflineApi } from 'src/app/@offline-api/api/synchronisation-offline.api';

@Injectable()
export class AuthService
{
    // Private
    private _authenticated: boolean;
    public _currentAuthUserSubject: BehaviorSubject<AuthUser>;
    public _currentAuthUser: Observable<AuthUser>;
    _activitiesLogSubject: BehaviorSubject<DbmActivityLog[]>;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        public apiService: ApiService,
        private _httpClient: HttpClient,
        private synchronisationOfflineApi: SynchronisationOfflineApi,
    )
    {
        // Set the defaults
        this._authenticated = false;
        var authenticatedUser = JSON.parse(localStorage.getItem('auth_user'));
        this._currentAuthUserSubject = new BehaviorSubject<AuthUser>(authenticatedUser);
        this._currentAuthUser = this._currentAuthUserSubject.asObservable();
        this._activitiesLogSubject = new BehaviorSubject<DbmActivityLog[]>([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter and getter for access token
     */
    set accessToken(token: string) {
        //localStorage.setItem('access_token', token);
        localStorage.setItem('access_token', token);
    }

    get accessToken(): string {
        return localStorage.getItem('access_token');
    }

    set authUser(authUser : AuthUser) {
        //localStorage.setItem('auth_user', JSON.stringify(authUser));
      localStorage.setItem('auth_user', JSON.stringify(authUser));
    }

    get authUser() {
      try {
        return JSON.parse(localStorage.getItem('auth_user'));
      } catch(e) { return null; }

    }

    public get currentAuthUser(): AuthUser {
        return this._currentAuthUserSubject.value;
    }

    set variablesEnvironment(variablesEnvironment : DbmVariableEnvironmentUser[]) {
      const authUser = this.currentAuthUser;
      authUser.VARIABLES_ENVIRONMENT = variablesEnvironment;
      this._currentAuthUserSubject.next(authUser);
    }

    get variablesEnvironment(): DbmVariableEnvironmentUser[] {
      const authU = this._currentAuthUserSubject.value;
      return authU !== undefined ? authU.VARIABLES_ENVIRONMENT : [];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string, password: string }): Observable<any> {
      if (this._authenticated) {
        //return throwError('Utilisateur déjà connecté');
        return of(true);
      }
      return this._httpClient.post(this.apiService.endPoints.authenticate, credentials).pipe(switchMap((response: JwtToken) => {
        this.accessToken = response.access_token;
        this.authUser = response;
        this._currentAuthUserSubject.next(response);
        this._activitiesLogSubject.next(response.RECENTS_ACTIVITY);
        localStorage.setItem(environment.LS_ABWORKFLOW_DEFAULT_FILTERS_DATAS_ADMINISTRATION, undefined);
        localStorage.setItem(environment.LS_ABWORKFLOW_DEFAULT_FILTERS_DATAS_HISTORY, undefined);
        this._authenticated = true;
        return of(response);
      }));
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
      return this._httpClient.post(this.apiService.endPoints.authenticateUsingToken, { access_token: this.accessToken})
              .pipe(
                catchError(() => { return of(false); }),
                switchMap((response: JwtToken) => {
                  this.accessToken = response.access_token;
                  this.authUser = response;
                  this._currentAuthUserSubject.next(response);
                  this._activitiesLogSubject.next(response.RECENTS_ACTIVITY);
                  localStorage.setItem(environment.LS_ABWORKFLOW_DEFAULT_FILTERS_DATAS_ADMINISTRATION, undefined);
                  localStorage.setItem(environment.LS_ABWORKFLOW_DEFAULT_FILTERS_DATAS_HISTORY, undefined);
                  this._authenticated = true;
                  return of(true);
              }));
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
      if (!this.apiService.isOfflineMode) {
        this.synchronisationOfflineApi.doClearOfflineDatabaseAction();
        localStorage.removeItem('access_token');
        localStorage.removeItem('auth_user');
        localStorage.removeItem(environment.LS_ABWORKFLOW_FINGERPRINT_KEY);
        localStorage.setItem(environment.LS_ABWORKFLOW_DEFAULT_FILTERS_DATAS_ADMINISTRATION, undefined);
        localStorage.setItem(environment.LS_ABWORKFLOW_DEFAULT_FILTERS_DATAS_HISTORY, undefined);
        localStorage.setItem(environment.LS_ABWORKFLOW_ENCRYPTED_PWD, undefined);
      }
      this._currentAuthUserSubject.next(null);
      this._authenticated = false;
      return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
      if (this._authenticated) {
        return of(true);
      }
      if (!this.accessToken) {
        return of(false);
      }

      if (this.apiService.isOfflineMode) {
        this._authenticated = true;
        return of(true);
      } else {
        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
          return of(false);
        }
        return this.signInUsingToken(); // If the access token exists and it didn't expire, sign in using it
      }
    }

    // UI Utils
    private isAbwAppLocked: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public isAbwAppLocked$: Observable<any> = this.isAbwAppLocked.asObservable();

    doLockAbwAppAction_UI() {
      this.isAbwAppLocked.next(true);
    }

    doUnlockAbwAppAction_UI() {
      this.isAbwAppLocked.next(false);
    }

}
