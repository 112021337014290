import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'cfc-input-dropdown',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcInputDropdownComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-input-dropdown.component.html'
})
export class CfcInputDropdownComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() options: { value: any, label: string }[] = [];
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() value: any;
  mFilteredOptions: { value: any, label: string }[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.mFilteredOptions = this.options;
    this.propagateChange(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options !== undefined) {
      this.mFilteredOptions = this.options;
    }
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      this.changeDetectorRef.detectChanges();
    }
  }

  onValueChanged(value: number) {
    this.propagateChange(value);
  }

  handleFilter(value) {
    this.mFilteredOptions = this.options.filter(
      (s) => s.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

}
