import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'cfc-input-numerictextbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcInputNumericTextboxComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-input-numerictextbox.component.html'
})
export class CfcInputNumericTextboxComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() options: { value: any, label: string }[] = [];
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() placeholder = null;
  @Input() value: any;
  @Input() autoCorrect = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value !== undefined) {
      try { this.value = parseFloat(changes.value.currentValue); } catch(e) { console.log(e); this.value = 0; }
    }
  }

  ngOnInit() {
    this.propagateChange(null);
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined && value !== null) {
      try { this.value = parseFloat(value); } catch(e) { console.log(e); }
      this.changeDetectorRef.detectChanges();
    }
  }

  onValueChanged(value: number) {
    this.propagateChange(value);
  }

}
