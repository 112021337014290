import { Injectable } from "@angular/core";
import { DbmResponseWorkflow, DbmTeam, DbmVariableEnvironmentUser, DbmWorkflow, ReqParams_HistoryResponseWorkflow, StartupInfos_HistoryResponseWorkflow } from "../../views/app/app.models";
import { EnvVarUserOfflineService } from "../services/envvaruser-offline.service";
import { ResponsesOfflineService } from "../services/responses-offline.service";
import { TeamsOfflineService } from "../services/teams-offline.service";
import { WorkflowsOfflineService } from "../services/workflows-offline.service";

@Injectable()
export class ResponsesOfflineApi {
    constructor(
        private _workflowOfflineService: WorkflowsOfflineService,
        private _responsesOfflineServices: ResponsesOfflineService,
        private _envVarUserOfflineServices: EnvVarUserOfflineService,
        private _teamsOfflineServices: TeamsOfflineService,
    ) {}

    doGetStartupInfosAction_HistoryResponsesWorkflow(_reqParams: ReqParams_HistoryResponseWorkflow): Promise<StartupInfos_HistoryResponseWorkflow> {
        return new Promise(async (resolve, reject) => {
            const startupInfos = new StartupInfos_HistoryResponseWorkflow();
            startupInfos.mResponsesWorkflow = await this._responsesOfflineServices.doGetResponsesAction_ResponseWorkflows(_reqParams);
            resolve(startupInfos);
        });
    }

    doGetDetailsResponseAction_HistoryResponseWorkflow(_guid: string): Promise<DbmResponseWorkflow> {
        return new Promise(async (resolve, reject) => {
            const response = await this._responsesOfflineServices.doGetResponseAction_ResponseWorkflows(_guid);
            resolve(response);
        });
    }

    doGetFiltersOptionsAction_HistoryResponsesWorkflow(): Promise<{ TEAMS: DbmTeam[], WORKFLOWS: DbmWorkflow[] }> {
        return new Promise(async (resolve, reject) => {
            const workflows = await this._workflowOfflineService.doGetAssignedWorkflowsAction_Processing();
            const teams = await this._teamsOfflineServices.doGetTeamsAction_Teams();
            resolve({
                TEAMS: teams,
                WORKFLOWS: workflows
            });
        });
    }

    doSaveResponseWorkflowAction_ResponseWorkflows(_response: DbmResponseWorkflow): Promise<{ RESPONSE_WORKFLOW: DbmResponseWorkflow, ENVIRONMENT_VARIABLES: DbmVariableEnvironmentUser[] }> {
        return new Promise(async (resolve, reject) => {
            const response = await this._responsesOfflineServices.doSaveResponseWorkflowAction_ResponseWorkflows(_response);
            const envVarsUser = await this._envVarUserOfflineServices.EvaluateEnvironmentVariableUser(response);
            const resolveDatas = {
                RESPONSE_WORKFLOW: response,
                ENVIRONMENT_VARIABLES: envVarsUser
            };
            resolve(resolveDatas);
        });
    }
}