import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";

function _window(): any {
  // return the global native browser window object
  return window;
}

@Component({
  selector: 'cfc-document-scanner',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CFCDocumentScannerComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-document-scanner.component.html'
})
export class CFCDocumentScannerComponent implements ControlValueAccessor {

  scannedDocument: any = null;
  propagateChange = (_: any) => {};

  constructor(
    private toastService: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private hardwareBridge: HardwareBridgeService
  ) {}

  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.scannedDocument = value;
    }
  }

  scanDocument() {
      this.hardwareBridge.openDocumentScanner()
      .then((result) => {
        this.scannedDocument = result;
        this.propagateChange(result);
        this.changeDetectorRef.detectChanges();
      });
  }

}
