<div *ngIf="viewMode == Command_View.COMMAND_LOADING">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="viewMode == Command_View.COMMAND_LOADING_ERROR">
    <button class="btn btn-block btn-danger" type="button">
        <span class="material-icons">warning</span>
        {{ loadingStatus }}
        <span class="material-icons">warning</span>
    </button>
</div>

<div *ngIf="viewMode == Command_View.COMMAND_LOADED">
    <div style='margin-top: 15px'>
        <table style='border-collapse:collapse; width: 100%'>
            <tbody>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>DATE : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ document.DO_Date | date : 'dd/MM/yyyy' }}</td>
                </tr>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>CLIENT : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ document.DO_Tiers }}</td>
                </tr>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>NUM PIECE : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>#{{ document.DO_Piece }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <ng-container>
            <div style='margin-top: 15px; width: 100%;'>
                <table style='border-collapse:collapse; width: 100%'>
                    <thead>
                        <tr>
                            <th style='border:1px solid black; font-size: 14px;font-family: Calibri; text-align:center'>Article</th>
                            <th *ngIf="doType == 2" style='border:1px solid black; width: 25%; font-size: 14px;font-family: Calibri; text-align:center'>Quantité Commandée</th>
                            <th style='border:1px solid black; width: 25%; font-size: 14px;font-family: Calibri; text-align:center'>{{ doType == 2 ? "Quantité Livrée" : "Quantité Chargée" }}</th>
                            <th style='border:1px solid black; width: 25%; font-size: 14px;font-family: Calibri; text-align:center'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
<!--                    [ngClass]="{'table-danger': isRowIncorrect(ligne), 'table-success': !isRowIncorrect(ligne) }"-->
                        <tr *ngFor="let ligne of document?.LIGNES; let rowIndex = index">
                            <td style='border:1px solid black; font-size: 14px;;font-family: Calibri'>{{ ligne.DL_Design }}</td>
                            <td *ngIf="doType == 2" style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>{{ ligne.DL_Qte != 0 ? ligne.DL_Qte : '' }}</td>
                            <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>
                              <input type="number" class="form-control" [formControl]="gridForms[ligne.cbMarq]" (keyup)="controlMaxValue(ligne)" (keyup)="processGridForm()" style="text-align: center; background:#bdc3c7; font-weight: bold">
                            </td>
                            <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>
                                <button type="button" class="btn btn-primary" (click)="openEmplacementsDialog(ligne)">Voir les emplacements</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</div>
<div style="clear:both">&nbsp;</div>

<div style="text-align: center">
    <button style="margin: 10px;" type="button" class="btn btn-primary" (click)="doScanCode()">Scanner un code Article</button>
</div>
