import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
    DynamicSelectModelConfig,
    DynamicSelectModel,
    DynamicInputModel,
    DynamicInputModelConfig,
  } from "@ng-dynamic-forms/core";

  export interface CfcInputDatePickerModelConfig extends DynamicInputModelConfig {}

  export class CfcInputDatePickerModel extends DynamicInputModel {
    @serializable() readonly type: string = 'CfcInputDatePicker';

    constructor(config: CfcInputDatePickerModelConfig, layout?: DynamicFormControlLayout) {
      super(config, layout);
    }
  }
