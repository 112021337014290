<ng-container [formGroup]="group">
    <cfc-soudotec-scan-emplacement style="display: block;"
      [formControlName]="model.id"
      [id]="bindId ? model.id : null"
      [name]="model.name"
      [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
      [required]="model.required"
      [emplacements]="model.emplacements"
      [noDepot]="model.noDepot"
      [modeScan]="model.modeScan"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)"
      (hasChanged)="changed($event)">
    </cfc-soudotec-scan-emplacement>
    <span class="cfc-error-message" *ngIf="group.dirty && group.controls[model.id].errors?.required">L'emplacement est requis</span>
    <span class="cfc-error-message" *ngIf="group.controls[model.id].errors?.notFound">L'emplacement scanné n'est pas valide</span>
    <span class="cfc-error-message" *ngIf="group.controls[model.id].errors?.notMatched">L'emplacement scanné ne correspond pas au dépot sélectionné</span>
  </ng-container>
