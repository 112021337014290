import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class Model_CfcSoudotecListArticle {
    C0001: string;
    C0002: string;
    label: string;
    value: string;
}

@Injectable({
    providedIn: 'root'
})
export class CfcSoudotecInventoryService {
    mArticles$ = new BehaviorSubject<Model_CfcSoudotecListArticle[]>([]);

    get Articles() {
        return this.mArticles$.value;
    }
    set Articles(value: Model_CfcSoudotecListArticle[]) {
        this.mArticles$.next(value);
    }
}