import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function CustomCfcNoSpaceValidator(control: AbstractControl): ValidationErrors | null {
  let hasError = control.value ? /\s/g.test(control.value as string) : false;
  return hasError ? {NoSpaceValidator: true} : null;
}

export function CustomQueryValidator(val: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let hasError = control.value ? (control.value as string).startsWith("abc") : false;
    return hasError ? {QueryValidator: true} : null;
  };
}
