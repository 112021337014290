<div style="padding: 10px">
    <h2 mat-dialog-title>Modifier Colonne</h2>
    <mat-dialog-content>
        <form>
            <div class="form-group">
                <label>Type de cellule <span class="text-danger">*</span></label>
                <select class="form-control" [(ngModel)]="column.TYPE_CELL" name="TYPE_CELL">
                    <option [value]="input.value" *ngFor="let input of availablesInputForm">{{ input.label }}</option>
                  </select>
            </div>
            <div class="form-group">
                <label>Identifiant <span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="column.IDENTIFIANT" name="IDENTIFIANT" />
            </div>
            <div class="form-group">
                <label>Libellé <span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="column.LIBELLE" name="LIBELLE" />
            </div>
            <div class="form-group">
                <label style="display: flex">
                    <input type="checkbox" id="READONLY" name="READONLY" [(ngModel)]="column.READONLY" value="true">
                    <label class="k-checkbox-label" for="READONLY" style="margin-left: 4px; font-weight: 400">Lecture Seule ?</label>
                </label>
            </div>
            <ng-container *ngIf="column.TYPE_CELL == '100'">
                <div class="form-group">
                    <label>Source de données <span class="text-danger">*</span></label>
                    <select class="form-control" [(ngModel)]="column.DATASOURCE_ID" name="DATASOURCE_ID">
                        <option [value]="ds.value" *ngFor="let ds of datasources">{{ ds.label }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Valeur - Expression</label>
                    <input type="text" class="form-control" [(ngModel)]="column.EXP_VALEUR" name="EXP_VALEUR" />
                </div>
                <div class="form-group">
                    <label>Affichage - Expression</label>
                    <input type="text" class="form-control" [(ngModel)]="column.EXP_AFFICHAGE" name="EXP_AFFICHAGE" />
                </div>
            </ng-container>
            <div class="form-group">
                <label>Type Valeur <span class="text-danger">*</span></label>
                <select class="form-control" [(ngModel)]="column.TYPE_DEFAULT_VALUE" name="TYPE_DEFAULT_VALUE">
                    <option value="0">Constante</option>
                    <option value="1">Calculé</option>
                    <option value="6">Valeur liée</option>
                </select>
            </div>
            <div class="form-group" *ngIf="column.TYPE_DEFAULT_VALUE == 0">
                <label>Valeur par défaut</label>
                <input type="text" class="form-control" [(ngModel)]="column.DEFAULT_VALUE" name="DEFAULT_VALUE" />
            </div>
            <div class="form-group" *ngIf="column.TYPE_DEFAULT_VALUE == 1">
                <label>Expression <span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="column.DEFAULT_VALUE_COMPUTED" name="DEFAULT_VALUE_COMPUTED" />
            </div>
            <ng-container *ngIf="column.TYPE_DEFAULT_VALUE == 6">
                <div class="form-group">
                    <label>Liste déroulante liée <span class="text-danger">*</span></label>
                    <select class="form-control" [(ngModel)]="column.INPUT_DROPDOWN_LINKED_DROPDOWN" name="INPUT_DROPDOWN_LINKED_DROPDOWN">
                        <option [value]="uiC.value" *ngFor="let uiC of uiComponents">{{ uiC.label }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Valeur - Expression <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="column.LINKED_VALUE_EXP" name="LINKED_VALUE_EXP" />
                </div>
            </ng-container>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end" style="display: block">
        <button type="button" class="btn btn-primary default mb-1" (click)="onSubmitAction()">Valider</button>
        <button type="button" class="btn btn-light default mb-1" (click)="onCancelAction()">Annuler</button>
    </mat-dialog-actions>
</div>