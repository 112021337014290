import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from './app-settings';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {

    apiEndPoint = this.onGetAction_APIEndpoint();
    endPoints = this.doInitEndpoints(this.apiEndPoint);
    public $offlineModeObservable: BehaviorSubject<boolean>;


    constructor(private httpClient: HttpClient) {
        var currentAppNetworkMode = parseInt(localStorage.getItem(environment.LS_APP_NETWORK_MODE));
        this.$offlineModeObservable = new BehaviorSubject<boolean>(!isNaN(currentAppNetworkMode) && currentAppNetworkMode == 1);
        this.$offlineModeObservable.subscribe((currentMode: boolean) => {
            localStorage.setItem(environment.LS_APP_NETWORK_MODE, currentMode ? "1" : "0");
        });
    }

    onSaveAction_APIEndpoints(_apiEndpoint_ABW : string){
        //localStorage.setItem(AppSettings.LS_API_ENDPOINT_ABW, _apiEndpoint_ABW);
        localStorage.setItem(AppSettings.LS_API_ENDPOINT_ABW, _apiEndpoint_ABW);
        this.apiEndPoint = this.onGetAction_APIEndpoint();
        this.endPoints = this.doInitEndpoints(this.apiEndPoint);
    }

    onGetAction_APIEndpoint_ABW(){
        var API_ENDPOINT_ABW = localStorage.getItem(AppSettings.LS_API_ENDPOINT_ABW);
        if(API_ENDPOINT_ABW == undefined) return "";
        return API_ENDPOINT_ABW;
    }

    get isOfflineMode() {
        return this.$offlineModeObservable.value == true;
    }

    private onGetAction_APIEndpoint(){
        var API_ENDPOINT = this.onGetAction_APIEndpoint_ABW();
        if(API_ENDPOINT.indexOf(AppSettings.DEV_SERVER_DEFAULT_ABW) > -1) return AppSettings.DEV_SERVER_DEFAULT_ABW + "/";
        return API_ENDPOINT + "/" /*+ AppSettings.SERVER_APP_NAME_ABW + "/"*/;
    }

    private doInitEndpoints(_apiEndPoint : string){
        return {
            // APP
            doGetCurrentAppInfos: _apiEndPoint + "api/App/doGetCurrentAppInfos",
            doDownloadLatestAppVersion: _apiEndPoint + "api/App/doDownloadLatestAppVersion",
            doGetUpdateManifest: _apiEndPoint + "api/App/doGetUpdateManifest",
            // AUTHENTICATION
            ping : _apiEndPoint + "api/ping/doPingABW",
            authenticate : _apiEndPoint + "api/auth/authenticate",
            authenticateUsingToken : _apiEndPoint + "api/auth/authenticateUsingToken",
            // DESIGN WORKFLOWS
            doGetStartupInfosDesignWorkflows_Design : _apiEndPoint + "api/designworkflows/doGetStartupInfosDesignWorkflows_Design",
            doGetWorkflowsAction_Design : _apiEndPoint + "api/designworkflows/doGetWorkflowsAction_Design",
            doSaveWorkflowAction_Design : _apiEndPoint + "api/designworkflows/doSaveWorkflowAction_Design",
            doDeleteWorkflowAction_Design: (_workflowId) => _apiEndPoint + "api/designworkflows/doDeleteWorkflowAction_Design/" + _workflowId,
            doCreateWorkflowStepAction_Design : _apiEndPoint + "api/designworkflows/doCreateWorkflowStepAction_Design",
            doDeleteWorkflowStepAction_Design: (_workflowStepId) => _apiEndPoint + "api/designworkflows/doDeleteWorkflowStepAction_Design/" + _workflowStepId,
            doGetWorkflowStepUiComponentsAction_Design : _apiEndPoint + "api/designworkflows/doGetWorkflowStepUiComponentsAction_Design",
            doSaveWorkflowStepUiComponentAction_Design : _apiEndPoint + "api/designworkflows/doSaveWorkflowStepUiComponentAction_Design",
            doReorderWorkflowStepUiComponentAction_Design : _apiEndPoint + "api/designworkflows/doReorderWorkflowStepUiComponentAction_Design",
            doDeleteWorkflowStepUiComponentAction_Design : _apiEndPoint + "api/designworkflows/doDeleteWorkflowStepUiComponentAction_Design",
            doCreateWorkflowStepDatasourceAction_Design :  _apiEndPoint + "api/designworkflows/doCreateWorkflowStepDatasourceAction_Design",
            doDeleteWorkflowStepDatasourceAction_Design: (_workflowStepDatasourceId) => _apiEndPoint + "api/designworkflows/doDeleteWorkflowStepDatasourceAction_Design/" + _workflowStepDatasourceId,
            doSaveWorkflowStepDatasourceAction_Design :  _apiEndPoint + "api/designworkflows/doSaveWorkflowStepDatasourceAction_Design",
            doBuildWorkflowStepDatasourceAction_Design : _apiEndPoint + "api/designworkflows/doBuildWorkflowStepDatasourceAction_Design",
            // VARIABLE ENVIRONMENT
            doGetStartupInfosVariables_Environment: _apiEndPoint + "api/VariableEnvironment/doGetStartupInfosVariables_Environment",
            doBuildGridVariablesEnvironment_Environment: _apiEndPoint + "api/VariableEnvironment/doBuildGridVariablesEnvironment_Environment",
            doSaveVariableAction_Environment: _apiEndPoint + "api/VariableEnvironment/doSaveVariableAction_Environment",
            doDeleteVariableAction_Environment: (_varEnvID) => _apiEndPoint + "api/VariableEnvironment/doDeleteVariableAction_Environment/" + _varEnvID,
            // AGENT
            doBuildUsersGridActions_Users: _apiEndPoint + "api/User/doBuildUsersGridActions_Users",
            doSaveUserAction_Users: _apiEndPoint + "api/User/doSaveUserAction_Users",
            doDeleteUserAction_Users: (_userId) => _apiEndPoint + "api/User/doDeleteUserAction_Users/" + _userId,
            //  TEAM
            doGetStartupInfosAction_Teams: _apiEndPoint + "api/Team/doGetStartupInfosAction_Teams",
            doBuildTeamsGridActions_Teams: _apiEndPoint + "api/Team/doBuildTeamsGridActions_Teams",
            doSaveTeamAction_Teams: _apiEndPoint + "api/Team/doSaveTeamAction_Teams",
            doDeleteTeamAction_Teams: (_teamID) => _apiEndPoint + "api/Team/doDeleteTeamAction_Teams/" + _teamID,
            // RESPONSE WORKFLOW
            doSubmitResponseWorkflowForValidation: _apiEndPoint + "api/ResponseWorkflow/doSubmitResponseWorkflowForValidation",
            doSaveResponseWorkflowAction_ResponseWorkflows: _apiEndPoint + "api/ResponseWorkflow/doSaveResponseWorkflowAction_ResponseWorkflows",
            doArchiveResponseWorkflowAction_Processing: (_responseId) => _apiEndPoint + "api/ResponseWorkflow/doArchiveResponseWorkflowAction_Processing/" + _responseId,
            doGetResponseWorkflowStepImage: _apiEndPoint + "api/ResponseWorkflow/doGetResponseWorkflowStepImage",
            // PROCESSING WORKFLOW
            doGetStartupInfosAction_Processing: _apiEndPoint + "api/ProcessingWorkflows/doGetStartupInfosAction_Processing",
            doGetAssignedWorkflowsAction_Processing: _apiEndPoint + "api/ProcessingWorkflows/doGetAssignedWorkflowsAction_Processing",
            // VALIDATION WORKFLOW
            doBuildResponsesWorkflowGridAction_ProcessValidation: _apiEndPoint + "api/ResponseWorkflow/doBuildResponsesWorkflowGridAction_ProcessValidation",
            doValidateResponseWorkflowAction_ProcessValidation: _apiEndPoint + "api/ResponseWorkflow/doValidateResponseWorkflowAction_ProcessValidation",
            doRejectResponseWorkflowAction_ProcessValidation: _apiEndPoint + "api/ResponseWorkflow/doRejectResponseWorkflowAction_ProcessValidation",
            // VARIABLE ENVIRONMENT USER
            doGetStartupInfosAction_VariableEnvironmentUser: _apiEndPoint + "api/VariableEnvironmentUser/doGetStartupInfosActions_VariableEnvironmentUser",
            doReinitVariableAction_VariableEnvironmentUser: (_variableEnvUserID) => _apiEndPoint + "api/VariableEnvironmentUser/doReinitVariableAction_VariableEnvironmentUser/" + _variableEnvUserID,
            doSaveValueVariableAction_VariableEnvironmentUser: _apiEndPoint + "api/VariableEnvironmentUser/doSaveValueVariableAction_VariableEnvironmentUser",
            // HISTORY WORKFLOW
            doGetStartupInfosAction_HistoryResponsesWorkflow: _apiEndPoint + "api/ProcessingHistory/doGetStartupInfosAction_HistoryResponsesWorkflow",
            doGetFiltersOptionsAction_HistoryResponsesWorkflow: _apiEndPoint + "api/ProcessingHistory/doGetFiltersOptionsAction_HistoryResponsesWorkflow",
            doGetDetailsResponseAction_HistoryResponseWorkflow: _apiEndPoint + "api/ProcessingHistory/doGetDetailsResponseAction_HistoryResponseWorkflow",
            // OSRM
            doOsrmMapMatchingAction_Osrm: _apiEndPoint + "api/osrm/doOsrmMapMatchingAction_Osrm",
            // DESIGN CIRCUIT
            doGetStartupInfos_Circuit: _apiEndPoint + "api/Circuit/doGetStartupInfosAction_Circuit",
            doSaveCircuit_Circuits: _apiEndPoint + "api/Circuit/doSaveCircuitAction_Circuits",
            doDeleteCircuit_Circuits: _apiEndPoint + "api/Circuit/doDeleteCircuitAction_Circuits",
            doSaveCircuitParams_Circuits: _apiEndPoint + "api/Circuit/doSaveCircuitParamAction_Circuits",
            doDeleteCircuitParams_Circuits: _apiEndPoint + "api/Circuit/doDeleteCircuitParamAction_Circuits",
            // PROCESSING CIRCUIT
            doGetStartupInfos_ProcessingCircuits:  _apiEndPoint + "api/ProcessingCircuits/doGetStartupInfosAction_ProcessingCircuits",
            doGetCircuitsInstancesAction_ProcessingCircuits:  _apiEndPoint + "api/ProcessingCircuits/doGetCircuitsInstancesAction_ProcessingCircuits",
            doInitCircuitInstanceAction_ProcessingCircuits:  _apiEndPoint + "api/ProcessingCircuits/doInitCircuitInstanceAction_ProcessingCircuits",
            doUpdateCircuitInstanceParamAction_ProcessingCircuits:  _apiEndPoint + "api/ProcessingCircuits/doUpdateCircuitInstanceParamAction_ProcessingCircuits",
            // ADMININISTRATION EXPORTS
            doBuildGridWorkflowsResponsesAction_AdministrationExports: _apiEndPoint + "api/administrationexports/doBuildGridWorkflowsResponsesAction_AdministrationExports",
            // DATASOURCE
            doGetStartupInfosDatasource_Datasource: _apiEndPoint + "api/Datasource/doGetStartupInfosDatasource_Datasource",
            doSaveDatasourceAction_Design: _apiEndPoint + "api/Datasource/doSaveDatasourceAction_Design",
            doCreateDatasourceAction_Design: _apiEndPoint + "api/Datasource/doCreateDatasourceAction_Design",
            doBuildDatasourceAction_Design: _apiEndPoint + "api/Datasource/doBuildDatasourceAction_Design",
            doDeleteDatasourceAction_Design: _apiEndPoint + "api/Datasource/doDeleteDatasourceAction_Design",
            // DECLENCHEUR
            doGetStartupInfosDeclencheurAction_Declencheur: _apiEndPoint + "api/Declencheur/doGetStartupInfosDeclencheurAction_Declencheur",
            doGetDeclencheursOfWorkflowAction_Declencheur: _apiEndPoint + "api/Declencheur/doGetDeclencheursOfWorkflowAction_Declencheur",
            doGetDeclencheursOfRunnerAction_Declencheur: _apiEndPoint + "api/Declencheur/doGetDeclencheursOfRunnerAction_Declencheur",
            doSaveDeclencheurAction_Declencheur: _apiEndPoint + "api/Declencheur/doSaveDeclencheurAction_Declencheur",
            doDeleteDeclencheurAction_Declencheur: _apiEndPoint + "api/Declencheur/doDeleteDeclencheurAction_Declencheur",
            // REDIRECTIONS
            doSaveWorkflowStepRedirectionAction_Design: _apiEndPoint + "api/designworkflows/doSaveWorkflowStepRedirectionAction_Design",
            doDeleteWorkflowStepRedirectionAction_Design: _apiEndPoint + "api/designworkflows/doDeleteWorkflowStepRedirectionAction_Design",
            // SYNCHRONISATIONS
            doBuildSyncGridAction_Synchronisation: _apiEndPoint + "api/Synchronisation/doBuildSyncGridAction_Synchronisation",
            doSynchronizeDatasAction_Synchronisation:  _apiEndPoint + "api/Synchronisation/doSynchronizeDatasAction_Synchronisation",
            // INFOS SOCIETE
            doGetCompany_Company: _apiEndPoint + "api/Company/doGetCompany_Company",
            doSaveParamsCompany_Company: _apiEndPoint + "api/Company/doSaveParamsCompany_Company",
            // APPCADENCIER APIs
            doGetBonCommandeAction_AppCadencier: _apiEndPoint + "api/AppCadencier/doGetBonCommandeAction_AppCadencier",
            // SAGE DOCUMENT API
            doGetSageDocumentAction_SageDocument: _apiEndPoint + "api/SageDocument/doGetSageDocumentAction_SageDocument",
        };
    }
}
