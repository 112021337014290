import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../views/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UnauthorizedComponent } from '../views/unauthorized/unauthorized.component';
import { SettingsApiComponent } from '../views/settings-api/settings-api.component';
import { ConnectorSettingsAPIService } from '../views/settings-api/connector-settings-api.service';
import { ReactiveFormsModule, FormsModule, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';


import { MyDynamicCustomFormControlComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-test/dynamic-cfc-test.component';
import { MyCustomFormControlComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-test/cfc-test.component';

import { DynamicCfcImageCardCheckListComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-image-card-checklist/dynamic-cfc-image-card-checklist.component';
import { CfcImageCardCheckListComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-image-card-checklist/cfc-image-card-checklist.component';

import { DYNAMIC_VALIDATORS, DynamicFormsCoreModule, Validator, ValidatorFactory, DYNAMIC_MATCHER_PROVIDERS, DYNAMIC_FORM_CONTROL_MAP_FN, DynamicFormControlModel, DynamicFormControl } from "@ng-dynamic-forms/core";
import { DynamicFormsKendoUIModule } from '@ng-dynamic-forms/ui-kendo';
import { customValidator, customDateRangeValidator, customForbiddenValidator, customAsyncFormGroupValidator } from "src/app/views/app/app.validators";

import { CFCSignaturePadComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-signature-pad/cfc-signature-pad.component';
import { DynamicCfcSignaturePadComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-signature-pad/dynamic-cfc-signature-pad.component';

import { CFCBarcodeScannerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-barcode-scanner/cfc-barcode-scanner.component';
import { DynamicCfcBarcodeScannerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-barcode-scanner/dynamic-cfc-barcode-scanner.component';

import { CFCDocumentScannerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-document-scanner/cfc-document-scanner.component';
import { DynamicCfcDocumentScannerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-document-scanner/dynamic-cfc-document-scanner.component';
import { DynamicCFCTextAutocompleteComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-text-autocomplete/dynamic-cfc-text-autocomplete.component';
import { CfcInputLocationGPSComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-locationgps/cfc-input-locationgps.component';
import { DynamicCfcInputLocationGPSComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-locationgps/dynamic-cfc-input-locationgps.component';
import { CfcChronometerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-chronometer/cfc-chronometer.component';
import { DynamicCfcChronometerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-chronometer/dynamic-cfc-chronometer.component';
import { CFCTextAutocompleteComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-text-autocomplete/cfc-text-autocomplete.component';
import { TextInputAutocompleteModule } from 'angular-text-input-autocomplete';
import { CustomCfcNoSpaceValidator, CustomQueryValidator } from '../views/app/zcomponents-lib/dynamic-form-control-matchers/custom-cfc-validators';
import { QueryBuilderModule } from 'angular2-query-builder';
import { CustomKendoFilterComponent } from '../views/app/zcomponents-lib/custom-kendo-filter/custom-kendo-filter.component';
import { NgxChronometerModule } from 'ngx-chronometer';
import { CfcInputDropdownComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-dropdown/cfc-input-dropdown.component';
import { DynamicCfcInputDropdownComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-dropdown/dynamic-cfc-input-dropdown.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CfcInputTextboxComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-textbox/cfc-input-textbox.component';
import { DynamicCfcInputTextboxComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-textbox/dynamic-cfc-input-textbox.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { CfcInputNumericTextboxComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-numerictextbox/cfc-input-numerictextbox.component';
import { DynamicCfcInputNumericTextboxComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-numerictextbox/dynamic-cfc-input-numerictextbox.component';
import { CfcInputTextareaComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-textarea/cfc-input-textarea.component';
import { DynamicCfcInputTextareaComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-textarea/dynamic-cfc-input-textarea.component';
import { CfcInputDatePickerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-datepicker/cfc-input-datepicker.component';
import { DynamicCfcInputDatePickerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-datepicker/dynamic-cfc-input-datepicker.component';
import { CfcInputFileComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-file/cfc-input-file.component';
import { DynamicCfcInputFileComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-file/dynamic-cfc-input-file.component';
import { CfcInputGridComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-grid/cfc-input-grid.component';
import { DynamicCfcInputGridComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-grid/dynamic-cfc-input-grid.component';
import { CfcInputGridColumnsPropertyComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-grid-columns-property/cfc-input-grid-columns-property.component';
import { DynamicCfcInputGridColumnsPropertyComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-grid-columns-property/dynamic-cfc-input-grid-columns-property.component';
import { CfcFamocoLaserComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-famoco-laser/cfc-famoco-laser.component';
import { DynamicCfcFamocoLaserComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-famoco-laser/dynamic-cfc-famoco-laser.component';
import { CfcSoudotecInventoryComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-inventory/cfc-soudotec-inventory.component';
import { DynamicCfcSoudotecInventoryComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-inventory/dynamic-cfc-soudotec-inventory.component';
import { CfcSoudotecInventoryInputDialogComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-inventory/cfc-soudotec-inventory-input.dialog';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { CfcInputGridColumnsPropertyDialogComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-input-grid-columns-property/cfc-input-grid-columns-property-dialog.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CfcFamocoFingerprintComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-famoco-fingerprint/cfc-famoco-fingerprint.component';
import { DynamicCfcFamocoFingerprintComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-famoco-fingerprint/dynamic-cfc-famoco-fingerprint.component';
import { CfcMibemCommandComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-mibem-command/cfc-mibem-command.component';
import { DynamicCfcMibemCommandComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-mibem-command/dynamic-cfc-mibem-command.component';
import { CfcMibemCommandGridInputComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-mibem-command-gridinput/cfc-mibem-command-gridinput.component';
import { DynamicCfcMibemCommandGridInputComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-mibem-command-gridinput/dynamic-cfc-mibem-command-gridinput.component';
import { CfcSoudotecCommandGridInputComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-command-gridinput/cfc-soudotec-command-gridinput.component';
import { DynamicCfcSoudotecCommandGridInputComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-command-gridinput/dynamic-cfc-soudotec-command-gridinput.component';
import { CfcSoudotecCommandScanItemComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-command-gridinput/cfc-soudotec-command-scan-item.component';
import { MomentModule } from 'ngx-moment';
import { CfcMapPickerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-map-picker/cfc-map-picker.component';
import { DynamicCfcMapPickerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-map-picker/dynamic-cfc-map-picker.component';
import {DynamicCfcCameraComponent} from "../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-camera/dynamic-cfc-camera.component";
import {CfcCameraComponent} from "../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-camera/cfc-camera.component";
import {CfcSoudotecBarcodesVerificationComponent} from "../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-barcodes-verification/cfc-soudotec-barcodes-verification.component";
import {DynamicCfcSoudotecBarcodesVerificationComponent} from "../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-barcodes-verification/dynamic-cfc-soudotec-barcodes-verification.component";
import {HighlightSearch} from "./highlight-search.pipe";
import { NgSelectModule } from '@ng-select/ng-select';
import { CfcSoudotecBarcodesVerificationDialogComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-barcodes-verification/cfc-soudotec-barcodes-verification-dialog/cfc-soudotec-barcodes-verification-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { CfcSoudotecScanEmplacementComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-scan-emplacement/cfc-soudotec-scan-emplacement.component';
import { DynamicCfcSoudotecScanEmplacementComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-scan-emplacement/dynamic-cfc-soudotec-scan-emplacement.component';
import { CfcCardPickerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-card-picker/cfc-card-picker.component';
import { DynamicCfcCardPickerComponent } from '../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-card-picker/dynamic-cfc-card-picker.component';
import { CfcSoudotecCommandEmplacementsDialogComponent } from "../views/app/zcomponents-lib/workflow-execution/zcustom-controls/cfc-soudotec-command-gridinput/cfc-soudotec-command-emplacements-dialog/cfc-soudotec-command-emplacements-dialog.component";

@NgModule({
  declarations: [
    ErrorComponent, UnauthorizedComponent, SettingsApiComponent, HighlightSearch,

    MyDynamicCustomFormControlComponent,
    MyCustomFormControlComponent,
    DynamicCfcImageCardCheckListComponent,
    CfcImageCardCheckListComponent,
    CFCSignaturePadComponent,
    DynamicCfcSignaturePadComponent,

    CFCBarcodeScannerComponent,
    DynamicCfcBarcodeScannerComponent,

    CFCDocumentScannerComponent,
    DynamicCfcDocumentScannerComponent,

    CFCTextAutocompleteComponent,
    DynamicCFCTextAutocompleteComponent,

    CfcInputLocationGPSComponent,
    DynamicCfcInputLocationGPSComponent,

    CfcChronometerComponent,
    DynamicCfcChronometerComponent,

    CfcInputDropdownComponent,
    DynamicCfcInputDropdownComponent,

    CfcInputTextboxComponent,
    DynamicCfcInputTextboxComponent,

    CfcInputNumericTextboxComponent,
    DynamicCfcInputNumericTextboxComponent,

    CfcInputTextareaComponent,
    DynamicCfcInputTextareaComponent,

    CfcInputDatePickerComponent,
    DynamicCfcInputDatePickerComponent,

    CfcInputFileComponent,
    DynamicCfcInputFileComponent,

    CfcInputGridComponent,
    DynamicCfcInputGridComponent,

    CfcInputGridColumnsPropertyComponent,
    DynamicCfcInputGridColumnsPropertyComponent,
    CfcInputGridColumnsPropertyDialogComponent,

    CfcFamocoLaserComponent,
    DynamicCfcFamocoLaserComponent,

    CfcSoudotecInventoryComponent,
    DynamicCfcSoudotecInventoryComponent,
    CfcSoudotecInventoryInputDialogComponent,

    CustomKendoFilterComponent,

    CfcFamocoFingerprintComponent,
    DynamicCfcFamocoFingerprintComponent,

    CfcMibemCommandComponent,
    DynamicCfcMibemCommandComponent,

    CfcMibemCommandGridInputComponent,
    DynamicCfcMibemCommandGridInputComponent,

    CfcSoudotecCommandGridInputComponent,
    DynamicCfcSoudotecCommandGridInputComponent,
    CfcSoudotecCommandScanItemComponent,
    CfcSoudotecCommandEmplacementsDialogComponent,

    CfcMapPickerComponent,
    DynamicCfcMapPickerComponent,

    CfcCameraComponent,
    DynamicCfcCameraComponent,

    CfcSoudotecBarcodesVerificationComponent,
    DynamicCfcSoudotecBarcodesVerificationComponent,
    CfcSoudotecBarcodesVerificationDialogComponent,

    CfcSoudotecScanEmplacementComponent,
    DynamicCfcSoudotecScanEmplacementComponent,

    CfcCardPickerComponent,
    DynamicCfcCardPickerComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormsCoreModule.forRoot(),
    DynamicFormsKendoUIModule,
    TextInputAutocompleteModule,
    QueryBuilderModule,
    NgxChronometerModule,
    DropDownsModule,
    InputsModule,
    LayoutModule,
    IntlModule,
    DateInputsModule,
    IndicatorsModule,
    MatTableModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MomentModule,
    NgSelectModule
  ],
  entryComponents: [
    MyDynamicCustomFormControlComponent,
    DynamicCfcImageCardCheckListComponent,
    DynamicCfcSignaturePadComponent,
    DynamicCfcBarcodeScannerComponent,
    DynamicCfcDocumentScannerComponent,
    DynamicCFCTextAutocompleteComponent,
    DynamicCfcInputLocationGPSComponent,
    DynamicCfcChronometerComponent,
    DynamicCfcInputDropdownComponent,
    DynamicCfcInputTextboxComponent,
    DynamicCfcInputTextareaComponent,
    DynamicCfcInputDatePickerComponent,
    DynamicCfcInputFileComponent,
    DynamicCfcInputGridComponent,
    DynamicCfcInputGridColumnsPropertyComponent,
    CfcInputGridColumnsPropertyDialogComponent,
    DynamicCfcFamocoLaserComponent,
    DynamicCfcSoudotecInventoryComponent,
    CfcSoudotecInventoryInputDialogComponent,
    DynamicCfcFamocoFingerprintComponent,
    DynamicCfcMibemCommandComponent,
    DynamicCfcMibemCommandGridInputComponent,
    DynamicCfcSoudotecCommandGridInputComponent,
    CfcSoudotecCommandScanItemComponent,
    DynamicCfcMapPickerComponent,
    DynamicCfcCameraComponent,
    DynamicCfcSoudotecBarcodesVerificationComponent,
    DynamicCfcSoudotecScanEmplacementComponent,
    DynamicCfcCardPickerComponent,
    CfcSoudotecCommandEmplacementsDialogComponent
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    ErrorComponent,
    UnauthorizedComponent,
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QueryBuilderModule,
    CustomKendoFilterComponent,
    HighlightSearch
  ],
  providers : [
    ConnectorSettingsAPIService,
    {
      provide: NG_VALIDATORS,
      useValue: customValidator,
      multi: true
    },
    {
        provide: NG_VALIDATORS,
        useValue: customDateRangeValidator,
        multi: true
    },
    {
        provide: NG_ASYNC_VALIDATORS,
        useValue: customAsyncFormGroupValidator,
        multi: true
    },
    {provide: NG_VALIDATORS, useValue: CustomCfcNoSpaceValidator, multi: true},
    {provide: NG_VALIDATORS, useValue: CustomQueryValidator, multi: true},
    {
        provide: DYNAMIC_VALIDATORS,
        useValue: new Map<string, Validator | ValidatorFactory>([
            ["customValidator", customValidator],
            ["customDateRangeValidator", customDateRangeValidator],
            ["customForbiddenValidator", customForbiddenValidator],
            ["customAsyncFormGroupValidator", customAsyncFormGroupValidator],
            ["CustomCfcNoSpaceValidator", CustomCfcNoSpaceValidator],
            ["CustomQueryValidator", CustomQueryValidator]
        ])
    },
    {
      provide: DYNAMIC_FORM_CONTROL_MAP_FN,
      useValue: (model: DynamicFormControlModel): Type<DynamicFormControl> | null  => {

        switch (model.type) {
          case 'MY-CUSTOM': return MyDynamicCustomFormControlComponent;
          case 'CfcImageCardCheckList': return DynamicCfcImageCardCheckListComponent;
          case 'CfcSignaturePad': return DynamicCfcSignaturePadComponent;
          case 'CfcBarcodeScanner': return DynamicCfcBarcodeScannerComponent;
          case 'CfcDocumentScanner': return DynamicCfcDocumentScannerComponent;
          case 'CfcTextAutocomplete': return DynamicCFCTextAutocompleteComponent;
          case 'CfcInputLocationGPS': return DynamicCfcInputLocationGPSComponent;
          case 'CfcChronometer': return DynamicCfcChronometerComponent;
          case 'CfcInputDropdown': return DynamicCfcInputDropdownComponent;
          case 'CfcInputTextbox': return DynamicCfcInputTextboxComponent;
          case 'CfcInputNumericTextbox': return DynamicCfcInputNumericTextboxComponent;
          case 'CfcInputTextarea': return DynamicCfcInputTextareaComponent;
          case 'CfcInputDatePicker': return DynamicCfcInputDatePickerComponent;
          case 'CfcInputFile': return DynamicCfcInputFileComponent;
          case 'CfcInputGrid': return DynamicCfcInputGridComponent;
          case 'CfcInputGridColumnsProperty': return DynamicCfcInputGridColumnsPropertyComponent;
          case 'CfcFamocoLaser': return DynamicCfcFamocoLaserComponent;
          case 'CfcSoudotecInventory': return DynamicCfcSoudotecInventoryComponent;
          case 'CfcFamocoFingerprint': return DynamicCfcFamocoFingerprintComponent;
          case 'CfcMibemCommand': return DynamicCfcMibemCommandComponent;
          case 'CfcMibemCommandGridInput': return DynamicCfcMibemCommandGridInputComponent;
          case 'CfcSoudotecCommandGridInput': return DynamicCfcSoudotecCommandGridInputComponent;
          case 'CfcMapPicker': return DynamicCfcMapPickerComponent;
          case 'CfcCamera': return DynamicCfcCameraComponent;
          case 'CfcSoudotecBarcodesVerification': return DynamicCfcSoudotecBarcodesVerificationComponent;
          case 'CfcSoudotecScanEmplacement': return DynamicCfcSoudotecScanEmplacementComponent;
          case 'CfcCardPicker': return DynamicCfcCardPickerComponent;
          }
       }
    },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {}
