<div style="display:flex;flex-direction:row; justify-content: space-between;gap: 20px;">
    <div style="flex: 1" *ngFor="let opt of options; let rowIndex = index">
        <a href="javascript:;" (click)="doSelectOption(opt.value)" style="height: 100%">
            <div class="card" [ngClass]="{'active': value === opt.value}" style="height: 100%">
                <div class="card-body">
                    <div class="text-center">
                        <div class="mb-2">
                            <div class="glyph-icon" [ngClass]="{'simple-icon-check': value === opt.value, 'simple-icon-minus': value !== opt.value}" style="font-size:26px"></div>
                        </div>
                        <p class="list-item-heading mb-1">{{ opt.label }}</p>
                        <p class="mb-4 text-muted text-small">{{ opt.value }}</p>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>