import { Injectable } from "@angular/core";
import { AbsGrid, DbmDatasource } from "../../views/app/app.models";
import { UnitOfWorkOfflineDao } from "../daos/unitofwork-offline.dao";

@Injectable()
export class DatasourcesOfflineService {
    constructor(private unitOfWork: UnitOfWorkOfflineDao) { }

    doBuildGridDatasourceAction_Datasource(_id: number): Promise<AbsGrid> {
        return new Promise((resolve, reject) => {
            this.unitOfWork.datasourcesDao.doGetDatasourceAction(_id)
            .then((_datasource: DbmDatasource) => {
                resolve(_datasource !== undefined ? _datasource.ABS_GRID : new AbsGrid());
            }, err => {
                console.log(err);
                reject(err);
            });
        });
    }
}