import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as L from 'leaflet';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";

@Component({
  selector: 'cfc-map-picker',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcMapPickerComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-map-picker.component.html',
  styles: ['#map { height: 480px; width: 100%; }']
})
export class CfcMapPickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  map: any;
  provider: any;
  search: GeoSearchControl;
  searchMarker: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private hardwareBridge: HardwareBridgeService,
  ) { }

  ngOnInit() {
    this.propagateChange(null);
  }

  ngAfterViewInit() {
    this.initMap();
    this.getCurrentGeolocation().then((location: { longitude: number, latitude: number }) => {
        this.goToLocation(location.latitude, location.longitude);
    });
  }

  initMap() {
    document.getElementById("contain-map").innerHTML = `<div id='map' style='width: 100%; height: 350px;'></div>`;
    this.map = L.map('map').setView([5.320357, -4.016107], 14);

    L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
    this.provider = new OpenStreetMapProvider();
    this.search = new GeoSearchControl({
      provider: this.provider,
      style: 'bar',
      showMarker: true,
      autoClose: true,
      marker: {
        // optional: L.Marker    - default L.Icon.Default
        icon: L.icon({
          iconUrl: 'assets/leaflet/marker-icon.png',
          shadowUrl: 'assets/leaflet/marker-shadow.png'
        }),
        draggable: true,
      },
      keepResult: true
    });
    this.map.addControl(this.search);


    this.map.on('geosearch/showlocation', (e) => {
      const lat = e.location.y;
      const lng = e.location.x;
      this.writeValue(`${lat};${lng}`);
    });

    this.map.on('geosearch/marker/dragend', (e) => {
      const lat = e.location.lat;
      const lng = e.location.lng;
      this.writeValue(`${lat};${lng}`);
    });
  }

  searchMap(keyword) {
    this.provider.search({ query: keyword }).then((results) => {
      if (results.length > 0) {
        const lat = results[0].y;
        const lng = results[0].x;
        this.writeValue(`${lat};${lng}`);

        if (!this.searchMarker) {
          this.searchMarker = this.search.addMarker(results[0]);
        }
        var newLatLng = new L.LatLng(lat, lng);
        this.searchMarker.setLatLng(newLatLng);
        this.map.panTo(newLatLng);
      }
    });
  }

  goToLocation(lat: number, lng: number) {
    if (!this.searchMarker) {
      this.searchMarker = this.search.addMarker({ y: lat, x: lng, label: 'test' });
    }
    const newLatLng = new L.LatLng(lat, lng);
    this.searchMarker.setLatLng(newLatLng);
    this.map.panTo(newLatLng);
    this.writeValue(`${lat};${lng}`);
  }

  getCurrentGeolocation() {
    return new Promise((resolve, reject) => {
      //const geolocationPromise = this.androidService.getGeolocationPoint();
      const geolocationPromise = this.hardwareBridge.getGeolocationPoint();
      geolocationPromise.then((location: { longitude: number, latitude: number }) => {
        resolve(location);
      }, err => {
        console.log(err);
        reject(err);
      });
    })
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.propagateChange(value);
      this.changeDetectorRef.detectChanges();
    }
  }

  onValueChanged(value: number) {
    this.propagateChange(value);
  }

}
