import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";

function _window(): any {
  // return the global native browser window object
  return window;
}

@Component({
  selector: 'cfc-barcode-scanner',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CFCBarcodeScannerComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-barcode-scanner.component.html'
})
export class CFCBarcodeScannerComponent implements ControlValueAccessor {

  scannedCode: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private hardwareBridge: HardwareBridgeService
  ) { }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.scannedCode = value;
      this.propagateChange(this.scannedCode);
      this.changeDetectorRef.detectChanges();
    }
  }

  scanCode() {
    this.hardwareBridge.openCodeScanner().then((result) => {
      this.scannedCode = result;
      this.writeValue(this.scannedCode);
    });
  }

}
