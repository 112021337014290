import { Injectable } from "@angular/core";
import { UnitOfWorkOfflineDao } from "../daos/unitofwork-offline.dao";
import { DbmResponseWorkflow, DbmResponseWorkflowStep, DbmWorkflow, StartupInfos_ProcessingWorkflows } from '../../views/app/app.models';

@Injectable()
export class WorkflowsOfflineService {
    constructor(private unitOfWork: UnitOfWorkOfflineDao) { }

    doGetStartupInfosAction_Processing(): Promise<StartupInfos_ProcessingWorkflows> {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.unitOfWork.workflowsDao.doGetWorkflowsAction(),
                this.unitOfWork.datasourcesDao.doGetDatasourcesAction(),
            ]).then((results) => {
                const startupInfos: StartupInfos_ProcessingWorkflows = {
                    mWorkflows: results[0],
                    mDatasources: results[1],
                    mPausedResponsesWorkflows: [],
                    mValidatingResponsesWorkflows: []
                };
                resolve(startupInfos);
            }, err => {
                console.log(err);
                reject(err);
            });
        });
    }

    doGetAssignedWorkflowsAction_Processing(): Promise<DbmWorkflow[]> {
        return new Promise((resolve, reject) => {
            this.unitOfWork.workflowsDao.doGetWorkflowsAction()
            .then((_workflows: DbmWorkflow[]) => {
                resolve(_workflows);
            }, err => {
                console.log(err);
                reject(err);
            });
        });
    }

  doCountWorkflowsAction_Processing(): number {
      return this.unitOfWork.workflowsDao.doCountWorkflowsAction();
  }
}
