<div *ngIf="viewMode == Command_View.COMMAND_LOADING">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="viewMode == Command_View.COMMAND_LOADING_ERROR">
    <button class="btn btn-block btn-danger" type="button">
        <span class="material-icons">warning</span> 
        {{ loadingStatus }}
        <span class="material-icons">warning</span> 
    </button>
</div>

<div *ngIf="viewMode == Command_View.COMMAND_LOADED">
    <div style='margin-top: 15px'>
        <table style='border-collapse:collapse; width: 100%'>
            <tbody>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>DATE : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.DATE_CREATION }}</td>
                </tr>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>CLIENT : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.NOM_CLIENT + ' (' + bonCommande.NOM_LIEU_LIVRAISON + ')' }}</td>
                </tr>
                <tr>
                    <td style='width: 20%;'></td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>#{{ bonCommande.NUM_BC }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style='width: 48%; float:left;'>
    <ng-container *ngFor="let famille of bonCommande.FAMILLES; let loopFamilleIndex = index">
        <ng-container *ngIf="loopFamilleIndex % 2 == 0">
                <div style='margin-top: 15px; width: 100%;'>
                    <table style='border-collapse:collapse; width: 100%'> 
                        <thead style='background: #B4C6E7'>
                            <tr>
                                <th style='border:1px solid black; width: 70%; font-size: 14px;font-family: Calibri; text-align:center'>{{ famille.NOM_FAMILLE }}</th>
                                <th style='border:1px solid black; width: 15%; font-size: 14px;font-family: Calibri; text-align:center'>Quantité</th>
                                <th style='border:1px solid black; width: 15%; font-size: 14px;font-family: Calibri; text-align:center'>Gratuit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ligne of famille.ARTICLES">
                                <td style='border:1px solid black; font-size: 14px;;font-family: Calibri'>{{ ligne.AR_Design }}</td>
                                <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>{{ ligne.QTE_COMMANDE_STR != 0 ? ligne.QTE_COMMANDE_STR : '' }}</td>
                                <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>{{ ligne.QTE_GRATUIT_STR != 0 ? ligne.QTE_GRATUIT_STR : '' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div style='width: 48%; float:right;'>
    <ng-container *ngFor="let famille of bonCommande.FAMILLES; let loopFamilleIndex = index">
        <ng-container *ngIf="loopFamilleIndex % 2 != 0">
                <div style='margin-top: 15px; width: 100%;'>
                    <table style='border-collapse:collapse; width: 100%'>
                        <thead style='background: #B4C6E7'>
                            <tr>
                                <th style='border:1px solid black; width: 70%; font-size: 14px;font-family: Calibri; text-align:center'>{{ famille.NOM_FAMILLE }}</th>
                                <th style='border:1px solid black; width: 15%; font-size: 14px;font-family: Calibri; text-align:center'>Quantité</th>
                                <th style='border:1px solid black; width: 15%; font-size: 14px;font-family: Calibri; text-align:center'>Gratuit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ligne of famille.ARTICLES">
                                <td style='border:1px solid black; font-size: 14px;;font-family: Calibri'>{{ ligne.AR_Design }}</td>
                                <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>{{ ligne.QTE_COMMANDE_STR != 0 ? ligne.QTE_COMMANDE_STR : '' }}</td>
                                <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>{{ ligne.QTE_GRATUIT_STR != 0 ? ligne.QTE_GRATUIT_STR : '' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div style='margin-top: 15px; width: 48%; float:right;'>
        <table style='border-collapse:collapse; width: 100%'>
            <tbody>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>VOLUME COMMANDE : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ (VOLUME_TOTAL + VOLUME_REMISE) }}</td>
                </tr>
            </tbody>
        </table>
        <table style='margin-top: 15px; border-collapse:collapse; width: 100%'>
            <tbody>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS MIB : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.POIDS_BC_MIB }}</td>
                </tr>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS MIBEM : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.POIDS_BC_MIBEM }}</td>
                </tr>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS AGENA : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.POIDS_BC_AGENA }}</td>
                </tr>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS GRATUITS : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ POIDS_BC_REMISE }}</td>
                </tr>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS TOTAL : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ POIDS_BC }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style='margin-top: 15px; width: 48%; float:right;'>
        <table style='border-collapse:collapse; width: 100%'>
            <thead>
                <tr>
                    <td style='border:1px solid black; font-size: 14px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>COMMENTAIRE : </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.COMMENTAIRE }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div style="clear:both">&nbsp;</div>