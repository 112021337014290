import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormControlComponent, DynamicFormControlCustomEvent, DynamicFormLayout, DynamicFormLayoutService, DynamicFormValidationService } from '@ng-dynamic-forms/core';
import { CfcSoudotecInventoryComponent } from './cfc-soudotec-inventory.component';
import { CfcSoudotecInventoryModel } from './cfc-soudotec-inventory.model'
import { CfcSoudotecInventoryService } from './cfc-soudotec-inventory.service';

@Component({
    selector: 'dynamic-cfc-soudotec-inventory',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dynamic-cfc-soudotec-inventory.component.html',
})
export class DynamicCfcSoudotecInventoryComponent extends DynamicFormControlComponent implements OnInit {

    @Input() bindId: boolean = true;
    @Input() group: FormGroup;
    //@Input() layout: DynamicFormLayout;
    @Input() model: CfcSoudotecInventoryModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild(CfcSoudotecInventoryComponent) myCustomFormControlComponent: CfcSoudotecInventoryComponent;

    constructor(
        protected layoutService: DynamicFormLayoutService,
        protected validationService: DynamicFormValidationService,
        private _cfcSoudotecInventoryService: CfcSoudotecInventoryService
    ) {

        super(layoutService, validationService);
    }

    ngOnInit() {
        this._cfcSoudotecInventoryService.Articles = this.model.datas;
    }
}
