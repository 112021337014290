import {
  DynamicFileUploadModelConfig,
  DynamicFileUploadModel,
  serializable,
  DynamicFormControlLayout,
  DynamicInputModelConfig,
  DynamicInputModel,
  DynamicSelectModelConfig,
  DynamicSelectModel,
} from "@ng-dynamic-forms/core";

export interface CfcSoudotecBarcodesVerificationModelConfig extends DynamicInputModelConfig {
  DoDomaine: number;
  DoType: number;
  DoPiece: string;
}

export class CfcSoudotecBarcodesVerificationModel extends DynamicInputModel {
  @serializable() readonly type: string = 'CfcSoudotecBarcodesVerification';
  DoDomaine: number;
  DoType: number;
  DoPiece: string;

  constructor(config: CfcSoudotecBarcodesVerificationModelConfig, layout?: DynamicFormControlLayout) {
    super(config, layout);
    this.DoPiece = config.DoPiece;
    this.DoDomaine = config.DoDomaine;
    this.DoType = config.DoType;
  }
}

export class Model_SoudotecBarcodesVerification {
  REFERENCE: string;
  LIBELLE: string;
  ARTICLE_BARCODE: string;
  SCANNED_BARCODE: string;
  STATUS: Enum_SoudotecBarcodesVerificationStatus;
}

export enum Enum_SoudotecBarcodesVerificationStatus {
  CORRESPONDANCE_OK = 0,
  CORRESPONDANCE_NON_OK = 1,
  AUCUNE_CORRESPONDANCE = 2
}

export const Labels_SoudotecBarcodesVerificationStatus: any = {
  0: 'Correspondance OK',
  1: 'Aucune correspondance trouvée',
  2: 'Le code barres scanné ne correspond pas'
};