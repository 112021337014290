<div *ngIf="viewMode == Command_View.COMMAND_LOADING">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="viewMode == Command_View.COMMAND_LOADING_ERROR">
    <button class="btn btn-block btn-danger" type="button">
        <span class="material-icons">warning</span>
        {{ loadingStatus }}
        <span class="material-icons">warning</span>
    </button>
</div>

<div *ngIf="viewMode == Command_View.COMMAND_LOADED">
    <div style='margin-top: 15px'>
        <table style='border-collapse:collapse; width: 100%'>
            <tbody>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>DATE : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.DATE_CREATION }}</td>
                </tr>
                <tr>
                    <td style='width: 20%;border:1px solid black; font-size: 13px; width:150px; text-align: center;font-family: Calibri'>CLIENT : </td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.NOM_CLIENT + ' (' + bonCommande.NOM_LIEU_LIVRAISON + ')' }}</td>
                </tr>
                <tr>
                    <td style='width: 20%;'></td>
                    <td style='width: 80%;border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>#{{ bonCommande.NUM_BC }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <div class="btn-group" role="group" style="margin-right: 10px; margin-bottom: 15px; float: right">
          <button type="button" class="btn btn-secondary" [ngClass]="{'active': !onlyFilledRowsFilterOn}" (click)="applyFilterRows(false)"><i class="glyph-icon simple-icon-list"></i> Toutes les lignes</button>
          <button type="button" class="btn btn-secondary" [ngClass]="{'active': onlyFilledRowsFilterOn}" (click)="applyFilterRows(true)"><i class="glyph-icon simple-icon-layers"></i> Lignes commandées</button>
        </div>
        <ng-container *ngFor="let famille of filteredRows; let loopFamilleIndex = index">
            <div style='margin-top: 15px; width: 100%;'>
                <table style='border-collapse:collapse; width: 100%'>
                    <thead style='background: #B4C6E7'>
                        <tr>
                            <th style='border:1px solid black; font-size: 14px;font-family: Calibri; text-align:center'>{{ famille.NOM_FAMILLE }}</th>
                            <th style='border:1px solid black; width: 10%; font-size: 14px;font-family: Calibri; text-align:center'>Quantité Commandée</th>
                            <th style='border:1px solid black; width: 10%; font-size: 14px;font-family: Calibri; text-align:center'>Gratuit Commandée</th>
                            <th style='border:1px solid black; width: 10%; font-size: 14px;font-family: Calibri; text-align:center'>Quantité Chargée</th>
                            <th style='border:1px solid black; width: 10%; font-size: 14px;font-family: Calibri; text-align:center'>Gratuit Chargée</th>
                            <th style='border:1px solid black; width: 15%; font-size: 14px;font-family: Calibri; text-align:center'>Motif de Modification</th>
                            <th style='border:1px solid black; width: 15%; font-size: 14px;font-family: Calibri; text-align:center'>Commentaire</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [ngClass]="{'table-danger': isRowIncorrect(ligne, gridForms[famille.REF_FAMILLE][ligne.AR_Ref]), 'table-success': !isRowIncorrect(ligne, gridForms[famille.REF_FAMILLE][ligne.AR_Ref]) && (ligne.QTE_COMMANDE_STR != 0 || ligne.QTE_GRATUIT_STR != 0) }" *ngFor="let ligne of famille.ARTICLES">
                            <td style='border:1px solid black; font-size: 14px;;font-family: Calibri'>{{ ligne.AR_Design }}</td>
                            <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>{{ ligne.QTE_COMMANDE_STR != 0 ? ligne.QTE_COMMANDE_STR : '' }}</td>
                            <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>{{ ligne.QTE_GRATUIT_STR != 0 ? ligne.QTE_GRATUIT_STR : '' }}</td>
                            <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'><input type="number" class="form-control" [formControl]="gridForms[famille.REF_FAMILLE][ligne.AR_Ref].QteChargeForm" (change)="processGridForm()" style="text-align: center; background:#bdc3c7; font-weight: bold"></td>
                            <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'><input type="number" class="form-control" [formControl]="gridForms[famille.REF_FAMILLE][ligne.AR_Ref].QteGratuitForm" (change)="processGridForm()" style="text-align: center; background:#bdc3c7; font-weight: bold"></td>
                            <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;font-family: Calibri'>
                                <select class="form-control" [ngClass]="{'is-invalid': gridForms[famille.REF_FAMILLE][ligne.AR_Ref].MotifModification.hasError('MOTIF_MODIFICATION') }" (change)="processGridForm()" [formControl]="gridForms[famille.REF_FAMILLE][ligne.AR_Ref].MotifModification">
                                    <option [value]="null">Aucune Modification</option>
                                    <ng-container *ngFor="let typeMotif of typesModification">
                                        <optgroup [label]="typeMotif.TEXT">
                                            <option [value]="motif.ID" *ngFor="let motif of typeMotif.MOTIFS_MODIFICATION">{{ motif.TEXT }}</option>
                                        </optgroup>
                                    </ng-container>
                                </select>
                            </td>
                            <td style='border:1px solid black; font-size: 13px; font-weight: bold; text-align: center;'>
                              <input type="text" class="form-control" style="text-align: center; background:#bdc3c7; font-weight: bold" [formControl]="gridForms[famille.REF_FAMILLE][ligne.AR_Ref].Commentaire" (change)="processGridForm()">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
    <div style="margin-top: 30px">
        <table style='border-collapse:collapse; width: 100%'>
            <thead>
                <tr>
                    <th></th>
                    <th style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri; text-align: center;'>COMMANDE</th>
                    <th style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri; text-align: center;'>CHARGEMENT</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>VOLUME : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ VOLUME_TOTAL + VOLUME_REMISE }}</td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ VOLUME_TOTAL_GRID }}</td>
                </tr>
            </tbody>
        </table>
        <table style='margin-top: 15px; border-collapse:collapse; width: 100%'>
            <thead>
                <tr>
                    <th></th>
                    <th style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri; text-align: center;'>COMMANDE</th>
                    <th style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri; text-align: center;'>CHARGEMENT</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS MIB : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.POIDS_BC_MIB }}</td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ POIDS_BC_MIB_GRID }}</td>
                </tr>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS MIBEM : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.POIDS_BC_MIBEM }}</td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ POIDS_BC_MIBEM_GRID }}</td>
                </tr>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS AGENA : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ bonCommande.POIDS_BC_AGENA }}</td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ POIDS_BC_AGENA_GRID }}</td>
                </tr>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS GRATUITS : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ POIDS_BC_REMISE }}</td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ POIDS_BC_REMISE_GRID }}</td>
                </tr>
                <tr>
                    <td style='border:1px solid black; font-size: 13px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>POIDS TOTAL : </td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ POIDS_BC }}</td>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri'>{{ POIDS_BC_GRID }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style="margin-top: 15px">
        <table style='border-collapse:collapse; width: 100%'>
            <thead>
                <tr>
                    <td style='border:1px solid black; font-size: 14px; width:150px; text-align: center;background: #B4C6E7;font-family: Calibri'>COMMENTAIRE : </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style='border:1px solid black; font-size: 14px; font-weight: bold; width: 300px; height: 20px; text-align: center;font-family: Calibri' [innerHtml]="bonCommande.COMMENTAIRE"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div style="clear:both">&nbsp;</div>
