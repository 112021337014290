import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
    DynamicSelectModelConfig,
    DynamicSelectModel,
  } from "@ng-dynamic-forms/core";

  export interface CfcInputDropdownModelConfig extends DynamicSelectModelConfig<any> {
    readOnly?: boolean;
    rawOptions?: any[];
  }

  export class CfcInputDropdownModel extends DynamicSelectModel<any> {
    @serializable() readonly type: string = 'CfcInputDropdown';
    readOnly: boolean = false;
    rawOptions: any[];

    constructor(config: CfcInputDropdownModelConfig, layout?: DynamicFormControlLayout) {
      super(config, layout);
      if (config.readOnly !== undefined) {
        this.readOnly = config.readOnly;
      }
      if (config.readOnly !== undefined) {
        this.rawOptions = config.rawOptions;
      }
    }
  }
