<div *ngFor="let item of items" class="row">

    <div class="col-md-12">

        <div class="card d-flex flex-row mb-3">
            <a class="d-flex">
                <img alt="Thumbnail" src="http://placehold.it/120x120&text=image1" class="list-thumbnail responsive border-0 card-img-left" />
            </a>
            <div class="pl-2 d-flex flex-grow-1 min-width-zero">
                <div
                    class="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                    <a class="w-40 w-sm-100">
                        <p class="list-item-heading mb-1 truncate">{{item.LIBELLE}}</p>
                    </a>
                    <p class="mb-1 text-muted text-small w-15 w-sm-100">{{item.INFOSUP_1}}</p>
                    <p class="mb-1 text-muted text-small w-15 w-sm-100">{{item.INFOSUP_2}}</p>
                    <div class="w-15 w-sm-100">
                        <span class="badge badge-pill badge-secondary">{{item.INFOSUP_3}}</span>
                    </div>
                </div>

                <div class="custom-control custom-checkbox pl-1 align-self-center pr-4">
                    <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" (click)="onCheckChange(item)" [(ngModel)]="item.VALUE" class="custom-control-input">
                        <span class="custom-control-label"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>