import {Injectable} from "@angular/core";
import {HbridgeInterface, ResultUpdate} from "./hbridge.interface";
import {WebbrowserBridgeService} from "./webbrowser-bridge.service";
import {AndroidBridgeService} from "./android-bridge.service";
import {Subject} from "rxjs";
import {ApiService} from "../../core/api.service";

@Injectable({
  providedIn: 'root',
})
export class HardwareBridgeService implements HbridgeInterface {

  constructor(
    private androidBridge: AndroidBridgeService,
    private webBrowserBridge: WebbrowserBridgeService
  ) {
  }

  isAndroidApp(): boolean {
    const lsAndroidApp = localStorage.getItem("ANDROID_APP");
    return !!lsAndroidApp && lsAndroidApp == '1';
  }

  get onDownloadUpdateChanged(): Subject<ResultUpdate> {
    return this.isAndroidApp() ? this.androidBridge.onDownloadUpdateChanged : null;
  }

  get isMobile(): boolean {
    return this.isAndroidApp();
  }

  get onLocationChanged(): Subject<{ latitude: number, longitude: number, accuracy: number }> {
    return this.isAndroidApp() ? this.androidBridge.onLocationChanged : this.webBrowserBridge.onLocationChanged;
  }

  getGeolocationPoint(): Promise<{ longitude: number, latitude: number, accuracy: number }> {
    return this.isAndroidApp() ? this.androidBridge.getGeolocationPoint() : this.webBrowserBridge.getGeolocationPoint();
  }

  watchGeolocationTracking() {
    this.isAndroidApp() ? this.androidBridge.watchGeolocationTracking() : this.webBrowserBridge.watchGeolocationTracking();
  }

  stopGeolocationTracking() {
    this.isAndroidApp() ? this.androidBridge.stopGeolocationTracking() : this.webBrowserBridge.stopGeolocationTracking();
  }

  openCodeScanner(): Promise<string> {
    return this.isAndroidApp() ? this.androidBridge.openCodeScanner() : this.webBrowserBridge.openCodeScanner();
  }

  openDocumentScanner(): Promise<string> {
    return this.isAndroidApp() ? this.androidBridge.openDocumentScanner() : this.webBrowserBridge.openDocumentScanner();
  }

  openCamera(): Promise<string> {
    return this.isAndroidApp() ? this.androidBridge.openCamera() : this.webBrowserBridge.openCamera();
  }

  showNotification(title: string, text: string) {
    this.isAndroidApp() ? this.androidBridge.showNotification(title, text) : this.webBrowserBridge.showNotification(title, text);
  }

  openFamocoLaser(): Promise<any> {
    return this.isAndroidApp() ? this.androidBridge.openFamocoLaser() : this.webBrowserBridge.openFamocoLaser();
  }

  openFingerprintScanner(): Promise<any> {
    return this.isAndroidApp() ? this.androidBridge.openFingerprintScanner() : this.webBrowserBridge.openFingerprintScanner();
  }

  getAppVersion(): string {
    return this.isAndroidApp() ? this.androidBridge.getAppVersion() : this.webBrowserBridge.getAppVersion();
  }

  downloadUpdateApp(url: string, version: string) {
    return this.isAndroidApp() ? this.androidBridge.downloadUpdateApp(url, version) : this.webBrowserBridge.downloadUpdateApp(url, version);
  }

  cancelUpdateApp() {
    return this.isAndroidApp() ? this.androidBridge.cancelUpdateApp() : this.webBrowserBridge.cancelUpdateApp();
  }


}
