import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ApiService } from 'src/app/core/api.service';

const FAMILLE_CDF_SANGRIA_ROUGE_ID = 9;
const FAMILLE_CDF_VIN_ROUGE_ID = 10;
const ORDER_FAMILLES_IDS = [1, 10, 2, 9, 3, 8, 4, 12, 5, 11, 6, 15, 7, 13, 14];
enum Enum_TypeRemise {
    GRATUITE_ARTICLE = 1,
    REMISE_TRANSPORT_GRATUITE = 2,
    REMISE_FINANCIERE = 3,
    RISTOURNE_CLIENT = 4,
    REMISE_LIBRE = 5
}
enum Enum_BCTypeLigne {
  LIGNE = 0,
  ENTETE = 1,
  TOTAUX = 2
}

enum Enum_AliasID {
  GROUPE = 1,
  MIB = 2,
  MIBEM = 3,
  AGENA = 4
}

enum Command_View {
  IDLE = 0,
  COMMAND_LOADING = 1,
  COMMAND_LOADED = 2,
  COMMAND_LOADING_ERROR = 3
}

@Component({
  selector: 'cfc-mibem-command',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcMibemCommandComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-mibem-command.component.html'
})
export class CfcMibemCommandComponent implements OnInit, ControlValueAccessor {

  viewMode = Command_View.IDLE;
  loadingStatus = '';
  Command_View = Command_View;
  @Input() bonCommandeId: number;
  bonCommande: any = {};
  VOLUME_TOTAL = 0;
  VOLUME_REMISE = 0;
  POIDS_BC_REMISE = 0;
  POIDS_BC = 0;
 
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private api: ApiService, 
    private httpClient: HttpClient
  ) {
  }

  ngOnInit(): void {
      this.doGetBonCommandeAction(this.bonCommandeId);
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.propagateChange(value);
      this.changeDetectorRef.detectChanges();
    }
  }

  doGetBonCommandeAction(_id: number) {
    this.viewMode = Command_View.COMMAND_LOADING;
    this.httpClient.get(this.api.endPoints.doGetBonCommandeAction_AppCadencier + "/" + _id.toString())
    .subscribe((_bonCommande: any) => {
      try {
        this._processBonCommande(_bonCommande);
      } catch(e) {
        this.loadingStatus = 'Une erreur est survenue lors de l\'affichage de la commande. Assurez vous que les paramètres soient correctement synchronisés. (Contactez un administrateur).';
        this.viewMode = Command_View.COMMAND_LOADING_ERROR;
        console.log(e);
      }
      this.changeDetectorRef.detectChanges();
    }, err => {
      this.loadingStatus = 'Une erreur est survenue lors du chargement de la commande. Veuillez réessayer plus tard.';
      this.viewMode = Command_View.COMMAND_LOADING_ERROR;
      console.log(err);
    });
  }

  private _processBonCommande(_bonCommande: any) {
    const familles = JSON.parse(JSON.stringify((_bonCommande.FAMILLES as any[]).sort((a, b) => ORDER_FAMILLES_IDS.lastIndexOf(a.ID) - ORDER_FAMILLES_IDS.lastIndexOf(b.ID))));
    familles.forEach((famille, index, famillesArr) => {
      var famille_articles = [];
      if (famille.ID == FAMILLE_CDF_VIN_ROUGE_ID || famille.ID == FAMILLE_CDF_SANGRIA_ROUGE_ID) {
        famille_articles = (famille.ARTICLES as any[]).sort((a,b) => a.ID - b.ID);
      } else {
        famille_articles = (famille.ARTICLES as any[]).sort((a,b) => a.AR_Design.localeCompare(b.AR_Design));
      }

      famille_articles.forEach((ligne, ligneIndex, lignesArr) => {
        var qteCommande = 0;
        var qteGratuit = 0;
        var poidsGratuite = 0;
        var ligne_commande = _bonCommande.LIGNES.find(l => l.FAMILLE_ID == famille.ID && l.AR_Ref == ligne.AR_Ref);
        if (ligne_commande !== null) {
          qteCommande = ligne_commande.DL_QteBC;
          _bonCommande.REMISES.forEach((remise, remiseIndex, remisesArr) => {
            if (remise.ENABLED == 1 && remise.REMISE_LIGNES !== undefined && (remise.TYPE_REMISE_ID == Enum_TypeRemise.GRATUITE_ARTICLE || remise.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_TRANSPORT_GRATUITE || remise.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_LIBRE)) {
              remise.REMISE_LIGNES.filter(l => l.AR_Ref == ligne.AR_Ref && (l.ALIAS_ID == _bonCommande.ALIAS_ID || _bonCommande.PARENT_BON_COMMANDE_ID == 0)).forEach((ligneArticle) => {
                qteGratuit += ligneArticle.DL_Qte;
                poidsGratuite += qteGratuit * ligne_commande.AR_PoidsNet;
              });
            }
          });
        }
        lignesArr[ligneIndex].QTE_COMMANDE_STR = qteCommande;
        lignesArr[ligneIndex].QTE_GRATUIT_STR = qteGratuit;
      });

      famillesArr[index].ARTICLES = famille_articles;
    });
    _bonCommande.FAMILLES = familles;
    _bonCommande.POIDS_BC_MIB = _bonCommande.LIGNES.filter(ligne => ligne.ALIAS_ID == Enum_AliasID.MIB && ligne.TYPE_LIGNE == Enum_BCTypeLigne.LIGNE && ligne.IS_HIDDEN == 0).map(l => l.DL_PoidsNetTotal).reduce((sum, current) => sum + current, 0);
    _bonCommande.POIDS_BC_MIBEM = _bonCommande.LIGNES.filter(ligne => ligne.ALIAS_ID == Enum_AliasID.MIBEM && ligne.TYPE_LIGNE == Enum_BCTypeLigne.LIGNE && ligne.IS_HIDDEN == 0).map(l => l.DL_PoidsNetTotal).reduce((sum, current) => sum + current, 0);
    _bonCommande.POIDS_BC_AGENA = _bonCommande.LIGNES.filter(ligne => ligne.ALIAS_ID == Enum_AliasID.AGENA && ligne.TYPE_LIGNE == Enum_BCTypeLigne.LIGNE && ligne.IS_HIDDEN == 0).map(l => l.DL_PoidsNetTotal).reduce((sum, current) => sum + current, 0);
    this.VOLUME_TOTAL = _bonCommande.LIGNES.filter(ligne => ligne.TYPE_LIGNE == Enum_BCTypeLigne.LIGNE && ligne.IS_HIDDEN == 0).map(l => l.DL_QteBC).reduce((sum, current) => sum + current, 0);

    var activesRemises = _bonCommande.REMISES.filter(r => r.ENABLED == 1 && (r.TYPE_REMISE_ID == Enum_TypeRemise.GRATUITE_ARTICLE || r.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_TRANSPORT_GRATUITE || r.TYPE_REMISE_ID == Enum_TypeRemise.REMISE_LIBRE));
    if (activesRemises.length > 0) {
      var activesRemiseLignes = activesRemises.map(r => r.REMISE_LIGNES)[0];
      if (activesRemiseLignes.length > 0) {
        this.VOLUME_REMISE += activesRemiseLignes.map(l => l.DL_Qte).reduce((sum, current) => sum + current, 0);
        var activeRemiseLignesArticles = activesRemiseLignes.filter(l => l.DL_Qte != 0 && (_bonCommande.ALIAS_ID == 0 || l.ALIAS_ID == _bonCommande.ALIAS_ID)).map(a => a.AR_PoidsNet * a.DL_Qte);
        if (activeRemiseLignesArticles.length > 0) {
          this.POIDS_BC_REMISE = activeRemiseLignesArticles.reduce((sum, current) => sum + current, 0);
        }
      }
    }
    this.POIDS_BC = _bonCommande.POIDS_BC_MIB + _bonCommande.POIDS_BC_MIBEM + _bonCommande.POIDS_BC_AGENA + this.POIDS_BC_REMISE;

    _bonCommande.POIDS_BC_MIB = Math.round(_bonCommande.POIDS_BC_MIB);
    _bonCommande.POIDS_BC_MIBEM = Math.round(_bonCommande.POIDS_BC_MIBEM);
    _bonCommande.POIDS_BC_AGENA = Math.round(_bonCommande.POIDS_BC_AGENA);
    this.VOLUME_TOTAL = Math.round(this.VOLUME_TOTAL);
    this.VOLUME_REMISE = Math.round(this.VOLUME_REMISE);
    this.POIDS_BC_REMISE = Math.round(this.POIDS_BC_REMISE);
    this.POIDS_BC = Math.round(this.POIDS_BC);

    const dateCreation = new Date(_bonCommande.DATE_CREATION);
    _bonCommande.DATE_CREATION = dateCreation.getDate().toString().padStart(2, '0') + "/" + (dateCreation.getMonth() + 1).toString().padStart(2, '0') + "/" + dateCreation.getFullYear();

    this.bonCommande = _bonCommande;
    this.viewMode = Command_View.COMMAND_LOADED;
  }

}
