import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
  } from "@ng-dynamic-forms/core";

  export interface CfcFamocoLaserModelConfig extends DynamicFileUploadModelConfig { }

  export class CfcFamocoLaserModel extends DynamicFileUploadModel {
    @serializable() readonly type: string = 'CfcFamocoLaser';

    constructor(config: CfcFamocoLaserModelConfig, layout?: DynamicFormControlLayout) {
      super(config, layout);
    }
  }
