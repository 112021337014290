<mwl-text-input-autocomplete-container>
  <textarea
    (keyup)="onChangeInput($event)"
    placeholder=""
    class="form-control"
    rows="1"
    [(ngModel)]="textValue"
    mwlTextInputAutocomplete
    [findChoices]="findChoices"
    [getChoiceLabel]="getChoiceLabel"
    (choiceSelected)="onChoiceSelected($event)">
  </textarea>
</mwl-text-input-autocomplete-container>
