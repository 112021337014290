<ng-container [formGroup]="group">
    <cfc-mibem-command style="display: block;"
      [formControlName]="model.id"
      [id]="bindId ? model.id : null"
      [name]="model.name"
      [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
      [bonCommandeId]="model.bonCommandeId"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)">
    </cfc-mibem-command>
</ng-container>
