import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import {HardwareBridgeService} from "../../../../../../shared/bridge/hardware-bridge.service";
import { SOUDOTEC_MODE_SCAN_EMPLACEMENT } from './cfc-soudotec-scan-emplacement.model';

@Component({
  selector: 'cfc-soudotec-scan-emplacement',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcSoudotecScanEmplacementComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CfcSoudotecScanEmplacementComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-soudotec-scan-emplacement.component.html'
})
export class CfcSoudotecScanEmplacementComponent implements ControlValueAccessor {

  @Input() required = false;
  @Input('emplacements') mEmplacements: any[] = [];
  @Input('noDepot') mNoDepot: string;
  @Input('modeScan') mModeScan = SOUDOTEC_MODE_SCAN_EMPLACEMENT.FAMOCO_LASER;
  scannedCode: any;
  @Output() hasChanged = new EventEmitter<void>();
  SOUDOTEC_MODE_SCAN_EMPLACEMENT = SOUDOTEC_MODE_SCAN_EMPLACEMENT;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private hardwareBridge: HardwareBridgeService,
    private inj: Injector
  ) {
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (!!value) {
      this.scannedCode = value;
      this.propagateChange(this.scannedCode);
      this.hasChanged.emit();
    }
  }

  validate({ value }: FormControl) {
    const matchedEmplacement = this.mEmplacements.find(e => e['C0001'] == value &&  e['C0003'] == this.mNoDepot);
    if (this.required && !value) {
      return  { required: true };
    }
    if (this.required && !matchedEmplacement) {
      return  { notFound: true };
    }
    if (this.required && matchedEmplacement['C0003'] != this.mNoDepot) {
      return  { notMatched: true };
    }
    return null;
  }

  scanCode() {
    if (this.mModeScan == SOUDOTEC_MODE_SCAN_EMPLACEMENT.FAMOCO_LASER) {
      this.hardwareBridge.openFamocoLaser()
      .then((result) => {
        this.writeValue(result);
      });
    } else if (this.mModeScan == SOUDOTEC_MODE_SCAN_EMPLACEMENT.APP_PHOTO_QR_CODE_READER) {
      this.hardwareBridge.openCodeScanner()
      .then((result) => {
        this.writeValue(result);
      });
    }
  }

}
