<kendo-dropdownlist
  [data]="mFilteredOptions"
  [textField]="'label'"
  [valueField]="'value'"
  [valuePrimitive]="true"
  [required]="required"
  [readonly]="readOnly"
  [disabled]="disabled"
  [value]="value"
  [filterable]="true"
  [virtual]="true"
  (valueChange)="onValueChanged($event)"
  (filterChange)="handleFilter($event)"
  [popupSettings]="{ width: 'auto' }">
</kendo-dropdownlist>
