<div class="fixed-background"></div>
<main>
  <div class="container">
    <div class="row h-100">
      <div class="col-12 col-md-10 mx-auto my-auto">
        <div class="card auth-card">
          <div class="position-relative image-side">
            
          </div>
          <div class="form-side">
            <h6 style="font-size: 13px;font-weight: 800; display: inline-block;">{{ 'absapisettings.title' | translate }}</h6>
            <kendo-loader [type]="'pulsing'" [themeColor]="'primary'" [size]="'medium'" *ngIf="showProgressBar"></kendo-loader>
            <p style="font-size: 11px;" [ngStyle]="{'color': colorMessage}">{{errorMessage}}</p>
            <form [formGroup]="configAPIForm" novalidate>
              <label class="form-group has-float-label">
                <input id="apiendpoint" [(ngModel)]="apiEndpointABW" class="form-control" required formControlName="apiendpoint" [readonly]="showProgressBar" />
                <span>{{ 'absapisettings.apiendpointlabel' | translate }} </span>
              </label>
              <div style="font-size: 12px; margin-left: 2px;" *ngIf="configAPIForm.controls.apiendpoint.errors">
                <div *ngIf="configAPIForm.controls.apiendpoint.errors.required">{{ 'absapisettings.apiendpointmsgrequired' | translate }}</div>
                <div *ngIf="configAPIForm.controls.apiendpoint.errors.pattern">{{ 'absapisettings.apiendpointmsgbadformat' | translate }}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <button style="font-size: 12px; margin-left: auto;" class="'btn btn-primary btn-lg btn-shadow'" [disabled]="configAPIForm.invalid || showProgressBar" (click)="onSaveAction_ConfigAPIParams()">
                  {{ 'absapisettings.saveaction' | translate | uppercase }}
                </button>
              </div>
            </form>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</main>