import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'cfc-soudotec-command-emplacements-dialog',
    templateUrl: './cfc-soudotec-command-emplacements-dialog.component.html',
    styleUrls: ['./cfc-soudotec-command-emplacements-dialog.component.scss']
})
export class CfcSoudotecCommandEmplacementsDialogComponent implements OnInit {
    mDocLigne: any;
    
    constructor(
        public dialogRef: MatDialogRef<CfcSoudotecCommandEmplacementsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
    ) {
        this.mDocLigne = data.ligne;
    }

    ngOnInit(): void {
        
    }
}