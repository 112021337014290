import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
    DynamicSelectModelConfig,
    DynamicSelectModel,
    DynamicInputModel,
    DynamicInputModelConfig,
  } from "@ng-dynamic-forms/core";

  export interface CfcInputNumericTextboxModelConfig extends DynamicInputModelConfig {}

  export class CfcInputNumericTextboxModel extends DynamicInputModel {
    @serializable() readonly type: string = 'CfcInputNumericTextbox';

    constructor(config: CfcInputNumericTextboxModelConfig, layout?: DynamicFormControlLayout) {
      super(config, layout);
    }
  }
