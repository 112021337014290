import {NgModule} from '@angular/core';
import { DatasourcesOfflineApi } from './api/datasources-offline.api';
import { EnvVarUserOfflineApi } from './api/envvaruser-offline.api';
import { ResponsesOfflineApi } from './api/responses-offline.api';
import { SynchronisationOfflineApi } from './api/synchronisation-offline.api';
import { WorkflowsOfflineApi } from './api/workflows-offline.api';
import { DatasourcesOfflineDao } from './daos/datasources-offline.dao';
import { EnvVarUserOfflineDao } from './daos/envvaruser-offline.dao';
import { ResponsesOfflineDao } from './daos/responses-offline.dao';
import { TeamsOfflineDao } from './daos/teams-offline.dao';
import { UnitOfWorkOfflineDao } from './daos/unitofwork-offline.dao';
import { WorkflowsOfflineDao } from './daos/workflows-offline.dao';
import { DatasourcesOfflineService } from './services/datasources-offline.service';
import { EnvVarUserOfflineService } from './services/envvaruser-offline.service';
import { ResponsesOfflineService } from './services/responses-offline.service';
import { SynchronisationOfflineService } from './services/synchronisation-offline.service'; 
import { TeamsOfflineService } from './services/teams-offline.service';
import { WorkflowsOfflineService } from './services/workflows-offline.service';

@NgModule({
  exports: [
  ],
  providers: [
    DatasourcesOfflineDao,
    EnvVarUserOfflineDao,
    ResponsesOfflineDao,
    WorkflowsOfflineDao,
    UnitOfWorkOfflineDao,
    TeamsOfflineDao,

    DatasourcesOfflineService,
    EnvVarUserOfflineService,
    ResponsesOfflineService,
    SynchronisationOfflineService,
    WorkflowsOfflineService,
    TeamsOfflineService,

    DatasourcesOfflineApi,
    EnvVarUserOfflineApi,
    ResponsesOfflineApi,
    SynchronisationOfflineApi,
    WorkflowsOfflineApi,
  ]
})
export class OfflineApiModule {}