import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CfcSoudotecInventoryService } from './cfc-soudotec-inventory.service';

@Component({
    selector: 'cfc-soudotec-inventory-input.dialog',
    templateUrl: 'cfc-soudotec-inventory-input.dialog.html',
    styles: [`.k-dropdown { display: block; width: 100%; } `]
})
export class CfcSoudotecInventoryInputDialogComponent implements OnInit, OnDestroy {
    control = new FormControl(null, Validators.required);
    articles: { value: string, label: string }[] = [];
    filteredArticles: Observable<{ value: string, label: string }[]>;
    onComplete = new Subject<any>();

    constructor(
        private dialogRef: MatDialogRef<CfcSoudotecInventoryInputDialogComponent>,
        private _cfcSoudotecInventoryService: CfcSoudotecInventoryService,
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.onComplete.next();
        this.onComplete.complete();
    }

    onSubmit() {
        if (!this.control.valid) { return; }
        this.dialogRef.close(this.control.value);
    }

    handleFilter(value) {
        this._filter(value);
    }

    private async _filterAsync(array, predicate) {
        const res = [];
        await array.reduce(async (promise, n) => {
            await promise;
            if (await predicate(n)) {
                res.push(n);
            }
        }, Promise.resolve());
        return res;
    }

    private _filter(value: string) {
        if (value.length < 3) { this.articles = []; return; }

        const filterValue = this._normalizeValue(value);
        this._filterAsync(this._cfcSoudotecInventoryService.Articles, async article => {
            return this._normalizeValue(article['C0001'] + ' ' + article['C0002']).includes(filterValue);
        }).then((_articles) => this.articles = _articles);
    }

    private _normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }
}
