import { Injectable } from "@angular/core";
import { DbmVariableEnvironmentUser } from "../../views/app/app.models";
import { Guid } from "guid-typescript";
import * as moment from "moment";

const TB_ENVIRONMENT_VARIABLES_USER_NAME = "LOCAL_TB_ENVIRONMENT_VARIABLES_USER";

@Injectable()
export class EnvVarUserOfflineDao {
    doSyncEnvironmentVariablesUserAction(_envVars: DbmVariableEnvironmentUser[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doClearEnvironmentVariablesUserAction();
            this.doInsertEnvironmentVariablesUserAction(_envVars).then(() => {
                resolve();
            }, err => reject(err));
        });
    }

    doClearEnvironmentVariablesUserAction() {
        localStorage.setItem(TB_ENVIRONMENT_VARIABLES_USER_NAME, "[]");
    }

    doGetEnvironmentVariablesUserAction(): Promise<DbmVariableEnvironmentUser[]> {
        return new Promise((resolve, reject) => {
            var datas: DbmVariableEnvironmentUser[] = [];
            try {
                datas = JSON.parse(localStorage.getItem(TB_ENVIRONMENT_VARIABLES_USER_NAME));
            } catch(e) { console.log(e) }
            resolve(datas);
        });
    }

    doInsertEnvironmentVariablesUserAction(_envVars: DbmVariableEnvironmentUser[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.doGetEnvironmentVariablesUserAction().then((_datasTable: DbmVariableEnvironmentUser[]) => {
                var newDatas = _datasTable.concat(_envVars);
                localStorage.setItem(TB_ENVIRONMENT_VARIABLES_USER_NAME, JSON.stringify(newDatas));
                resolve();
            }, err => reject(err));
        });
    }

    doSaveEnvironmentVariablesUserAction(_envVar: DbmVariableEnvironmentUser): Promise<DbmVariableEnvironmentUser> {
        return new Promise((resolve, reject) => {
            this.doGetEnvironmentVariablesUserAction().then((_datasTable: DbmVariableEnvironmentUser[]) => {
                var itemIndex = _datasTable.findIndex(i => i.ID != _envVar.ID || i.GUID == _envVar.GUID);
                var newDatas = _datasTable;
                if (itemIndex === -1) {
                    newDatas.push(_envVar);
                    itemIndex = newDatas.length - 1;
                    newDatas[itemIndex].DATE_CREATION = moment().format();
                } else {
                    newDatas[itemIndex] = _envVar;
                    newDatas[itemIndex].GUID = Guid.create().toString();
                    newDatas[itemIndex].DATE_MODIFICATION = moment().format();
                }
                localStorage.setItem(TB_ENVIRONMENT_VARIABLES_USER_NAME, JSON.stringify(newDatas));
                resolve(newDatas[itemIndex]);
            }, err => reject(err));
        });
    }

    doGetEnvironmentVariableUser(_id: number): Promise<DbmVariableEnvironmentUser> {
        return new Promise((resolve, reject) => {
            this.doGetEnvironmentVariablesUserAction().then((_datasTable: DbmVariableEnvironmentUser[]) => {
                const datasource = _datasTable.find(w => w.ID == _id);
                resolve(datasource);
            }, err => reject(err));
        });
    }
}
