<!--<form [formGroup]="inputGridFormsGroup[indexArray]" class="row grid-row" *ngFor="let formArray of inputGridFormsModel; let indexArray = index">
  <div class="col-12"><span class="text-muted">{{ label + ' ' + (indexArray + 1) }}</span></div>
  <div class="grid-item col" *ngFor="let formControl of formArray; let indexControl = index" [ngStyle]="{'flex-basis': indexArray < initialRowsCount ? 'calc(90% / ' + gridColumns.length + ')' : 'calc(80% / ' + gridColumns.length + ')', 'flex-grow': '0'}">
    <dynamic-kendo-form-control [group]="inputGridFormsGroup[indexArray]" [model]="formControl"></dynamic-kendo-form-control>
  </div>
  <div class="grid-item-action col" *ngIf="indexArray >= initialRowsCount" style="flex-basis: 50px; flex-grow: 0">
    <button class="btn btn-xs btn-danger" (click)="removeRow(indexArray)"><i class="glyph-icon simple-icon-minus"></i></button>
  </div>
</form>
<div class="row" style="border-bottom: 2px solid #EEE; padding-bottom: 40px" *ngIf="hasDynamicRows">
  <div class="col-12 text-center">
    <button class="btn btn-xs btn-light" (click)="addRow()"><i class="glyph-icon simple-icon-plus"></i> Ajouter un élément</button>
  </div>
</div>-->

<table style="padding-right:10px">
  <thead>
    <tr>
      <th *ngFor="let col of gridColumns" [width]="(100 / gridColumns.length) + '%'">{{ col['LIBELLE'] }}</th>
      <th *ngIf="!readOnly">
        <span class="hidden-on-phone">Actions</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr [formGroup]="inputGridFormsGroup[indexArray]" *ngFor="let formArray of inputGridFormsModel; let indexArray = index">
      <td *ngFor="let formControl of formArray; let indexControl = index">
        <dynamic-kendo-form-control [group]="inputGridFormsGroup[indexArray]" [model]="formControl"></dynamic-kendo-form-control>
      </td>
      <td *ngIf="!readOnly" style="flex-basis: 50px; flex-grow: 0">
        <button class="btn btn-xs btn-danger" (click)="removeRow(indexArray)"><i class="glyph-icon simple-icon-minus"></i></button>
      </td>
    </tr>
  </tbody>
  <tfoot *ngIf="hasDynamicRows && !readOnly">
    <tr>
      <td [attr.colspan]="gridColumns.length + 1" style="text-align: center;padding-top: 20px;
      padding-bottom: 20px;">
        <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <button class="btn btn-light btn-block" (click)="addRow()"><i class="glyph-icon simple-icon-plus"></i> Ajouter un élément</button>
      </td>
    </tr>
  </tfoot>
</table>