import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'cfc-input-textarea',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcInputTextareaComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-input-textarea.component.html'
})
export class CfcInputTextareaComponent implements OnInit, ControlValueAccessor {

  @Input() options: { value: any, label: string }[] = [];
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() value: any;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.propagateChange(null);
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
      this.changeDetectorRef.detectChanges();
    }
  }

  onValueChanged(event: any) {
    this.propagateChange(event.target.value);
  }

}
