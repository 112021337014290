import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../core/api.service';
import { Router } from '@angular/router';
import { ConnectorSettingsAPIService } from './connector-settings-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-api',
  templateUrl: './settings-api.component.html'
})
export class SettingsApiComponent implements OnInit {

  configAPIForm : FormGroup;
  apiEndpointABW : string;

  constructor(
    private formBuilder: FormBuilder,
    private connector : ConnectorSettingsAPIService,
    private ref: ChangeDetectorRef,
    private api : ApiService,
    private router: Router,
  ) {
    this.apiEndpointABW = this.api.onGetAction_APIEndpoint_ABW();
  }

  ngOnInit() {
    document.body.classList.add('background');
    document.body.classList.add('no-footer');
    // #region Forms
    const urlRegex = '^(http|https):\/\/(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])(:[0-9]+)?$';
    this.configAPIForm = this.formBuilder.group({
        apiendpoint: ['', [Validators.required, Validators.pattern(urlRegex)]]
    });
    // #endregion
  }

  ngOnDestroy() {
    document.body.classList.remove('background');
    document.body.classList.add('no-footer');
  }

  onSaveAction_ConfigAPIParams(){
    this.showProgressBar = true;
    this.api.onSaveAction_APIEndpoints(this.apiEndpointABW);
    this.onDisplayAction_ABWMessage("INFO", "Tentative de connexion au serveur");
    this.connector.doPingAction().subscribe((res : { APP_VERSION: string, COMPANY_NAME: string }) => {
      //localStorage.setItem(environment.LS_ABWORKFLOW_CURRENT_COMPANY, res.COMPANY_NAME);
      localStorage.setItem(environment.LS_ABWORKFLOW_CURRENT_COMPANY, res.COMPANY_NAME);
      this.onDisplayAction_ABWMessage("SUCCESS", "Api Endpoint accessible");
      this.showProgressBar = false;
      this.router.navigateByUrl("/user/sign-in");
    }, err => {
      console.error(err);
      this.onDisplayAction_ABWMessage("ERROR", "Api Endpoint Inaccessible");
      this.showProgressBar = false;
      //setTimeout(() => { this.ref.detectChanges(); }, 500);
    })
  }


  // #region ABW Message
  showProgressBar : boolean;
  colorMessage : string;
  errorMessage : string;
  onDisplayAction_ABWMessage(_type : string, _message : string){
      switch(_type){
          case "SUCCESS" : this.colorMessage = "#10ac84"; this.errorMessage = _message; break;
          case "ERROR" : this.colorMessage = "#ee5253"; this.errorMessage = _message; break;
          case "INFO" : this.colorMessage = "#54a0ff"; this.errorMessage = _message; break;
          case "WARN" : this.colorMessage = "#ff9f43"; this.errorMessage = _message; break;
      }
  }
  // #endregion

}
