<table>
  <thead>
    <tr>
      <th>Type de cellule</th>
      <th>Identifiant</th>
      <th>Intitulé</th>
      <th width="17%"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let column of gridColumns; let columnIndex = index">
      <td>
        <select class="form-control" (change)="updateValue()" [formControl]="column['TYPE_CELL']">
          <option [value]="input.value" *ngFor="let input of availablesInputForm">{{ input.label }}</option>
        </select>
      </td>
      <td>
        <input type="text" class="form-control" (change)="updateValue()" [formControl]="column['IDENTIFIANT']" />
      </td>
      <td>
        <input type="text" class="form-control" (change)="updateValue()" [formControl]="column['LIBELLE']" />
      </td>
      <td>
        <button class="btn btn-xs" style="padding: 0.25rem;" (click)="onEditColumnAction(columnIndex)"><div class="glyph-icon simple-icon-pencil"></div></button>
        <button class="btn btn-xs" style="padding: 0.25rem;" (click)="onRemoveColumnAction(columnIndex)"><div class="glyph-icon simple-icon-minus"></div></button>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan="4"><button class="btn btn-outline-primary btn-block" (click)="onAddColumnAction()"><span class="glyph-icon simple-icon-plus"></span> Ajouter Col</button></td>
    </tr>
  </tfoot>
</table>