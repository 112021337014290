import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";

@Component({
  selector: 'cfc-input-textbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CfcInputTextboxComponent),
      multi: true
    }
  ],
  templateUrl: './cfc-input-textbox.component.html'
})
export class CfcInputTextboxComponent implements OnInit, ControlValueAccessor {

  @Input() options: { value: any, label: string }[] = [];
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() placeholder = null;
  @Input() valid = true;
  @Input() value: any;
  @Input('isTruncated') IsTruncated?: boolean;
  @Input('truncatedLength') TruncatedLength?: number;
  @Input('truncatedStartIndex') TruncatedStartIndex?: number;
  ngControl: NgControl | undefined;

  constructor(private changeDetectorRef: ChangeDetectorRef, private inj: Injector) { }

  ngOnInit() {
    this.propagateChange(null);
  }

  ngAfterViewInit(): void {
      this.ngControl = this.inj.get(NgControl);
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) { this.propagateChange = fn; }
  registerOnTouched() {}
  writeValue(value: string) {
    if (value !== undefined) {
      if (value !== null) {
        if (!!this.IsTruncated && this.IsTruncated === true) {
          const truncatedStartIndex = !!this.TruncatedStartIndex ? this.TruncatedStartIndex : -1;
          if (truncatedStartIndex !== -1 && truncatedStartIndex < value.length) {
            value = value.substring(truncatedStartIndex);
          }
          const truncatedLength = !!this.TruncatedLength ? this.TruncatedLength : -1;
          if (truncatedLength !== -1 && truncatedLength < value.length) {
            value = value.substring(0, truncatedLength);
          }
          if (!!this.ngControl && this.ngControl.control.value != value) {
            this.ngControl.control.patchValue(value, { emitEvent: false });
          }
        }
      }
      this.value = value;
      this.changeDetectorRef.detectChanges();
    }
  }

  onValueChanged(value: number) {
    this.propagateChange(value);
  }

}
