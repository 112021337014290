import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../../../core/api.service';
import { DynamicDatePickerModel, DynamicFormControlModel, DynamicFormGroupModel, DynamicFormService, DynamicInputModel, DynamicSelectModel, DynamicSwitchModel, DynamicTextAreaModel, DynamicTimePickerModel } from '@ng-dynamic-forms/core';
import { DatasourcesOfflineApi } from 'src/app/@offline-api/api/datasources-offline.api';
import { AbsGrid } from '../../app.models';

@Injectable()
export class ConnectorWorkflowExecutionDsBuilderService {

    constructor(
        private api: ApiService, 
        private httpClient: HttpClient, 
        private formService: DynamicFormService,
        private datasourcesOfflineApi: DatasourcesOfflineApi
    ) {
    }

    // #region API
    private doBuildWorkflowStepDatasourceAction_Design(_workflowStepDatasourceID : string): Promise<AbsGrid> {
        if (!this.api.isOfflineMode) {
            let _httpParams = new HttpParams().set("_workflowStepDatasourceID", _workflowStepDatasourceID);
            return this.httpClient.get<AbsGrid>(this.api.endPoints.doBuildWorkflowStepDatasourceAction_Design, { params : _httpParams }).toPromise();
        } else {
            return this.datasourcesOfflineApi.doBuildGridDatasourceAction_Datasource(parseInt(_workflowStepDatasourceID));
        }
    }
    // #endregion

    doBuildFullDatasourceOptionsAction_Execution(_workflowStepDatasourceID : string) : (Promise<any[]>) {
        return new Promise((resolve) => { 
            this.doBuildWorkflowStepDatasourceAction_Design(_workflowStepDatasourceID).then((res : any) => {
                resolve(res.mData);
            }, err=> {
                console.log(err);
                resolve([]);
            });     
        });
    }

    doBuildDatasourceOptionsAction_Execution(_workflowStepDatasourceID : string, _valueField : string, _textField : string) : (Promise<any[]>) {
        let _options = [];
        return new Promise((resolve) => { 
            this.doBuildWorkflowStepDatasourceAction_Design(_workflowStepDatasourceID).then((res : any) => {
                _options = res.mData.map(function(_row){
                    _row['label'] = ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_textField, _row);
                    _row['value'] = ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_valueField, _row);
                    return _row;
                });
                resolve(_options);
            }, err=> {
                console.log(err);
                resolve([]);
            });     
        });
    }

    doBuildAPIDatasourceOptionsAction_Execution(_datasourceAPI : string, _valueField : string, _textField : string, _apiDatas: any) : (Promise<any[]>) {
        let _options = [];
        return new Promise((resolve) => { 
            this.httpClient.post(this.api.apiEndPoint + _datasourceAPI, _apiDatas)
            .subscribe((res : any) => {
                _options = res.map(function(_row){
                    return { 
                        label : _row[_textField], 
                        value : _row[_valueField]
                    };
                })
                resolve(_options);
            }, err=> {
                console.log(err);
                resolve([]);
            });     
        });
    }

    doBuildDatasourceGroupOptionsAction_Execution(_workflowStepDatasourceID : string, _idField : string, _valueField : string, _textField : string) : (Promise<any[]>) {
        let _options = [];
        return new Promise((resolve) => { 
            this.doBuildWorkflowStepDatasourceAction_Design(_workflowStepDatasourceID).then((res : any) => {
                _options = res.mData.map(function(_row){
                    return { 
                        id : ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_idField, _row), 
                        label : ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_textField, _row), 
                        value : ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_valueField, _row)
                    };
                })
                resolve(_options);
            }, err=> {
                console.log(err);
                resolve([]);
            });     
        });
    }

    doBuildDatasourceCardCheckListOptionsAction_Execution(_workflowStepDatasourceID : string, _idField : string, _textField : string, _infosup1Field : string, _infosup2Field : string, _infosup3Field : string) : (Promise<any[]>) {
        let _options = [];
        return new Promise((resolve) => { 
            this.doBuildWorkflowStepDatasourceAction_Design(_workflowStepDatasourceID).then((res : any) => {
                _options = res.mData.map(function(_row){
                    return { 
                        ID : ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_idField, _row), 
                        LIBELLE : ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_textField, _row), 
                        INFOSUP_1 : ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_infosup1Field, _row), 
                        INFOSUP_2 : ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_infosup2Field, _row), 
                        INFOSUP_3 : ConnectorWorkflowExecutionDsBuilderService.doInterpolateExpressionAction_Execution(_infosup3Field, _row)
                    };
                })
                console.log(_options);
                resolve(_options);
            }, err=> {
                console.log(err);
                resolve([]);
            });     
        });
    }

    private static doInterpolateExpressionAction_Execution(_expression : string, _row : any) : string {
        let _result = '';
        try {  
            _result = _expression.replace(/fields./gi, '');
            var matchesResult = _result.split('[').filter(function(v){ return v.indexOf(']') > -1}).map( function(value) { return value.split(']')[0]});
            var matchesResultVal = matchesResult.map(function(a){ return _row[a]});
            for(var k = 0; k<matchesResult.length; k++){ _result = _result.replace(matchesResult[k], matchesResultVal[k]); }
            _result = _result.replace(/\[/gi, '');
            _result = _result.replace(/]/gi, '');
        } catch(e){ return ''; }
        return _result;
    }

    public static dogetColumnIDExpressionAction_Execution(_expression : string) : string {
        const _result = _expression.replace(/fields./gi, '');
        const matches = _result.match(/([\d\w]+)/gi);
        if (matches.length > 0) {
            return matches[0];
        }
        return '';
    }
}